import { h } from 'preact';
import { Button } from 'src/components/';

const ReportFooter = ({
  isLoading,
  isPreviousButtonDisabled,
  isNextButtonDisabled,
  showPreviousButton,
  previousButtonAction,
  nextButtonAction,
  nextButtonText,
  exitButtonAction,
  reopenButtonAction,
  isFinishStep,
  isReportFinished,
  finalizeButtonAction
}) => (
  <div className='w-full self-end'>
    <footer>
      {isFinishStep ? (
        <div className='w-full flex flex-col-reverse md:flex-row justify-between items-center md:items-end'>
          {showPreviousButton ? (
            <Button
              type='button'
              onClick={previousButtonAction}
              disabled={isLoading || isPreviousButtonDisabled}
              variant='empty-with-border-sm'
              classes='mt-4 md:mt-auto h-9 w-[8.5rem]'
            >
              Previous
            </Button>
          ) : null}
          <div className='flex flex-col md:flex-row md:ml-auto'>
            {isReportFinished ? (
              <Button
                type='button'
                onClick={reopenButtonAction}
                disabled={isLoading}
                variant='purple-with-border-sm'
                classes='mt-4 md:mt-auto w-6.867rem h-9 w-[8.5rem] md:mr-2'
              >
                Reopen
              </Button>
            ) : null}
            <Button
              type='button'
              onClick={finalizeButtonAction}
              disabled={isLoading}
              variant='empty-with-border-sm'
              classes='mt-4 md:mt-auto h-9 w-[8.5rem]'
            >
              Finalize
            </Button>
            {!isReportFinished ? (
              <Button
                type='button'
                onClick={exitButtonAction}
                disabled={isLoading}
                variant='purple-with-border-sm'
                classes='mt-4 md:mt-auto w-6.867rem h-9 w-[8.5rem] md:ml-2'
              >
                {`${isReportFinished ? 'Save for later' : 'Close'}`}
              </Button>
            ) : null}
          </div>
        </div>
      ) : (
        <div className='w-full flex flex-col-reverse md:flex-row justify-between items-center md:items-end'>
          <Button
            type='button'
            onClick={exitButtonAction}
            disabled={isLoading}
            variant='empty-with-border-sm'
            classes='mt-4 md:mt-auto h-9 w-[8.5rem]'
          >
            Exit
          </Button>
          <div className='flex flex-col md:flex-row md:ml-auto'>
            {showPreviousButton ? (
              <Button
                type='button'
                onClick={previousButtonAction}
                disabled={isLoading || isPreviousButtonDisabled}
                variant='empty-with-border-sm'
                classes='mt-4 md:mt-auto h-9 w-[8.5rem] md:mr-2'
              >
                Previous
              </Button>
            ) : null}
            <Button
              type='button'
              onClick={nextButtonAction}
              disabled={isLoading || isNextButtonDisabled}
              variant='purple-with-border-sm'
              classes='mt-4 md:mt-auto w-6.867rem h-9 w-[8.5rem]'
            >
              {nextButtonText}
            </Button>
          </div>
        </div>
      )}
    </footer>
  </div>
);

export default ReportFooter;
