/* eslint-disable jsx-a11y/media-has-caption */
import { h } from 'preact';
import { route } from 'preact-router';
import { Button, toast } from 'src/components';
import OnboardingBase from 'src/pagesDashboard/Onboarding/OnboardingBase';
import appUtils from 'src/components/appUtils';
import COMMON_CONSTANTS from 'common/commonConstants';

const { PUBLIC_BUCKET_URL } = COMMON_CONSTANTS;

const OnboardingVideo = () => {
  const loggedUser = appUtils.getLoggedUser();

  if (!loggedUser) {
    toast.show('Please login to continue');
    return route(appUtils.getBaseUrl());
  }

  return (
    <OnboardingBase>
      <div className='w-full text-center'>
        <p className='text-xl font-bold m-0 text-black'>Welcome to WorkStory</p>
        <p className='m-0'>
          We’re glad to have you onboard. Here’s a quick overview to get you up
          and running.
        </p>
      </div>

      <video
        src={`${PUBLIC_BUCKET_URL}/3.0/videos/Intro to WorkStory.mp4`}
        poster={`${PUBLIC_BUCKET_URL}/3.0/videos/onboarding-thumbnail.png`}
        controls
      />

      <Button
        variant='purple'
        onClick={() => route('/dashboard/onboarding/comms')}
        classes='!rounded-md !w-3/5 !h-11 !mx-auto'
      >
        Continue
      </Button>
    </OnboardingBase>
  );
};

export default OnboardingVideo;
