import { isNumber, isEmpty } from 'lodash';
import { Fragment, h } from 'preact';
import Checkbox from 'src/components/Checkbox/Checkbox';
import { appUtils } from 'src/components/index';
import Pagination from 'src/components/Pagination/Pagination';
import { BADGE_COLOR_CLASSES } from 'src/componentsTailwind/tailwindConstants';

export const TYPES = {
  ACTION: 'action',
  BADGES: 'badges',
  CHECKBOX: 'checkbox',
  IMAGE: 'image'
};

const defaultRenderEmptyPlaceholder = (message = 'No data available') => (
  <div className='flex justify-center items-center h-52 w-full'>
    <p className='text-black text-md m-0'>{message}</p>
  </div>
);

const Table = ({
  columns,
  rows,
  pagination = {},
  placeholderMessage = 'No data available',
  renderEmptyPlaceholder = defaultRenderEmptyPlaceholder
}) => {
  const { currentPage, setCurrentPage, totalPages } = pagination;
  const isPaginated = !isEmpty(pagination);

  const renderHeader = (column) => {
    const colSpan = isNumber(column.span) ? column.span * 10 : 1 * 10;
    if (column.type === TYPES.CHECKBOX) {
      return (
        <th colSpan={colSpan}>
          <span
            className={`flex justify-center items-center  ${column.hidden ? 'hidden' : ''}`}
          >
            <Checkbox
              onChange={column.onChange}
              value={column.value}
              disabled={column.disabled}
            />
          </span>
        </th>
      );
    }

    return (
      <th className='px-4 py-3' colSpan={colSpan}>
        <div
          className={`text-left text-sm font-semibold text-gray-900 ${column.hidden ? 'hidden' : ''}`}
        >
          {column.label}
        </div>
      </th>
    );
  };

  const renderCell = (column, row) => {
    const colSpan = isNumber(column.span) ? column.span * 10 : 1 * 10;
    if (row.type === TYPES.ACTION) {
      const { hidden, disabled } = row;
      return (
        <td className='px-4 py-3' colSpan={colSpan}>
          <span className='flex justify-center'>
            <button
              className={`text-indigo-600 disabled:text-mid-gray enabled:hover:text-indigo-900 text-center text-sm font-medium ${hidden ? 'hidden' : ''}`}
              onClick={row.onClick}
              disabled={disabled}
            >
              {row.label}
            </button>
          </span>
        </td>
      );
    }

    if (row.type === TYPES.BADGES) {
      // console.log('badges are', label);
      return (
        <td className='px-4 py-3' colSpan={colSpan}>
          <div className='flex flex-wrap gap-1'>
            {row.badges.map(
              ({
                label,
                labelClasses = '',
                badgeClasses = '',
                colorClasses = BADGE_COLOR_CLASSES.GREEN
              }) => (
                <button
                  disabled
                  className={`tooltip relative rounded-md px-2 py-[2px] ring-1 ring-inset w-fit truncate ${badgeClasses} ${colorClasses}`}
                >
                  <span className='tooltip-text w-fit bg-black text-white text-xs font-medium whitespace-nowrap -top-[35px] left-[0%]'>
                    {label}
                  </span>
                  <div
                    className={`m-0 leading-4 text-xs font-medium truncate max-w-[100px] ${labelClasses}`}
                  >
                    {label}
                  </div>
                </button>
              )
            )}
          </div>
        </td>
      );
    }

    if (row.type === TYPES.CHECKBOX) {
      return (
        <td colSpan={colSpan}>
          <span className='flex justify-center items-center'>
            <Checkbox
              onChange={row.onChange}
              value={row.value}
              disabled={row.disabled}
            />
          </span>
        </td>
      );
    }

    if (row.type === TYPES.IMAGE) {
      const { imageClasses = '' } = row;
      return (
        <td className='py-3' colSpan={colSpan}>
          <div className='flex items-center justify-center m-auto w-full h-full'>
            <img
              className={`object-cover grow-0 shrink-0 size-fit ${imageClasses}`}
              src={row.imageSrc}
              alt='row'
            />
          </div>
        </td>
      );
    }

    return (
      <td className='px-4 py-3' colSpan={colSpan}>
        <span className='tooltip relative'>
          <span className='tooltip-text w-fit bg-black text-white text-xs font-medium whitespace-nowrap -top-[35px] left-[0%]'>
            {row.label}
            {row.underlabel ? ` - ${row.underlabel}` : ''}
          </span>
          <div
            className={`m-0 text-gray-500 text-sm ${column.multiline ? 'multiline-ellipsis' : 'truncate'} ${row.labelClasses || ''}`}
          >
            {row.label}
            {row.underlabel ? (
              <div
                className={`m-0 text-gray-400 text-xs truncate leading-4 ${row.underlabelClasses || ''}`}
              >
                {row.underlabel}
              </div>
            ) : null}
          </div>
        </span>
      </td>
    );
  };

  const isDataEmpty = isEmpty(rows);
  return (
    <div className='py-2 px-4 inline-block'>
      <div className='overflow-x-auto w-full'>
        <table className='w-full max-w-[100%] table-fixed'>
          <thead className='border-b border-gray-300'>
            <tr>{columns.map((column) => renderHeader(column))}</tr>
          </thead>

          <tbody className='bg-white'>
            {rows.map((row) => (
              <tr>
                {columns.map((column, index) => renderCell(column, row[index]))}
              </tr>
            ))}
          </tbody>
        </table>
        {isDataEmpty ? (
          <Fragment>{renderEmptyPlaceholder(placeholderMessage)}</Fragment>
        ) : null}
      </div>
      {isPaginated ? (
        <Pagination
          name='Table'
          totalPages={totalPages}
          currentPage={currentPage}
          selectPage={setCurrentPage}
        />
      ) : null}
    </div>
  );
};

export default Table;
