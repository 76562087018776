import { h } from 'preact';
import appUtils from 'src/components/appUtils';
import { Base } from 'src/components';
import STYLE from 'src/constants/style';
import { route } from 'preact-router';
import { useCompany } from 'src/queries/company';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useReviews } from 'src/queries/reviews';
import commonDateUtils from 'common/commonDateUtils';
import { usePulseReviews } from 'src/queries/pulse';
import { useEvents } from 'src/queries/events';

const {
  ENABLED_STATUS, REVIEW_STATUS, ACCESS, EVENT_TYPES
} = COMMON_CONSTANTS;

const HomeOrganization = () => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isReadyFirst = company && company.id && !isFetchingCompany && !isErrorCompany;

  if (!isReadyFirst) {
    return null;
  }
  const loggedUser = appUtils.getLoggedUser();
  const now = commonDateUtils.getUnixDateNow();
  const oneMonthAgoFromNow = commonDateUtils.getUnixDateFromDaysAgo(30);
  const oneMonthFromNow = commonDateUtils.getUnixDateFromNow(30);

  const {
    data: { reviews: reviewsCompleted },
    isFetching: isFetchingReviewsCompleted,
    isError: isErrorReviewsCompleted
  } = useReviews({
    companyId: company.id,
    filters: {
      status: REVIEW_STATUS.REVIEWED,
      start: oneMonthAgoFromNow,
      end: now
    },
    options: {
      size: 10000,
      page: 1
    }
  });

  const {
    data: pulseReviewsCompleted,
    isFetching: isFetchingPulseReviewsCompleted,
    isError: isErrorPulseReviewsCompleted
  } = usePulseReviews({
    status: REVIEW_STATUS.REVIEWED,
    start: oneMonthAgoFromNow,
    end: now
  });

  const {
    data: reviewsScheduled,
    isFetching: isFetchingReviewsScheduled,
    isError: isErrorReviewsScheduled
  } = useEvents({
    isAdmin: loggedUser.access === ACCESS.ADMIN,
    filters: {
      type: EVENT_TYPES.REVIEW,
      start: now,
      end: oneMonthFromNow
    },
    options: {
      page: 1,
      size: 10000
    }
  });

  const {
    data: pulseReviewsScheduled,
    isFetching: isFetchingPulseReviewsScheduled,
    isError: isErrorPulseReviewsScheduled
  } = useEvents({
    isAdmin: loggedUser.access === ACCESS.ADMIN,
    filters: {
      type: EVENT_TYPES.PULSE.PULSE_REVIEW,
      start: now,
      end: oneMonthFromNow
    },
    options: {
      page: 1,
      size: 10000
    }
  });

  const isFetching = isFetchingReviewsScheduled
    || isFetchingReviewsCompleted
    || isFetchingPulseReviewsScheduled
    || isFetchingPulseReviewsCompleted;
  const isError = isErrorReviewsScheduled
    || isErrorReviewsCompleted
    || isErrorPulseReviewsScheduled
    || isErrorPulseReviewsCompleted;
  const isReady = pulseReviewsCompleted
    && reviewsScheduled
    && reviewsScheduled.events
    && !isFetching
    && !isError;

  if (!isReady) {
    return null;
  }

  const { events } = company;

  const goToPendingReviews = () => {
    route(`/dashboard/organization/chart/?scrollToReviews=true`);
  };

  const goToEvents = () => {
    route(`/dashboard/organization/events`);
  };

  const goToPendingPulse = () => {
    route(`/dashboard/pulse/?scrollToPendingPulse=true`);
  };

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <div className='w-full'>
        <p className='m-0 mt-2 font-semibold text-3xl text-black leading-8'>
          Organization Status
        </p>
        <p className='my-2 font-normal text-md text-[#6B7280] leading-8'>
          This section is only visible for organization administrators
        </p>
      </div>
      {events.reviews === ENABLED_STATUS.ENABLED ? (
        <div className='w-full px-6 py-4'>
          <div className='flex flex-row items-center justify-between w-full'>
            <div className='tracking-wide w-13.5/20 shrink-0 grow-0 pr-2 mini:pr-6'>
              <p className='m-0 text-md truncate pb-2'>
                Review events are
                {' '}
                <strong>live</strong>
              </p>
              <p className='m-0 text-sm text-gray-500 truncate'>
                {`${company.name} has completed ${reviewsCompleted.length} reviews in the last month.`}
              </p>
            </div>
            <button
              onClick={goToEvents}
              className='task-action text-white text-md h-12 text-center font-semibold tracking-wide w-5/20 rounded-md p-1 mini:py-3 mini:px-4 focus:outline-none bg-purple hover:bg-hover-purple mr-5'
            >
              See Schedule
            </button>
          </div>
          <p className='m-0 text-sm text-gray-500 truncate'>
            {`It has ${reviewsScheduled.events.length} scheduled in the next month.`}
          </p>
        </div>
      ) : null}
      {events.reviews === ENABLED_STATUS.DISABLED ? (
        <div className='w-full px-6 py-4'>
          <div className='flex flex-row items-center justify-between w-full'>
            <div className='tracking-wide w-13.5/20 shrink-0 grow-0 pr-2 mini:pr-6'>
              <p className='m-0 text-md truncate pb-2'>
                Review events are
                {' '}
                <strong>not live</strong>
              </p>
              <p className='m-0 text-sm text-gray-500 leading-5'>
                Turn on Review events in order to enable automatic review
                scheduling
              </p>
            </div>
            <button
              onClick={goToEvents}
              className='task-action text-white text-md h-12 text-center font-semibold tracking-wide w-5/20 rounded-md p-1 mini:py-3 mini:px-4 focus:outline-none bg-purple hover:bg-hover-purple mr-5'
            >
              Enable Reviews
            </button>
          </div>
        </div>
      ) : null}
      {events.pulse === ENABLED_STATUS.ENABLED ? (
        <div className='w-full px-6 py-4'>
          <div className='flex flex-row items-center justify-between w-full'>
            <div className='tracking-wide w-13.5/20 shrink-0 grow-0 pr-2 mini:pr-6'>
              <p className='m-0 text-md truncate pb-2'>
                Pulse events are
                {' '}
                <strong>live</strong>
              </p>
              <p className='m-0 text-sm text-gray-500 truncate'>
                {`${company.name} has completed ${pulseReviewsCompleted.total} pulse reviews in the last month.`}
              </p>
            </div>
            <button
              onClick={goToPendingPulse}
              className='task-action text-white text-md h-12 text-center font-semibold tracking-wide w-5/20 rounded-md p-1 mini:py-3 mini:px-4 focus:outline-none bg-purple hover:bg-hover-purple mr-5'
            >
              See Schedule
            </button>
          </div>
          <p className='m-0 text-sm text-gray-500 truncate'>
            {`It has ${pulseReviewsScheduled.events.length} scheduled in the next month.`}
          </p>
        </div>
      ) : null}
      {events.pulse === ENABLED_STATUS.DISABLED ? (
        <div className='w-full px-6 py-4'>
          <div className='flex flex-row items-center justify-between w-full'>
            <div className='tracking-wide w-13.5/20 shrink-0 grow-0 pr-2 mini:pr-6'>
              <p className='m-0 text-md truncate pb-2'>
                Pulse events are
                {' '}
                <strong>not live</strong>
              </p>
              <p className='m-0 text-sm text-gray-500'>
                Turn on Pulse events in order to enable automatic pulse review
                scheduling
              </p>
            </div>
            <button
              onClick={goToEvents}
              className='task-action text-white text-md h-12 text-center font-semibold tracking-wide w-5/20 rounded-md p-1 mini:py-3 mini:px-4 focus:outline-none bg-purple hover:bg-hover-purple mr-5'
            >
              Enable Pulse
            </button>
          </div>
        </div>
      ) : null}
    </Base>
  );
};

export default HomeOrganization;
