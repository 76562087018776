import { h } from 'preact';
import { useForm, Controller } from 'react-hook-form';
import { useCompany } from 'src/queries/company';
import COMMON_CONSTANTS from 'common/commonConstants';
import {
  Button, Modal, Base, Checkbox
} from 'src/components/';
import { useUpdateReport } from 'src/hooks/UserReports/useUpdateReport';

const { VIEW_SCORES_PERMISSIONS } = COMMON_CONSTANTS;

const ExportReportModal = ({
  close,
  userId,
  reportId,
  showBundled,
  classes
}) => {
  const { data: company, isFetching: isCompanyFetching } = useCompany();
  const { pdf: exportAsPdf } = useUpdateReport(reportId);
  if (!userId || isCompanyFetching) {
    return null;
  }
  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitted }
  } = useForm({
    defaultValues: {
      isCompactReport: true,
      showScores: true
    }
  });

  const formValues = watch();

  const generateReportPFD = () => {
    if (formValues.isCompactReport) {
      exportAsPdf({
        reportId,
        options: 'COMPACT_REPORT',
        showBundled,
        isScoresVisible: formValues.showScores
      });
      return;
    }
    exportAsPdf(reportId, 'FULL_REPORT', showBundled, formValues.showScores);
  };

  return (
    <Modal
      variant='custom'
      close={close}
      classes={`shadow text-left z-100 ${classes}`}
      innerClasses='w-full max-w-42rem text-left'
    >
      <Base
        classes='text-left shadow-none z-20'
        loadingClass='display-none'
        loading={isCompanyFetching}
      >
        <h5 className='font-bold text-xl mb-5'>Export report</h5>
        <Controller
          name='showScores'
          defaultValue={
            company.settings.viewScores === VIEW_SCORES_PERMISSIONS.MANAGERS
          }
          control={control}
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              label='Show scores'
              classes='text-lg font-semibold'
              onChange={onChange}
              value={value}
            />
          )}
        />
        <p className='ml-6 text-gray-400'>If unchecked, emojis are displayed</p>
        <Controller
          name='isCompactReport'
          defaultValue
          control={control}
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              label='Compact report'
              classes='text-lg font-semibold'
              onChange={onChange}
              value={value}
            />
          )}
        />
        <p className='ml-6 mb-20 text-gray-400'>
          If unchecked, a full report will be exported
        </p>

        <div className='w-full inline-block text-right'>
          <Button
            variant='yellow'
            onClick={handleSubmit(generateReportPFD)}
            disabled={isSubmitted}
          >
            Generate
          </Button>
        </div>
      </Base>
    </Modal>
  );
};

export default ExportReportModal;
