import { h } from 'preact';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import commonQuestions from 'common/commonQuestions';
import STYLE from 'src/constants/style';
import { Base } from 'src/components/index';
import COMMON_CONSTANTS from 'common/commonConstants';
import SearchSVG from '../../../assets/search.svg';
import ArrowRight from '../../../assets/arrowRight.svg';
import PlusCircle from '../../../assets/plusInCircle.svg';
import EditSvg from '../../../assets/edit-3.svg';
import SHARED_CONSTANTS from '../../../common/sharedConstants';

const { DEFAULT_ROLE } = COMMON_CONSTANTS;
const { EDIT_MODES } = SHARED_CONSTANTS;

const RoleColumn = ({
  companyQuestions,
  selectedRole,
  selectRole,
  editRole,
  addRole
}) => {
  let ROLES_LIST = commonQuestions.convertCompanyRolesToList(
    companyQuestions.ROLES
  );
  ROLES_LIST = ROLES_LIST.filter(
    (r) => r.status === COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE
      && r.id !== DEFAULT_ROLE.id
  ).sort((a, b) => (a.label > b.label ? 1 : -1));

  return (
    <Base className={STYLE.CONTAINER_GRAY_PADDINGLESS}>
      <div className='w-full h-full pt-6 px-2'>
        {/*
              <a class="inline-block relative mb-0 mr-4">
                <SearchSVG />
              </a>
            */}
        <h4 className='inline-block mr-3  ml-4'>Roles</h4>
        <a
          onClick={() => {
            editRole(EDIT_MODES.ADD);
          }}
          className='inline-block float-right mr-3 mb-0'
        >
          <PlusCircle />
        </a>
        <div className='clear-both' />
        <div className='overflow-scroll h-41rem'>
          {ROLES_LIST.length
            ? ROLES_LIST.map((role) => (
              <div
                className={`hover:bg-purple hover:text-white rounded-lg m-2 ml-0 ${
                  selectedRole === role.key
                    ? 'bg-purple text-white'
                    : 'bg-white'
                }`}
              >
                <a
                  onClick={() => {
                    editRole(EDIT_MODES.EDIT, role, role.key);
                  }}
                  className='inline-block w-1/12 mb-0 pt-2 pb-2 pl-3 hover:visible'
                >
                  <EditSvg class='w-4 hover:stroke-yellow' />
                </a>
                <a
                  onClick={(e) => {
                    selectRole(e, role, role.key);
                  }}
                  className={`inline-block w-9/12 mb-0 p-4 pt-2 pb-2 font-bold linkWhite hover:no-underline
                         ${selectedRole === role.key ? 'white' : ''}
                        `}
                >
                  {role.label}
                </a>
                <a
                  onClick={(e) => {
                    selectRole(e, role, role.key);
                  }}
                  className='inline-block w-2/12 mb-0 p-4 pt-3 pb-2'
                >
                  <ArrowRight class='float-right' />
                </a>
              </div>
            ))
            : null}
        </div>
      </div>
    </Base>
  );
};

export default RoleColumn;
