import { h } from 'preact';
import { RadioCards } from 'src/componentsTailwind/index';
import MSTeamsIconSVG from 'src/assets/svg/msteams-icon.svg';
import SlackIconSVG from 'src/assets/svg/slack-icon.svg';
import WebexIconSVG from 'src/assets/svg/webex-icon.svg';
import EmailIconSVG from 'src/assets/svg/email-icon.svg';
import COMMON_CONSTANTS from 'common/commonConstants';

const { COMMUNICATION_TYPES } = COMMON_CONSTANTS;

const OnboardingRadioCards = ({
  selectedCommunication,
  setSelectedCommunication
}) => {
  const cards = [
    {
      id: COMMUNICATION_TYPES.MS_TEAMS,
      label: 'Microsoft Teams',
      icon: <MSTeamsIconSVG className='w-full h-full aspect-square' />
    },
    {
      id: COMMUNICATION_TYPES.SLACK,
      label: 'Slack',
      icon: <SlackIconSVG className='w-full h-full aspect-square' />
    },
    {
      id: COMMUNICATION_TYPES.WEBEX,
      label: 'Cisco Webex',
      icon: <WebexIconSVG className='w-full h-full aspect-square' />
    },
    {
      id: COMMUNICATION_TYPES.EMAIL,
      label: 'Email',
      icon: <EmailIconSVG className='w-full h-full aspect-square' />
    }
  ];

  return (
    <RadioCards
      selectedCard={cards.find((card) => card.id === selectedCommunication)}
      setSelectedCard={({ id }) => setSelectedCommunication(id)}
      cards={cards}
    />
  );
};

export default OnboardingRadioCards;
