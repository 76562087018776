import { h } from 'preact';
import { useEffect, useState, useRef } from 'preact/hooks';
import { route } from 'preact-router';
import { useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { xor } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNewUserReportContext } from 'src/pagesDashboard/NewUserReport/context/NewUserReportProvider';
import { useTree } from 'src/queries/tree';
import {
  useReport,
  shareReportQuery,
  updateReportQuery,
  QUERY_KEYS as REPORTS_QUERY_KEYS
} from 'src/queries/reports';
import {
  useAccount,
  QUERY_KEYS as ACCOUNT_QUERY_KEYS
} from 'src/queries/account';
import useUpdateReport from 'src/hooks/UserReports/useUpdateReport';
import {
  ConfirmationModal,
  Select,
  Multiselect,
  Button,
  toast
} from 'src/components';
import { ALL_STEPS } from 'src/pagesDashboard/NewUserReport/utils';
import buildParams from 'src/queries/utils/buildParams';
import BinSVG from 'src/assets/svg/bin-v2.svg';
import ShareV2 from 'src/assets/svg/share-v2.svg';
import appUtils from 'src/components/appUtils';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonPermissions from 'common/commonPermissions';
import DownloadFile from 'src/assets/svg/download-file.svg';

const { USER_STATE, REPORT_STATUS, INCLUDE_SCORES_OPTIONS } = COMMON_CONSTANTS;

const getInitialUserOptions = (treeList, loggedUserId) => {
  if (!treeList || !treeList.length || !loggedUserId) return [];

  const users = treeList
    .filter((n) => [USER_STATE.ACTIVE, USER_STATE.PASSIVE].includes(n.active))
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    .map((t) => ({
      id: t.id,
      label: `Share with ${t.name}`,
      checked: false,
      disabled: t.id === loggedUserId
    }));
  return users;
};

const getUserOptions = ({
  treeList,
  reportShareList,
  currentUserOptions,
  loggedUserId
}) => {
  if (reportShareList && reportShareList.length && currentUserOptions.length) {
    const filteredUserOptions = currentUserOptions.map((user) => {
      if (reportShareList.find((shareUser) => shareUser.id === user.id)) {
        return { ...user, checked: true };
      }
      return user;
    });
    return filteredUserOptions;
  }

  return getInitialUserOptions(treeList, loggedUserId);
};

const getMultiSelectedOptions = (options) => options.filter((u) => u.checked).map((u) => u.id);

const NewUserReportShare = (props) => {
  const { userId, reportId } = props;
  const { updateContext, context } = useNewUserReportContext();
  const { showStatsOnReportBuilder } = useFlags();
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);

  const formRef = useRef();

  const { reopenReport, refreshReports } = context;

  const loggedUser = appUtils.getLoggedUser();
  const queryClient = useQueryClient();

  useEffect(() => {
    updateContext({ formRef, activeStep: ALL_STEPS.FINALIZE });
  }, []);

  const invalidateReportQueries = () => {
    queryClient.invalidateQueries([REPORTS_QUERY_KEYS.REPORT, reportId]);
    queryClient.invalidateQueries(REPORTS_QUERY_KEYS.SHARED_REPORTS);
    queryClient.invalidateQueries(REPORTS_QUERY_KEYS.MY_REPORTS);
    queryClient.invalidateQueries(ACCOUNT_QUERY_KEYS.TASKS);
  };

  const { data: report, isFetching: isFetchingReport } = useReport(reportId);
  const { mutateAsync: shareReport, isLoading: isShareReportLoading } = shareReportQuery();
  const { mutateAsync: updateReport, isLoading: isUpdateReportLoading } = updateReportQuery();
  const { remove: deleteReport, pdf: exportAsPdf } = useUpdateReport(reportId);

  const generateReportPFD = async () => {
    setIsLoadingPdf(true);
    await exportAsPdf({
      reportId,
      isScoresVisible: report.includeScores === INCLUDE_SCORES_OPTIONS.YES ,
      hideStats: !showStatsOnReportBuilder,
      compareToReport: report.previousReport
    });
    setIsLoadingPdf(false);
  };

  const {
    data: { treeList, myTreeRow, tree },
    isFetching: isFetchingTree
  } = useTree();

  const { data: accountData, isFetching: isFetchingAccount } = useAccount('me');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleteReportLoading, setIsDeleteReportLoading] = useState(false);

  const [userOptions, setUserOptions] = useState([]);
  const [newStatus, setNewStatus] = useState(report.status);

  const { handleSubmit } = useForm({
    defaultValues: {}
  });

  const isFetching = isFetchingReport || isFetchingTree || isFetchingAccount;

  useEffect(() => {
    updateContext({
      isLoading: isFetching
    });
  }, [isFetching]);

  useEffect(() => {
    if (reopenReport) {
      setNewStatus(REPORT_STATUS.IN_PROGRESS);
      updateContext({ reopenReport: false, refreshReports: !refreshReports });
    }
  }, [context]);

  if (isFetching) return <div className='h-full' />;

  useEffect(() => {
    const newUserOptions = getUserOptions({
      treeList,
      reportShareList: report.share,
      currentUserOptions: userOptions,
      loggedUserId: loggedUser.id
    });
    setUserOptions(newUserOptions);
  }, [treeList, report, userOptions.length]);

  const isReportAuthor = loggedUser.id === report.createdBy;
  const isViewerDirectlyAboveUser = commonTreeUtils.isNodeDirectlyAbove(
    tree,
    report.user,
    myTreeRow.id
  );
  const canManageAccount = commonPermissions.canManageAccounts(accountData, [
    report.user
  ]);

  const canUpdateReport = commonPermissions.isAdmin(accountData)
    || isViewerDirectlyAboveUser
    || isReportAuthor
    || canManageAccount;

  // const statusOptions = Object.values(REPORT_STATUS).map((status) => ({
  //   id: status,
  //   label: status
  // }));

  const updateChangeShareWith = async () => {
    try {
      const selected = getMultiSelectedOptions(userOptions);
      const removed = report.share
        .filter((u) => !selected.includes(u.id))
        .map((u) => u.id);

      await shareReport({
        reportId,
        users: {
          new: selected,
          removed
        }
      });
      queryClient.invalidateQueries([REPORTS_QUERY_KEYS.REPORT, reportId]);
      queryClient.invalidateQueries(REPORTS_QUERY_KEYS.SHARED_REPORTS);
      toast.show('Report shared');
    } catch (error) {
      console.error('Failed to share report', error);
      return toast.error(error ?? 'Failed to share report');
    }
  };

  const updateStatus = async (updateToStatus) => {
    try {
      await updateReport({
        reportId,
        status: updateToStatus
      });
      invalidateReportQueries();
      updateContext({ refreshReports: !refreshReports });
      toast.show('Report status updated');
    } catch (error) {
      console.error('Failed to update report status', error);
      return toast.error(error ?? 'Failed to update report status');
    }
  };

  const onSelectShareWith = (option, type) => {
    if (type === 'select') {
      const foundInOption = userOptions.find((opt) => opt.id === option.id);
      if (foundInOption) {
        const newOptions = userOptions.map((opt) => {
          if (opt.id === foundInOption.id) {
            return { ...opt, checked: !opt.checked };
          }
          return opt;
        });
        setUserOptions(newOptions);
      }
    }
  };

  const onDeleteReport = async () => {
    setIsDeleteReportLoading(true);

    const routeToGo = loggedUser.id === userId
      ? `/dashboard/me/reports`
      : `/dashboard/profile/${userId}/reports`;

    await deleteReport();
    invalidateReportQueries();
    route(routeToGo);
  };

  const finalizeReport = () => {
    queryClient.invalidateQueries([
      REPORTS_QUERY_KEYS.REPORTS,
      buildParams({ userId })
    ]);
    invalidateReportQueries();
    const routeToGo = loggedUser.id === userId
      ? `/dashboard/me/reports`
      : `/dashboard/profile/${userId}/reports`;
    return route(routeToGo);
  };

  // const isDirtyStatus = newStatus !== report.status;
  const isDirtyShare = !!xor(
    report.share.map((u) => u.id),
    getMultiSelectedOptions(userOptions)
  ).length;

  // const isStatusSelectDisabled = report.status === REPORT_STATUS.FINISHED || !canUpdateReport;
  // const isStatusSelectDisabled = false;
  // const isUpdateDisabled = !canUpdateReport || !isDirtyStatus || isUpdateReportLoading;

  return (
    <form
      id='new-user-report-share-form'
      ref={formRef}
      className='flex flex-col h-full w-full justify-between'
      onSubmit={handleSubmit(finalizeReport)}
    >
      {isLoadingPdf ? (
        <div className='fixed inset-0 z-20 backdrop-blur-[2px]' />
      ) : null}
      {showDeleteModal ? (
        <ConfirmationModal
          id={reportId}
          onAction={onDeleteReport}
          onClose={() => setShowDeleteModal(false)}
          title='Delete Report'
          subtitle='Are you sure you would like to delete this report?'
          actionText='Delete'
          isLoading={isDeleteReportLoading}
        />
      ) : null}
      <div className='flex flex-col md:mb-30 my-auto'>
        <div className='flex flex-col w-3/5 mb-5 mx-auto'>
          <div className='flex flex-col justify-between w-full border border-gray-200 rounded p-4 shadow-md'>
            <p className='mb-2 font-bold'>Status</p>
            <p className='mb-4 text-md text-gray-500'>
              The performance review status is used to determine when a review
              is complete or not. Outstanding tasks related to review will stay
              open until the review is finalized.
            </p>
            <div className='w-full mb-1'>
              <div className='w-full justify-between text-right'>
                {report.status === REPORT_STATUS.FINISHED ? (
                  <Button
                    classes='w-1/3 h-9 mr-2 rounded-md px-4 py-1'
                    variant='purple-with-border-sm'
                    onClick={() => updateStatus(REPORT_STATUS.IN_PROGRESS)}
                    disabled={false}
                  >
                    Reopen
                  </Button>
                ) : (
                  <Button
                    classes='w-1/3 h-9 mr-2 rounded-md px-4 py-1'
                    variant='purple-with-border-sm'
                    onClick={() => updateStatus(REPORT_STATUS.FINISHED)}
                    disabled={false}
                  >
                    Finalize
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col w-3/5 mt-10 mb-5 mx-auto'>
          <div className='flex flex-col justify-between w-full border border-gray-200 rounded p-4 shadow-md'>
            <p className='mb-2 font-bold'>Share</p>
            <p className='mb-4 text-md text-gray-500'>
              When you share a performance review, it creates a task for that
              user to collaborate on the review. They will have that task until
              the performance review is finalized.
            </p>
            <div className='w-full mb-1 relative'>
              <div className='flex flex-row w-full justify-between relative'>
                <Multiselect
                  classes='w-[60%]'
                  title='Share with..'
                  options={userOptions}
                  placeholder='Share with..'
                  onChange={(option, type) => onSelectShareWith(option, type)}
                  multiselect
                  disabled={!canUpdateReport}
                />
                <Button
                  classes='w-1/3 h-9 text-sm mr-2 rounded-md bg-purple text-white px-4 py-1 font-bold answer transition-colors duration-300 flex items-center justify-center'
                  variant='custom'
                  onClick={updateChangeShareWith}
                  disabled={
                    !canUpdateReport || !isDirtyShare || isShareReportLoading
                  }
                >
                  <ShareV2 className='w-4 h-4 mr-1.5 mb-0.5' />
                  Share
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col w-3/5 mt-10 mb-5 mx-auto'>
          <div className='flex flex-col justify-between w-full border border-gray-200 rounded p-4 shadow-md'>
            <p className='mb-2 font-bold'>Options</p>
            <p className='mb-4 text-md text-gray-500 leading-4'>
              This review has been drafted. You can download the latest version
              or delete it as necessary.
            </p>
            <div className='w-full mb-1'>
              <div className='flex flex-row w-full justify-between'>
                <Button
                  classes='w-1/2 mr-3 h-9 px-4 py-0 flex items-center justify-center'
                  variant='purple-with-border-sm'
                  onClick={() => generateReportPFD()}
                  disabled={isFetching}
                >
                  <DownloadFile className='w-4 h-4 text-white mr-1.5 mb-0.5' />
                  Download Report
                </Button>
                {canUpdateReport ? (
                  <button
                    onClick={() => setShowDeleteModal(true)}
                    disabled={isFetching}
                    type='button'
                    className='flex h-9 items-center border border-hover-red text-hover-red bg-white hover:bg-red-warning px-3 py-2 w-1/2 rounded-md transition-colors duration-300 focus:outline-none items-center justify-center'
                  >
                    <BinSVG className='w-4 h-4 text-hover-red mr-2 mb-0.5' />
                    Delete Report
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-row flex-wrap gap-4 items-center justify-center md:justify-center text-md w-full md:w-28rem m-auto mt-10'>
          {/* <button className='flex items-center justify-around gap-2 text-black px-3 py-2 font-bold border border-black hover:text-dark-grey hover:border-dark-grey rounded transition-colors duration-300'>
            <ClipboardListSVG className='w-6 h-6' />
            Copy Link
          </button> */}
        </div>
      </div>
    </form>
  );
};

export default NewUserReportShare;
