import { h } from 'preact';
import appUtils from 'src/components/appUtils';

const HeaderV2 = ({
  title = 'title',
  overtitle,
  icon = null,
  titleClasses = 'text-3xl font-extrabold',
  overtitleClasses = 'text-md font-medium'
}) => {
  const appSize = appUtils.getAppSize();

  return (
    <div className={`px-5 bg-white pb-8 ${overtitle ? 'pt-1' : 'pt-9'}`}>
      <div className={`mb-0 text-zinc-500 leading-10 ${overtitleClasses}`}>
        {overtitle}
      </div>
      <div className='flex w-full gap-2 justify-start items-center min-h-8'>
        {icon ? <div className='h-full'>{icon}</div> : null}
        <p className={`inline-block mb-0 text-black ${titleClasses}`}>
          {title}
        </p>
      </div>
    </div>
  );
};

export default HeaderV2;
