import { h } from 'preact';
import { useState } from 'preact/hooks';
import { route } from 'preact-router';
import { isEmpty } from 'lodash';
import appUtils from 'src/components/appUtils';
import { Base, toast } from 'src/components/';
import STYLE from 'src/constants/style';
import commonDateUtils from 'common/commonDateUtils';
import { TYPES } from 'src/componentsTailwind/Table/Table';
import { Table } from 'src/componentsTailwind';
import { useCompanyAsDataset } from 'src/queries/company';
import { useBuilderDataset, useBuilderReports } from 'src/queries/builder';
import builderUtils from 'src/pages/PerformanceBuilder/builderUtils';
import commonUtils from 'common/commonUtils';
import BuilderDashboardFilters from 'src/pages/PerformanceBuilder/BuilderDashboardFilters';
import COMMON_CONSTANTS from 'common/commonConstants';

const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

const renderEmptyPlaceholder = (areThereReports) => (
  <div className='flex justify-center items-center h-52 w-full'>
    <p className='text-black text-md m-0'>
      {areThereReports
        ? 'No performance reviews matching these filters'
        : 'No performance reviews created yet'}
    </p>
  </div>
);

const BuilderDashboard = () => {
  const loggedUser = appUtils.getLoggedUser();
  if (!loggedUser) {
    toast.show('Please log in to view this page');
    return route('/builder');
  }
  const { companyid } = loggedUser;
  const loggedUserId = loggedUser._id || loggedUser.id;

  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: builderDataset,
    isFetching: isFetchingBuilderDataset,
    isError: isErrorBuilderDataset
  } = useBuilderDataset();

  const {
    data: companyData,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompanyAsDataset(companyid, loggedUserId);

  const [filters, setFilters] = useState({
    roleIds: [],
    createdDate: {
      value: DATE_RANGE_FILTERS.THREE_MONTHS.key,
      start: commonDateUtils.getFirstUnixOfDate(
        commonDateUtils.getDateFromDaysAgo(DATE_RANGE_FILTERS.THREE_MONTHS.days)
          .date
      ),
      end: undefined
    }
  });
  const { roleIds, createdDate } = filters;

  const {
    data: singleReportData,
    isFetching: isFetchingSingleReport,
    isError: isErrorSingleReport
  } = useBuilderReports(
    {
      companyid
    },
    {
      viewerId: loggedUserId,
      page: { size: 1 }
    }
  );

  const {
    data: reports,
    meta: { page },
    isFetching: isFetchingReports,
    isRefetching: isRefetchingReports,
    isError: isErrorReports
  } = useBuilderReports(
    {
      companyid,
      createdDate,
      ...(!isEmpty(roleIds) && { roleIds })
    },
    {
      viewerId: loggedUserId,
      page: {
        number: currentPage,
        size: 10
      }
    },
    {
      keepPreviousData: true
    }
  );

  const isInitialFetchingReports = isFetchingReports && !isRefetchingReports;
  const isFetching = isInitialFetchingReports
    || isFetchingSingleReport
    || isFetchingCompany
    || isFetchingBuilderDataset;
  const isError = isErrorReports
    || isErrorSingleReport
    || isErrorCompany
    || isErrorBuilderDataset;
  const isReady = !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  if (isEmpty(companyData)) {
    toast.show(
      'Data unavailable. Redirecting to the performance review builder...'
    );
    route('/builder');
  }

  const [singleReport] = singleReportData;
  const areThereReports = Boolean(singleReport);

  const pagination = {
    currentPage,
    setCurrentPage,
    totalPages: page.totalPages
  };

  const columns = [
    {
      label: 'Date Created',
      span: 1.5
    },
    {
      label: 'Review Date',
      span: 1.5
    },
    {
      label: 'Reviewer',
      span: 1.5
    },
    {
      label: 'Reviewee',
      span: 1.5
    },
    {
      label: 'Title',
      span: 1.5
    },
    {
      label: 'Role',
      span: 1.5
    },
    {
      type: TYPES.ACTION,
      label: 'View',
      hidden: true,
      span: 0.5
    },
    {
      type: TYPES.ACTION,
      label: 'Edit',
      hidden: true,
      span: 0.5
    }
  ];

  const rows = reports.map((report) => {
    const dataset = builderUtils.getDatasetByCompanyMode(report.companyMode, {
      builderDataset,
      companyDataset: companyData.dataset
    });
    const {
      _id,
      editToken,
      createdDate,
      reviewDate,
      reviewer,
      reviewee,
      title,
      industryId,
      roleId
    } = report;

    let roleLabel = 'N/A';
    if (industryId && roleId) {
      const industryData = dataset.find(
        (industry) => industry.industryId === industryId
      );
      if (industryData) {
        const roleData = industryData.roles.find(
          (role) => role.roleId === roleId
        );
        if (roleData) roleLabel = roleData.name;
      }
    }

    return [
      {
        label: commonDateUtils.dateToMonthDayYearFormat(new Date(createdDate))
      },
      {
        label: commonDateUtils.dateToMonthDayYearFormat(new Date(reviewDate))
      },
      {
        label: commonUtils.getFullName(reviewer)
      },
      {
        label: commonUtils.getFullName(reviewee)
      },
      {
        label: title
      },
      {
        label: roleLabel
      },
      {
        type: TYPES.ACTION,
        label: 'View',
        onClick: () => route(`/builder/${_id}/view`)
      },
      {
        type: TYPES.ACTION,
        label: 'Edit',
        onClick: () => route(`/builder/${editToken}/edit`)
      }
    ];
  });

  const isLoading = isFetching || isRefetchingReports;
  return (
    <div className='w-full h-full flex flex-col mx-auto p-7'>
      <Base classes={STYLE.BASE}>
        <BuilderDashboardFilters filters={filters} setFilters={setFilters} />
        <Base classes={STYLE.CONTAINER_WHITE} loading={isLoading}>
          <div className='flex justify-between'>
            <div className='w-3/5'>
              <h5 className='text-black text-left text-xl mb-2'>
                Review Dashboard
              </h5>
            </div>
          </div>
          <Table
            columns={columns}
            rows={rows}
            pagination={pagination}
            renderEmptyPlaceholder={() => renderEmptyPlaceholder(areThereReports)}
          />
        </Base>
      </Base>
    </div>
  );
};

export default BuilderDashboard;
