import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { useGoals } from 'src/queries/goal';
import { useReport } from 'src/queries/reports';
import { GoalNewReport } from 'src/pagesDashboard/NewUserReport/components';

const GoalsListSection = ({
  userId,
  reportId,
  setIsLoading = () => {},
  isEditorOpenByDefault = true,
  viewOnly = false
}) => {
  const {
    data: report,
    isFetching: isFetchingReport,
    isError: isErrorReports
  } = useReport(reportId);

  if (isFetchingReport || isErrorReports) return <div />;

  const { data: goals, isFetching: isFetchingGoals } = useGoals(
    {
      assignees: [userId],
      createdDate: {
        start: report.start,
        end: report.end
      }
    },
    {
      page: {
        size: Number.MAX_SAFE_INTEGER
      }
    }
  );

  useEffect(() => {
    setIsLoading(isFetchingGoals);
  }, [isFetchingGoals]);

  return (
    <div className='flex flex-col mb-10'>
      {goals && goals.length
        ? goals.map((goal) => (
          <GoalNewReport
            reportId={reportId}
            previousReport={report.previousReport}
            userId={userId}
            goal={goal}
            viewOnly={viewOnly}
            isEditorOpenByDefault={isEditorOpenByDefault}
          />
        ))
        : null}

      {!isFetchingGoals && !goals.length ? (
        <h1 className='mt-10 text-2xl text-center'>No Goals available</h1>
      ) : null}
    </div>
  );
};

export default GoalsListSection;
