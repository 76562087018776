import { h } from 'preact';
import { route } from 'preact-router';
import { Button, toast } from 'src/components';
import OnboardingBase from 'src/pagesDashboard/Onboarding/OnboardingBase';
import appUtils from 'src/components/appUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useState } from 'preact/hooks';
import { useAccountUpdate } from 'src/queries/account';
import OnboardingRadioCards from 'src/pagesDashboard/Onboarding/OnboardingRadioCards';

const { COMMUNICATION_TYPES } = COMMON_CONSTANTS;

const OnboardingComms = () => {
  const loggedUser = appUtils.getLoggedUser();

  if (!loggedUser) {
    toast.show('Please login to continue');
    return route(appUtils.getBaseUrl());
  }

  const [selectedCommunication, setSelectedCommunication] = useState(
    COMMUNICATION_TYPES.EMAIL
  );

  const loggedUserId = loggedUser._id || loggedUser.id;
  const { update: updateAccount, isLoading: isLoadingAccountUpdate } = useAccountUpdate(loggedUserId);

  const routeToNextStep = async () => {
    if (selectedCommunication === COMMUNICATION_TYPES.EMAIL) {
      if (loggedUser.preferredCommunication !== COMMUNICATION_TYPES.EMAIL) {
        await updateAccount({
          data: { preferredCommunication: COMMUNICATION_TYPES.EMAIL }
        });
      }
      return route(`/dashboard/onboarding/complete`);
    }

    if (selectedCommunication === COMMUNICATION_TYPES.MS_TEAMS) {
      route(`/dashboard/onboarding/msteams/1`);
    }

    if (selectedCommunication === COMMUNICATION_TYPES.SLACK) {
      route(`/dashboard/onboarding/slack`);
    }

    if (selectedCommunication === COMMUNICATION_TYPES.WEBEX) {
      route(`/dashboard/onboarding/webex`);
    }
  };

  return (
    <OnboardingBase>
      <div className='w-full text-left'>
        <p className='text-xl font-bold m-0 text-black'>
          Choose your communication preference
        </p>
        <p className='m-0'>
          Pick the one that you use to communicate with your team most often.
        </p>
      </div>
      <OnboardingRadioCards
        selectedCommunication={selectedCommunication}
        setSelectedCommunication={setSelectedCommunication}
      />
      <div className='flex justify-between gap-4 w-full'>
        <Button
          variant='empty-with-border'
          classes='!rounded-md !w-1/2 !h-11'
          onClick={() => route(`/dashboard/onboarding/video`)}
        >
          Previous
        </Button>
        <Button
          variant='purple'
          classes='!rounded-md !w-1/2 !h-11'
          onClick={routeToNextStep}
          disabled={isLoadingAccountUpdate}
        >
          {isLoadingAccountUpdate ? 'Loading...' : 'Continue'}
        </Button>
      </div>
    </OnboardingBase>
  );
};

export default OnboardingComms;
