import { h } from 'preact';
import { useContext } from 'preact/hooks';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import commonViewPermissions from 'common/commonViewPermissions';
import FilterByReviewers from 'src/containers/UserProfile/FilterView/FilterByReviewers';
import FilterByReviewees from 'src/containers/UserProfile/FilterView/FilterByReviewees';
import FilterByRoles from 'src/containers/UserProfile/FilterView/FilterByRoles';
import FilterByRevieweeRoles from 'src/containers/UserProfile/FilterView/FilterByRevieweeRoles';
import FilterByCategories from 'src/containers/UserProfile/FilterView/FilterByCategories';
import FilterByRevieweeCategories from 'src/containers/UserProfile/FilterView/FilterByRevieweeCategories';
import FilterByStatus from 'src/containers/UserProfile/FilterView/FilterByStatus';
import FilterByDate from 'src/containers/UserProfile/FilterView/FilterByDate';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import { DashContext } from 'src/pagesDashboard/Dash/context/DashProvider';
import commonDateUtils from 'common/commonDateUtils';
import commonPermissions from 'common/commonPermissions';
import { useAccount } from 'src/queries/account';

const FilterView = ({
  userId,
  show = [],
  options = {},
  direction = 'row',
  showLabels = false,
  wrapperClasses = '',
  customContext,
  classes,
  selectClasses
}) => {
  const {
    data: { myTreeRow, tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');

  const isFetching = isFetchingTree || isFetchingCompany || isFetchingLoggedAccount;
  const isError = isErrorTree || isErrorCompany || isErrorLoggedAccount;
  const isReady = tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }
  const { id: companyid } = company;

  const canManageAccount = commonPermissions.canManageAccounts(loggedAccount, [
    userId
  ]);
  const canViewFilters = canManageAccount
    || commonViewPermissions.canViewUserProfileFilters(tree, myTreeRow.id, userId);

  if (!canViewFilters) {
    return null;
  }

  const { isReport } = options;
  const isMyProfile = !isReport && myTreeRow.id === userId;

  const {
    context,
    updateContext,
    resetPages = () => {}
  } = customContext
  || useContext(isMyProfile ? DashContext : UserProfileContext);

  const setRange = (value, start, end) => {
    const startValue = commonDateUtils.getFirstUnixOfDateFromUnix(
      start || commonDateUtils.dateToUnix('2020-01-01')
    );
    const endValue = commonDateUtils.getLastUnixOfDateFromUnix(
      end || commonDateUtils.dateToUnix(new Date())
    );

    if (isReport || value === 'custom') {
      updateContext({
        range: {
          value: 'custom',
          start: startValue,
          end: endValue
        }
      });
      return resetPages();
    }

    updateContext({
      range: {
        value,
        start: startValue,
        end: undefined
      }
    });
    return resetPages();
  };

  const updateFields = (fields) => {
    const newFilters = {
      ...context.filters,
      ...fields
    };

    updateContext({
      filters: newFilters,
      historicReviews: { ...context.historicReviews, page: 1 }
    });
    resetPages();
  };

  const { filters } = context;

  return (
    <div
      className={`flex ${
        direction === 'col' ? 'flex-col' : ''
      } mb-2 ${classes ?? ''}`}
    >
      {show.includes('reviewers') && (
        <div
          className={`${wrapperClasses} [&:not(:first-child)]:pl-1 [&:not(:last-child)]:pr-1`}
        >
          {showLabels && <div className='text-sm font-semibold'>Reviewers</div>}
          <FilterByReviewers
            isMyProfile={isMyProfile}
            userId={userId}
            updateFn={updateFields}
            reviewerIds={filters.reviewerIds}
            reviewerGroup={filters.reviewerGroup}
            customSelectClasses={selectClasses}
          />
        </div>
      )}
      {show.includes('reviewees') && (
        <div
          className={`${wrapperClasses} [&:not(:first-child)]:pl-1 [&:not(:last-child)]:pr-1`}
        >
          {showLabels && <div className='text-sm font-semibold'>Reviewees</div>}
          <FilterByReviewees
            isMyProfile={isMyProfile}
            userId={userId}
            updateFn={updateFields}
            revieweeIds={filters.revieweeIds}
            revieweeGroup={filters.revieweeGroup}
            customSelectClasses={selectClasses}
          />
        </div>
      )}
      {show.includes('roles') && (
        <div
          className={`${wrapperClasses} [&:not(:first-child)]:pl-1 [&:not(:last-child)]:pr-1`}
        >
          {showLabels && <div className='text-sm font-semibold'>Roles</div>}
          <FilterByRoles
            isMyProfile={isMyProfile}
            userId={userId}
            roles={filters.roles}
            updateFn={updateFields}
            customSelectClasses={selectClasses}
          />
        </div>
      )}
      {show.includes('reviewee-roles') && (
        <div
          className={`${wrapperClasses} [&:not(:first-child)]:pl-1 [&:not(:last-child)]:pr-1`}
        >
          {showLabels && (
            <div className='text-sm font-semibold'>Reviewee Roles</div>
          )}
          <FilterByRevieweeRoles
            isMyProfile={isMyProfile}
            userId={userId}
            roles={filters.roles}
            updateFn={updateFields}
            customSelectClasses={selectClasses}
          />
        </div>
      )}
      {show.includes('categories') && (
        <div
          className={`${wrapperClasses} [&:not(:first-child)]:pl-1 [&:not(:last-child)]:pr-1`}
        >
          {showLabels && (
            <div className='text-sm font-semibold'>Categories</div>
          )}
          <FilterByCategories
            isMyProfile={isMyProfile}
            userId={userId}
            category={filters.category}
            updateFn={updateFields}
            customSelectClasses={selectClasses}
          />
        </div>
      )}
      {show.includes('reviewee-categories') && (
        <div
          className={`${wrapperClasses} [&:not(:first-child)]:pl-1 [&:not(:last-child)]:pr-1`}
        >
          {showLabels && (
            <div className='text-sm font-semibold'>Reviewee Categories</div>
          )}
          <FilterByRevieweeCategories
            isMyProfile={isMyProfile}
            userId={userId}
            category={filters.category}
            updateFn={updateFields}
            customSelectClasses={selectClasses}
          />
        </div>
      )}
      {show.includes('status') && (
        <div
          className={`${wrapperClasses} [&:not(:first-child)]:pl-1 [&:not(:last-child)]:pr-1`}
        >
          {showLabels && <div className='text-sm font-semibold'>Status</div>}
          <FilterByStatus
            isMyProfile={isMyProfile}
            companyid={companyid}
            userId={userId}
            status={filters.status}
            updateFn={updateFields}
            customSelectClasses={selectClasses}
          />
        </div>
      )}
      {show.includes('date') && (
        <div
          className={`${wrapperClasses} [&:not(:first-child)]:pl-1 [&:not(:last-child)]:pr-1`}
        >
          {showLabels && <div className='text-sm font-semibold'>Date</div>}
          <FilterByDate
            dateRangeSelected={context.range}
            onSelectDateRange={setRange}
            classes={selectClasses}
          />
        </div>
      )}
    </div>
  );
};

export default FilterView;
