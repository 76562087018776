/* eslint-disable jsx-a11y/label-has-associated-control */
import { Fragment, h } from 'preact';
import { useEffect, useContext } from 'preact/hooks';
import { Select, Button } from 'src/components';
import {
  useUpdateBuilderReport,
  QUERY_KEYS as BUILDER_QUERY_KEYS
} from 'src/queries/builder';
import BuilderContainer from 'src/pages/PerformanceBuilder/containers/BuilderContainer';
import builderUtils from 'src/pages/PerformanceBuilder/builderUtils';
import commonUtils from 'common/commonUtils';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import { useQueryClient } from 'react-query';

const ReviewTemplate = () => {
  const {
    data: {
      editToken,
      form,
      dataset,
      companyMode,
      reviewMode,
      prevDataRef,
      mode,
      reportId
    },
    setDataAttribute
  } = useContext(PerformanceBuilderContext);
  const isCompanySpecific = companyMode === 'company';
  const isTestMode = mode === 'test';

  const { watch, setValue, getValues } = form;
  const prevData = prevDataRef.current;
  const formValues = watch();
  const { industry, role } = formValues;
  const {
    update: updateBuilderReport,
    isLoading: isUpdateBuilderReportLoading
  } = useUpdateBuilderReport(editToken);

  let industryOptions = [];
  if (isCompanySpecific) {
    const [industryData] = dataset;
    industryOptions = [
      { id: industryData.industryId, label: industryData.name }
    ];
  } else {
    industryOptions = dataset
      .filter((industryData) => industryData.industryId !== industry.id)
      .map((industryData) => ({
        id: industryData.industryId,
        label: industryData.name
      }));
  }

  let roleOptions = [];
  if (industry.id) {
    const selectedIndustry = dataset.find(
      (industryData) => industryData.industryId === industry.id
    );
    roleOptions = selectedIndustry.roles
      .filter((roleData) => roleData.roleId !== role.id)
      .map((roleData) => ({
        id: roleData.roleId,
        label: roleData.name
      }));
    if (isCompanySpecific) {
      const { accounts } = selectedIndustry;
      const { revieweeId: oldRevieweeId } = prevData;
      const savedReviewee = accounts.find(
        (account) => account._id === oldRevieweeId
      );
      const { roles: validRoles } = savedReviewee;
      roleOptions = roleOptions.filter((roleData) => validRoles.includes(roleData.id));
    }
  }

  const onChangeIndustry = (option) => {
    const isSelectingADifferentOption = industry.id && industry.id !== option.id;
    if (isSelectingADifferentOption) {
      const answer = confirm(
        'Changing and saving the selected industry will erase your progress. Are you sure you want to proceed?'
      );
      if (!answer) return;
    }

    setValue('industry', option);
    setValue('role', builderUtils.getEmptyOption());
    const populatedReviews = builderUtils.getPopulatedReviews(dataset, {
      industryId: industry.id,
      roleId: null,
      reviewMode,
      companyMode
    });

    setValue('reviews', populatedReviews);
    setValue('summary', '');
    setValue('summaryTemplate', null);
  };

  const onChangeRole = (option) => {
    const isSelectingADifferentOption = role.id && role.id !== option.id;
    if (isSelectingADifferentOption) {
      const answer = confirm(
        'Changing and saving the selected role will erase your progress. Are you sure you want to proceed?'
      );
      if (!answer) return;
    }

    setValue('role', option);
    const populatedReviews = builderUtils.getPopulatedReviews(dataset, {
      industryId: industry.id,
      roleId: option.id,
      reviewMode,
      companyMode
    });

    setValue('reviews', populatedReviews);
    setValue('summary', '');
    setValue('summaryTemplate', null);
  };

  const queryClient = useQueryClient();
  const update = async () => {
    const data = {
      industryId: industry.id,
      roleId: role.id,
      reviews: [],
      summary: ''
    };

    await updateBuilderReport({ data });
    queryClient.invalidateQueries([BUILDER_QUERY_KEYS.BUILDER, reportId]);
    queryClient.invalidateQueries([BUILDER_QUERY_KEYS.BUILDER, editToken]);

    const populatedReviews = builderUtils.getPopulatedReviews(dataset, {
      ...data,
      reviewMode,
      companyMode
    });

    setValue('reviews', populatedReviews);
    setValue('summary', '');
    setValue('summaryTemplate', null);

    prevDataRef.current = JSON.parse(JSON.stringify(getValues()));
    setDataAttribute('setOpenState', {});

    let scrollToSection;
    const refreshIntervalId = setInterval(() => {
      scrollToSection = document.getElementById('performance-data-section');
      if (!scrollToSection) {
        return;
      }
      scrollToSection.scrollIntoView({ behavior: 'smooth' });
      clearInterval(refreshIntervalId);
    }, 300);
  };

  const { industry: oldIndustry, role: oldRole } = prevData;
  const getIsButtonDisabled = () => {
    if (isUpdateBuilderReportLoading) return true;

    const newData = {
      roleId: role.id,
      industryId: industry.id
    };

    const areFieldsEmpty = commonUtils.isAnyFalsy(newData);
    if (areFieldsEmpty) return true;

    const oldData = {
      roleId: oldRole.id,
      industryId: oldIndustry.id
    };

    const canSubmitForm = !commonUtils.isSame(oldData, newData);

    return !canSubmitForm;
  };
  const hasSavedIndustryAndRole = oldIndustry.id && oldRole.id;

  const getTestData = () => {
    if (!isTestMode) return { testIndustryOption: null, testRoleOption: null };
    const [testIndustry] = dataset;
    const [testRole] = testIndustry.roles;

    const testIndustryOption = {
      id: testIndustry.industryId,
      label: testIndustry.name
    };
    const testRoleOption = {
      id: testRole.roleId,
      label: testRole.name
    };
    return { testIndustryOption, testRoleOption };
  };

  const autofillRole = () => {
    const { testRoleOption } = getTestData();
    onChangeRole(testRoleOption);
  };

  const autofillTemplate = () => {
    const { testIndustryOption } = getTestData();
    onChangeIndustry(testIndustryOption);
    autofillRole();
  };

  useEffect(() => {
    if (isTestMode) {
      if (!industry.id) {
        autofillTemplate();
      }
      // else {
      //   autofillRole();
      // }
    }
  }, [industry]);

  return (
    <BuilderContainer loading={isUpdateBuilderReportLoading}>
      <Fragment>
        <div
          id='review-template-section'
          className='mb-6 border-b border-[#E2E8F0]'
        />
        <h4 className='text-xl'>Choose Review Role</h4>
        <p className='text-gray-500 mb-5'>
          Select the industry and role for which you’d like to evaluate.
        </p>
        <div className='flex flex-col w-1/2'>
          <label className='mb-3 flex flex-col gap-2'>
            <span className='text-base font-bold'>
              {isCompanySpecific ? 'Company' : 'Industry'}
            </span>
            <Select
              disabled={isCompanySpecific}
              customVariantClasses='bg-white rounded-lg border border-slate-300 h-10'
              scrollStyle='purple'
              options={industryOptions}
              title={industry.label || 'Select an Industry'}
              onChange={onChangeIndustry}
              drop={hasSavedIndustryAndRole ? 'down' : 'up'}
            />
          </label>
          <label className='mb-3 flex flex-col gap-2'>
            <span className='text-base font-bold'>Role</span>
            <Select
              disabled={!industry.id}
              scrollStyle='purple'
              customVariantClasses='bg-white rounded-lg border border-slate-300 h-10'
              options={roleOptions}
              title={role.label || 'Select a Role'}
              onChange={(option) => onChangeRole(option)}
              drop={hasSavedIndustryAndRole ? 'down' : 'up'}
            />
          </label>
        </div>
        <Button
          type='button'
          disabled={getIsButtonDisabled()}
          variant='custom'
          classes='w-[7rem] bg-[#0F172A] text-white text-base px-4 py-2 rounded-md answer transition-colors duration-300'
          onClick={update}
        >
          Save
        </Button>
      </Fragment>
    </BuilderContainer>
  );
};

export default ReviewTemplate;
