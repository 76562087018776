import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import {
  getReportAiNoteQuery,
  useReport,
  useReportNotes
} from 'src/queries/reports';
import { useCreateNote } from 'src/hooks/UserReports/useNote';
import { useCompany } from 'src/queries/company';
import {
  NotesNewReport,
  AddNoteNewReport
} from 'src/pagesDashboard/NewUserReport/components';
import COMMON_CONSTANTS from 'common/commonConstants';

const { REPORT_NOTE_TYPES, REPORT_TEMPLATE_TYPES, REPORT_PROMISE_TYPES } = COMMON_CONSTANTS;

const PerformanceSummaryNotes = ({
  reportId,
  isEditorOpenByDefault = true,
  setIsLoading = () => {},
  viewOnly = false
}) => {
  const [includedEditNoteSummary, setIncludedEditNoteSummary] = useState('');
  const [includedNewNoteSummary, setIncludedNewNoteSummary] = useState('');
  const [notesOpen, setNotesOpen] = useState(null);

  const {
    data: report,
    isFetching: isFetchingReport,
    isError: isErrorReport
  } = useReport(reportId);

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const { data: summaryNotes } = useReportNotes(reportId, {
    type: REPORT_NOTE_TYPES.SUMMARY
  });

  const { data: previousSummaryNotes } = useReportNotes(report.previousReport, {
    type: REPORT_NOTE_TYPES.SUMMARY
  });

  const { saveNote } = useCreateNote();

  if (isFetchingCompany || isErrorCompany) return <div className='h-full' />;

  const summaryNotesTemplates = company.templates
    .filter((t) => t.type === REPORT_TEMPLATE_TYPES.SUMMARY_TEMPLATE)
    .map((t) => ({
      id: t.id,
      label: t.name,
      content: t.content
    }));
  summaryNotesTemplates.unshift({
    id: 'clear',
    label: 'No template',
    content: ''
  });

  const clearIncludedText = (input) => {
    if (input === 'edit') {
      setIncludedEditNoteSummary('');
      clearIncludedText('new');
    }
    if (input === 'new') setIncludedNewNoteSummary('');
    setNotesOpen(null);
  };

  const isFetching = isFetchingReport || isFetchingCompany;
  const isError = isErrorReport || isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  if (!isReady) {
    return <div className='h-full' />;
  }

  const { mutateAsync: getReportAiNote, isLoading: isLoadingGetReportAiNote } = getReportAiNoteQuery();
  const generateAiNote = async () => {
    const params = {
      reportId,
      userId: report.user,
      type: REPORT_PROMISE_TYPES.SUMMARY_SINGLE
    };
    return getReportAiNote(params);
  };

  return (
    <div>
      <NotesNewReport
        reportId={report.id}
        title='Notes'
        notes={summaryNotes ?? []}
        previousNotes={previousSummaryNotes ?? []}
        includeText={includedEditNoteSummary}
        clearIncludeText={() => clearIncludedText('edit')}
        onFieldOpen={() => setNotesOpen('edit')}
      />

      {!viewOnly && summaryNotes && notesOpen !== 'edit' ? (
        <AddNoteNewReport
          title='Feedback note'
          reportId={report.id}
          noteType={REPORT_NOTE_TYPES.SUMMARY}
          includeText={includedNewNoteSummary}
          templatesOptions={summaryNotesTemplates}
          isOpenByDefault={isEditorOpenByDefault ?? !summaryNotes.length}
          onSave={saveNote}
          clearIncludeText={() => clearIncludedText('new')}
          onFieldOpen={() => setNotesOpen('new')}
          parentValues={{ noteCount: summaryNotes.length }}
          generateNoteCallback={generateAiNote}
          isGenerateNoteLoading={isLoadingGetReportAiNote}
        />
      ) : null}
    </div>
  );
};

export default PerformanceSummaryNotes;
