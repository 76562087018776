export const COLORS = {
  INDIGO: '#667EEA',
  PINK: '#ED64A6',
  GRAY: '#122549'
};

export const STYLE = {};

// BASE STYLES
STYLE.CONTENT = 'w-full flex flex-col w-full mini:max-w-80vw wide:max-w-1600 mx-auto min-h-screen';
STYLE.BASE = 'p-4 rounded-md rounded bg-cloud-blue flex flex-col z-0 h-full w-full';
STYLE.BASE_CONTAINER = 'rounded-md flex flex-col';
STYLE.BASE_HEADER = 'w-19.5/20 mx-auto text-white bg-purple py-4 p-5 rounded-md relative z-1 mb-2';
STYLE.BASE_SIDEBAR = 'h-full flex flex-col items-center';
STYLE.BASE_SIDEBAR_PARENT = 'w-full font-medium text-white flex justify-between items-center focus:outline-none px-4';
STYLE.BASE_SIDEBAR_CHILD = 'w-full text-left font-medium flex justify-between items-center focus:outline-none hover:text-white transition duration-200 whitespace-no-wrap pr-4 pl-10';

// SIDEBAR STYLES
STYLE.SIDEBAR_CONTAINER = `${STYLE.BASE_SIDEBAR} min-w-15vw w-15vw max-w-15vw`;
STYLE.SIDEBAR = `${STYLE.SIDEBAR_CONTAINER} fixed`;
STYLE.MOBILE_SIDEBAR = `${STYLE.BASE_SIDEBAR} w-full absolute z-50`;
STYLE.SIDEBAR_PARENT = `${STYLE.BASE_SIDEBAR_PARENT} pt-2 pb-2`;
STYLE.MOBILE_SIDEBAR_PARENT = `${STYLE.BASE_SIDEBAR_PARENT} pt-6 pb-4`;
STYLE.SIDEBAR_CHILD = `${STYLE.BASE_SIDEBAR_CHILD} py-1.5`;
STYLE.MOBILE_SIDEBAR_CHILD = `${STYLE.BASE_SIDEBAR_CHILD} py-3`;

// CONTAINER STYLES
STYLE.CONTAINER = `${STYLE.BASE_CONTAINER} mb-2 mt-1 bg-transparent`;
STYLE.CONTAINER_BLUE = `${STYLE.BASE_CONTAINER} mb-2 mt-1 bg-cloud-blue`;
STYLE.CONTAINER_WHITE = `${STYLE.BASE_CONTAINER} mb-2 p-2 pl-4 mt-1 rounded shadow bg-white`;
STYLE.CONTAINER_WHITE_PADDINGLESS = `${STYLE.BASE_CONTAINER} mb-2 mt-1 rounded shadow bg-white`;
STYLE.CONTAINER_WHITE_MARGINLESS = `${STYLE.BASE_CONTAINER} p-2 pl-4 rounded shadow bg-white`;
STYLE.CONTAINER_WHITE_PADDINGLESS_MARGINLESS = `${STYLE.BASE_CONTAINER} rounded shadow bg-white`;
STYLE.CONTAINER_WHITE_PADDINGLESS_MARGINLESS_SHADOWLESS = `${STYLE.BASE_CONTAINER} rounded bg-white`;
STYLE.CONTAINER_GRAY = `${STYLE.BASE_CONTAINER} mb-2 p-2 pl-4 mt-1 rounded shadow bg-gray`;
STYLE.CONTAINER_GRAY_PADDINGLESS = `${STYLE.BASE_CONTAINER} mb-2 mt-1 rounded shadow bg-gray`;
STYLE.CONTAINER_LIGHT_GRAY = `${STYLE.BASE_CONTAINER} mb-2 p-2 pl-4 mt-1 rounded shadow bg-background-gray`;
STYLE.CONTAINER_LIGHT_GRAY_PADDINGLESS = `${STYLE.BASE_CONTAINER} rounded shadow bg-background-gray`;
STYLE.CONTAINER_LIGHT_GRAY_PADDINGLESS_MARGINLESS = `${STYLE.BASE_CONTAINER} rounded shadow bg-background-gray`;

// MISC STYLES
STYLE.NOTIFICATION = 'notification w-19.5/20 mx-auto border-r-16 border-l-16 border-white h-10 bg-red text-white font-bold text-md text-center rounded-md z-1';
STYLE.NOTIFICATION_BUTTON = 'w-full h-full rounded-md transition duration-300 hover:bg-hover-red';
STYLE.HEADER = `${STYLE.BASE_HEADER} -mt-20`;
STYLE.SANDBOX_PAGE = 'm-10 bg-cloud-blue rounded p-5 text-left h-50 w-full xl:w-70rem 2xl:w-75rem mx-auto';

export default STYLE;
