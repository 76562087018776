import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import api from 'src/services/api';
import builderUtils from 'src/pages/PerformanceBuilder/builderUtils';
import { get, isEmpty } from 'lodash';
import qs from 'qs';

export const QUERY_KEYS = {
  BUILDER_DATASET: 'builderDataset',
  BUILDER: 'builder',
  BUILDER_DASHBOARD: 'builderReports'
};

export const useBuilderDataset = () => {
  const { data, ...rest } = useQuery([QUERY_KEYS.BUILDER_DATASET], () => api.get('/builder/dataset').then((resp) => {
    if (!resp.success) {
      console.error(`GET /builder/dataset error`);
      return {
        success: false
      };
    }
    return resp;
  }));

  return {
    data: get(data, 'data', {}),
    meta: get(data, 'meta', {}),
    success: get(data, 'success'),
    ...rest
  };
};

export const useBuilderReport = (id, options = {}, queryOptions = {}) => {
  const stringified = qs.stringify({ options }, { skipNulls: true });

  const { data, ...rest } = useQuery(
    [QUERY_KEYS.BUILDER, id],
    () => api.get(`/builder/${id}?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /builder/${id}?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    {
      enabled: Boolean(id),
      ...queryOptions
    }
  );

  return {
    data: get(data, 'data', {}),
    meta: get(data, 'meta', {}),
    success: get(data, 'success'),
    ...rest
  };
};

export const useBuilderReports = (
  {
    ids, companyid, companyMode, reviewMode, roleIds, createdDate
  } = {},
  {
    page, sort, include, search, projection, viewerId = null
  } = {},
  queryOptions = {}
) => {
  const filters = {
    ids,
    companyid,
    companyMode,
    reviewMode,
    roleIds,
    createdDate
  };
  const options = {
    page,
    sort,
    include,
    search,
    projection,
    viewerId
  };
  const stringified = qs.stringify(
    { filters, options },
    { skipNulls: true, arrayFormat: 'brackets' }
  );

  const { data, ...rest } = useQuery(
    [QUERY_KEYS.BUILDER_DASHBOARD, ...(ids || []), filters, options],
    () => api.get(`/builder?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /builder?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    queryOptions
  );

  return {
    data: get(data, 'data', []),
    meta: get(data, 'meta', {
      page: {
        number: null,
        size: null,
        totalItems: null,
        totalPages: null
      }
    }),
    success: get(data, 'success'),
    ...rest
  };
};

export const useCreateBuilderReport = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: mutate, ...rest } = useMutation(
    (data) => api.post('/builder', data).then((resp) => {
      if (!resp.success) {
        console.error('POST /builder error');
        return {
          success: false
        };
      }
      return resp;
    }),
    {
      throwOnError: true,
      mutationKey: [QUERY_KEYS.BUILDER],
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.BUILDER);
        queryClient.invalidateQueries(QUERY_KEYS.BUILDER_DASHBOARD);
      }
    }
  );

  return {
    create: mutate,
    ...rest
  };
};

export const useUpdateBuilderReport = (editToken) => {
  const { mutateAsync: mutate, ...rest } = useMutation(
    (data) => api.patch(`/builder/${editToken}`, data).then((resp) => {
      if (!resp.success) {
        return {
          success: false
        };
      }
      return resp;
    }),
    {
      throwOnError: true,
      mutationKey: [QUERY_KEYS.BUILDER, editToken]
    }
  );

  return {
    update: mutate,
    ...rest
  };
};

export const sendShareLinkEmail = () => useMutation((data) => api.post('/builder/shareLink', data));

export const sendLinksToReviewer = () => useMutation((data) => api.post('/builder/reviewerLinks', data));

export const useSendFeedbackEmail = () => useMutation((data) => api.post('/admin/builderFeedback', data));

export const useCreatePdf = () => useMutation((data) => api.post('/builder/pdf', data));

export const useBuilderForm = (reportData, companyData, builderDataset) => {
  const doesReportExist = !isEmpty(reportData);
  const doesCompanyExist = !isEmpty(companyData);
  let defaultValues = {};

  let reviewMode = null;
  if (doesReportExist) {
    reviewMode = reportData.reviewMode;
  } else if (doesCompanyExist) {
    reviewMode = 'categories';
  } else {
    reviewMode = 'questions';
  }

  let companyMode = null;
  if (doesReportExist) {
    companyMode = reportData.companyMode;
  } else if (doesCompanyExist) {
    companyMode = 'company';
  } else {
    companyMode = 'public';
  }

  const dataset = builderUtils.getDatasetByCompanyMode(companyMode, {
    builderDataset,
    ...(doesCompanyExist && { companyDataset: companyData.dataset })
  });
  if (doesReportExist) {
    const {
      reviewer, reviewee, companyName, title, reviewDate
    } = reportData;
    defaultValues = {
      reviewerId: reviewer.reviewerId || null,
      reviewerFirstName: reviewer.firstName || '',
      reviewerLastName: reviewer.lastName || '',
      reviewerEmail: reviewer.email || '',
      revieweeId: reviewee.revieweeId || null,
      revieweeFirstName: reviewee.firstName || '',
      revieweeLastName: reviewee.lastName || '',
      revieweeTitle: reviewee.title || '',
      companyName: companyName || '',
      title: title || '',
      reviewDate: new Date(reviewDate)
    };

    const { industryId, roleId } = reportData;
    if (industryId) {
      const industryData = dataset.find(
        (industry) => industry.industryId === industryId
      );
      defaultValues.industry = {
        id: industryData.industryId,
        label: industryData.name
      };

      if (roleId) {
        const { roles } = industryData;
        const roleData = roles.find((role) => role.roleId === roleId);
        defaultValues.role = {
          id: roleData.roleId,
          label: roleData.name
        };
      } else {
        defaultValues.role = builderUtils.getEmptyOption();
      }
    } else {
      defaultValues.industry = builderUtils.getEmptyOption();
      defaultValues.role = builderUtils.getEmptyOption();
    }

    const { reviews, summary } = reportData;
    const populatedReviews = builderUtils.getPopulatedReviews(dataset, {
      industryId,
      roleId,
      reviews,
      summary,
      reviewMode
    });
    defaultValues.reviews = populatedReviews;
    defaultValues.summary = summary || '';
    defaultValues.summaryTemplate = null;
  } else {
    defaultValues = {
      reviewerId: null,
      reviewerFirstName: '',
      reviewerLastName: '',
      reviewerEmail: '',
      revieweeId: null,
      revieweeFirstName: '',
      revieweeLastName: '',
      revieweeTitle: '',
      companyName: doesCompanyExist ? companyData.name : '',
      title: 'Performance Review',
      reviewDate: new Date(),
      industry: builderUtils.getEmptyOption(),
      role: builderUtils.getEmptyOption(),
      reviews: [],
      summary: '',
      summaryTemplate: null
    };
  }

  defaultValues.companyMode = companyMode;
  defaultValues.reviewMode = reviewMode;

  return {
    form: useForm({ defaultValues }),
    defaultValues
  };
};
