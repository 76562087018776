import { h } from 'preact';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import { useCompany } from 'src/queries/company';
import STYLE from 'src/constants/style';
import { Base } from 'src/components/index';
import COMMON_CONSTANTS from 'common/commonConstants';
import SearchSVG from '../../../assets/search.svg';
import ArrowRight from '../../../assets/arrowRight.svg';
import PlusCircle from '../../../assets/plusInCircle.svg';
import EditSvg from '../../../assets/edit-3.svg';
import SHARED_CONSTANTS from '../../../common/sharedConstants';

const { DEFAULT_QUESTION } = COMMON_CONSTANTS;

const { EDIT_MODES } = SHARED_CONSTANTS;
const { STATUS } = COMMON_QUESTION_CONSTANTS;

const QuestionColumn = ({
  roleKey,
  categoryKey,
  selectedCategory,
  questions = [],
  selectedQuestion,
  edit,
  options
}) => {
  const { showArchived } = options;
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && questions && !isFetching && !isError;

  if (!isReady) {
    return false;
  }

  const selectedCategoryObj = company && company.questions
    ? company.questions.CATEGORIES[selectedCategory]
    : null;
  const displayQuestions = questions.filter(
    (question) => question.id !== DEFAULT_QUESTION.id
      && (showArchived || question.status === STATUS.ACTIVE)
  );

  return (
    <Base className={STYLE.CONTAINER_GRAY_PADDINGLESS}>
      <div className='w-full h-full pt-6 px-2'>
        {/*
            <a class="inline-block relative mb-0 mr-4">
              <SearchSVG />
            </a>
          */}
        <h4 className='inline-block mr-3 ml-4'>
          {selectedCategoryObj
            ? `${selectedCategoryObj.label} Questions`
            : 'All Questions'}
        </h4>
        {selectedCategory ? (
          <a
            onClick={(e) => {
              edit(EDIT_MODES.ADD, roleKey, categoryKey, undefined);
            }}
            className='inline-block float-right mr-3 mb-0'
          >
            <PlusCircle />
          </a>
        ) : null}
        <div className='clear-both' />
        <div className='overflow-scroll h-41rem'>
          {!selectedCategory ? (
            <p className='text-center mt-10 mb-0 pb-5'>Select a category</p>
          ) : null}
          {displayQuestions
            ? displayQuestions.map((questionObj) => (
              <div
                className={`hover:bg-purple hover:text-white rounded-lg m-2 ml-0 ${
                  selectedQuestion === questionObj.id
                    ? 'bg-purple text-white'
                    : 'bg-white'
                }`}
              >
                <a
                  onClick={(e) => {
                    edit(
                      EDIT_MODES.EDIT,
                      roleKey,
                      categoryKey,
                      questionObj.id
                    );
                  }}
                  className='inline-block w-1/12 mb-0 pt-2 pb-2 pl-3 hover:visible'
                >
                  <EditSvg class='w-4 hover:stroke-white' />
                </a>
                <a
                  onClick={(e) => {
                    edit(
                      EDIT_MODES.EDIT,
                      roleKey,
                      categoryKey,
                      questionObj.id
                    );
                  }}
                  className={`inline-block w-9/12 mb-0 p-4 pt-2 pb-2 font-bold linkWhite hover:no-underline
                         ${selectedQuestion === questionObj.id ? 'white' : ''}
                        `}
                >
                  {questionObj.question}
                  {' '}
                  {questionObj.status === STATUS.ARCHIVED ? '(Archived)' : ''}
                </a>
              </div>
            ))
            : null}
        </div>
      </div>
    </Base>
  );
};

export default QuestionColumn;
