import { h, Fragment } from 'preact';
import { Controller } from 'react-hook-form';
import { Select } from 'src/components';
import { GOAL_TYPES } from 'src/containers/UserProfile/Goals/constants';

const Progress = ({ control, disabled }) => (
  <Fragment>
    <div className='flex justify-between'>
      <p className='mb-0 font-bold text-base text-gray-500'>GOAL MEASUREMENT</p>
      <div className='pl-8 pr-6 h-16 w-4/6'>
        <Controller
          name='progressType'
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            const title = field.value ? field.value.label : '';
            return (
              <Select
                placeholder='abcd'
                classes='w-full'
                options={GOAL_TYPES}
                title={title}
                disabled={disabled}
                {...field}
              />
            );
          }}
        />
      </div>
    </div>
  </Fragment>
);

export default Progress;
