import { h } from 'preact';
import { route } from 'preact-router';
import { Button, toast } from 'src/components';
import OnboardingBase from 'src/pagesDashboard/Onboarding/OnboardingBase';
import appUtils from 'src/components/appUtils';
import CircledCheckmarkSVG from 'src/assets/svg/circled-checkmark.svg';
import { useContext, useEffect } from 'preact/hooks';
import { AppContext } from 'src/app/context/AppProvider';
import { connectSlackQuery } from 'src/queries/user';
import COMMON_CONSTANTS from 'common/commonConstants';

const { SLACK_REDIRECT_URI } = COMMON_CONSTANTS;

const OnboardingComplete = () => {
  const loggedUser = appUtils.getLoggedUser();

  if (!loggedUser) {
    toast.show('Please login to continue');
    return route(appUtils.getBaseUrl());
  }

  const { globalContext } = useContext(AppContext);

  const { mutateAsync: connectSlack, isLoading: isConnectingSlack } = connectSlackQuery();

  const handleSlackAuth = async ({ code, state }) => {
    toast.show('Connecting to Slack, please wait');
    const redirectUrl = SLACK_REDIRECT_URI.ONBOARDING_COMPLETE;

    const result = await connectSlack({ code, state, redirectUrl });
    if (!result || !result.success) {
      toast.error(result.message);
      return;
    }
    toast.show('Slack connected');
  };

  useEffect(() => {
    // this runs when slack redirects back to workstory
    if (globalContext.matches?.code && globalContext.matches?.state) {
      const { code, state } = globalContext.matches;
      handleSlackAuth({ code, state });
    }
  }, [globalContext]);

  return (
    <OnboardingBase>
      <div className='w-full text-left'>
        <div className='rounded-full bg-[#D1FADF] p-3 my-3 w-fit'>
          <CircledCheckmarkSVG className='w-5 h-5 text-secondary-green' />
        </div>
        <p className='text-xl font-bold m-0 text-black'>Setup Complete!</p>
        <p className='m-0'>You're ready to get started with WorkStory.</p>
      </div>

      <div className='text-left m-0'>
        <p className='font-bold m-0'>What's next?</p>
        <ul>
          <li>
            • Follow the onboarding steps to adjust your preferences and learn
            more about how the platform works.
          </li>
          <li>
            • Wait for requests and notifications to start coming your way.
          </li>
        </ul>
      </div>

      <Button
        variant='purple'
        onClick={() => route(appUtils.getHomeRoute())}
        disabled={isConnectingSlack}
        classes='!rounded-md !w-3/5 !h-10 !mx-auto'
      >
        Enter WorkStory
      </Button>
    </OnboardingBase>
  );
};

export default OnboardingComplete;
