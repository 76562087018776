import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { route } from 'preact-router';
import { useForm } from 'react-hook-form';
import { useReport } from 'src/queries/reports';
import { useNewUserReportContext } from 'src/pagesDashboard/NewUserReport/context/NewUserReportProvider';
import { ALL_STEPS, getRoutes } from 'src/pagesDashboard/NewUserReport/utils';
import { FeedbackListSection } from 'src/pagesDashboard/NewUserReport/containers/NewUserReportFeedback';
import COMMON_CONSTANTS from 'common/commonConstants';

const { REPORT_NOTE_TYPES } = COMMON_CONSTANTS;

const NewUserReportFeedback = (props) => {
  const { userId, reportId } = props;

  const { updateContext } = useNewUserReportContext();
  const {
    data: report,
    isFetching: isFetchingReport,
    isError: isErrorReport
  } = useReport(reportId, {
    type: REPORT_NOTE_TYPES.FEEDBACK
  });

  if (isFetchingReport) return <div className='h-full' />;

  const formRef = useRef();

  useEffect(() => {
    updateContext({ formRef, activeStep: ALL_STEPS.FEEDBACK });
  }, []);

  useEffect(() => {
    updateContext({
      isLoading: isFetchingReport
    });
  }, [isFetchingReport]);

  const isFetching = isFetchingReport;
  const isError = isErrorReport;
  const isReady = !isFetching && !isError && report;

  if (!isReady) return <div className='h-full' />;

  const { handleSubmit } = useForm({
    defaultValues: {}
  });

  const goNext = (values) => {
    const routeToGo = getRoutes(userId, reportId, ALL_STEPS.CATEGORIES);
    return route(routeToGo);
  };

  return (
    <form
      id='new-user-report-feedback-form'
      ref={formRef}
      className='flex flex-col h-full w-full justify-between md:mb-32'
      onSubmit={handleSubmit(goNext)}
    >
      <FeedbackListSection
        userId={userId}
        reportId={reportId}
        previousReport={report.previousReport}
        isEditorOpenByDefault={false}
      />
    </form>
  );
};

export default NewUserReportFeedback;
