import { h, Fragment } from 'preact';
import { isEmpty } from 'lodash';
import CircledCheckmarkSVG from 'src/assets/svg/circled-checkmark.svg';
import PlainCheckmarkSVG from 'src/assets/svg/plain-checkmark.svg';
import { BADGE_COLOR_CLASSES } from 'src/componentsTailwind/tailwindConstants';
import Button from 'src/components/Button/Button';

const renderButton = (tier) => {
  const { button } = tier;

  if (tier.current) {
    return (
      <Button
        variant='purple-with-border'
        onClick={button.onClick}
        disabled={button.disabled}
        classes='flex items-center justify-center gap-2 !text-purple !bg-white !rounded-md !w-full !h-full'
      >
        <CircledCheckmarkSVG />
        {button.text}
      </Button>
    );
  }

  if (tier.highlight) {
    return (
      <Button
        variant='purple'
        onClick={button.onClick}
        disabled={button.disabled}
        classes='!rounded-md !w-full !h-full'
      >
        {button.text}
      </Button>
    );
  }

  return (
    <Button
      variant='purple-with-border'
      onClick={button.onClick}
      disabled={button.disabled}
      classes='flex items-center justify-center gap-2 !text-purple !bg-white !rounded-md !w-full !h-full'
    >
      {button.text}
    </Button>
  );
};

const Tiers = ({ tiers = [] }) => (
  <div className='flex justify-evenly gap-4'>
    {tiers.map((tier) => {
      const {
        highlight,
        title,
        badge,
        description,
        price,
        features = []
      } = tier;

      return (
        <div
          className={`rounded-md p-4 full:p-8 ${highlight ? 'ring-2 ring-black' : 'ring-1 ring-tertiary-gray'} text-black h-[100%] shadow-md`}
        >
          <div className='flex items-center gap-4 h-[10%]'>
            <p className='text-black text-xl font-semibold leading-8 m-0'>
              {title}
            </p>
            {!isEmpty(badge) ? (
              <div
                className={`rounded-md m-0 text-xs font-semibold leading-5 px-2 py-1 ${badge.colorClasses || BADGE_COLOR_CLASSES.GREEN}`}
              >
                {badge.text}
              </div>
            ) : null}
          </div>
          <div className='flex items-center gap-3 h-[10%]'>
            <p className='text-4xl font-bold tracking-tight align-baseline text-black m-0'>
              {price.value}
            </p>
            <div>
              <p className='text-sm leading-4 text-secondary-gray m-0'>
                {price.label}
              </p>
              {price.observation ? (
                <p className='text-sm leading-4 text-secondary-gray m-0'>
                  {price.observation}
                </p>
              ) : null}
            </div>
          </div>
          <div className='flex items-center h-[15%]'>
            <p className='m-0 text-md leading-4 text-secondary-gray'>
              {description}
            </p>
          </div>
          <div className='border-b border-tertiary-gray' />
          <div className='flex flex-col gap-[10px] text-sm leading-6 text-secondary-gray pt-4 pb-2 h-[55%]'>
            {features.map((feature) => (
              <span className='flex items-center gap-3'>
                <PlainCheckmarkSVG className='h-4 w-4' />
                {feature}
              </span>
            ))}
          </div>
          <div className='p-1 h-[10%]'>{renderButton(tier)}</div>
        </div>
      );
    })}
  </div>
);

export default Tiers;
