import { h } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import { Select, DateRange } from 'src/components/';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonDateUtils from 'common/commonDateUtils';

if (!window.speedChartList) {
  window.speedChartList = [];
}

const FilterByDate = ({
  id,
  dateRangeSelected,
  onSelectDateRange,
  classes,
  customSelectClasses
}) => {
  const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

  const dateRangeRef = useRef();

  const [showDateRange, setShowDateRange] = useState(false);

  const options = Object.values(DATE_RANGE_FILTERS);

  const selectedOption = options.find((o) => o.key === dateRangeSelected.value);

  const title = selectedOption && selectedOption.key !== 'custom'
    ? `Filter by ${selectedOption.label}`
    : `${
      dateRangeSelected.start
          && commonDateUtils.dateToMonthDayYearFormat(
            new Date(dateRangeSelected.start * 1000)
          )
    } -
      ${
  dateRangeSelected.end
        && commonDateUtils.dateToMonthDayYearFormat(
          new Date(dateRangeSelected.end * 1000)
        )
}`;

  const today = new Date();
  const parsedToday = Date.parse(today);

  useEffect(() => {
    if (showDateRange && dateRangeRef.current) {
      dateRangeRef.current.click();
    }
  }, [showDateRange, dateRangeRef.current]);

  const startAndEndDates = {
    [DATE_RANGE_FILTERS.LAST_MONTH.key]: {
      start: commonDateUtils.getDateFromDaysAgo(
        DATE_RANGE_FILTERS.LAST_MONTH.days
      ).unix,
      end: commonDateUtils.getUnixDateNow()
    },
    [DATE_RANGE_FILTERS.THREE_MONTHS.key]: {
      start: commonDateUtils.getDateFromDaysAgo(
        DATE_RANGE_FILTERS.THREE_MONTHS.days
      ).unix,
      end: commonDateUtils.getUnixDateNow()
    },
    [DATE_RANGE_FILTERS.SIX_MONTHS.key]: {
      start: commonDateUtils.getDateFromDaysAgo(
        DATE_RANGE_FILTERS.SIX_MONTHS.days
      ).unix,
      end: commonDateUtils.getUnixDateNow()
    },
    [DATE_RANGE_FILTERS.ONE_YEAR.key]: {
      start: commonDateUtils.getDateFromDaysAgo(
        DATE_RANGE_FILTERS.ONE_YEAR.days
      ).unix,
      end: commonDateUtils.getUnixDateNow()
    },
    [DATE_RANGE_FILTERS.ALL_TIME.key]: {}
  };

  return (
    <div className={`inline-block ${classes || 'w-64'}`}>
      <Select
        id={id ?? 'filterByDate-select'}
        variant='shadow'
        classes={customSelectClasses}
        title={title}
        options={options}
        onChange={(option) => {
          if (option.key === 'custom') {
            setShowDateRange(true);
            return;
          }
          const { start, end } = startAndEndDates[option.key];
          onSelectDateRange(
            option.key,
            start ? parseInt(start, 10) : start,
            end ? parseInt(end, 10) : end
          );
        }}
      />

      {showDateRange ? (
        <div
          className='relative -ml-[calc(276px-100%)]'
          id='filterByDate-daterange-container'
        >
          <DateRange
            ref={dateRangeRef}
            parentElId='filterByDate-daterange-container'
            calendarPosition='bottom'
            datePickerClasses='litepicker-top-8'
            containerClasses='m-0'
            inputClasses='hidden'
            iconClasses='hidden'
            innerClasses='float-right'
            maxDate={parsedToday}
            startDate={null}
            endDate={null}
            onSelect={(startArg, endArg) => {
              const startUnix = startArg
                ? commonDateUtils.dateToUnix(startArg)
                : null;
              const endUnix = endArg
                ? commonDateUtils.dateToUnix(endArg)
                : null;
              onSelectDateRange(
                'custom',
                parseInt(startUnix, 10),
                parseInt(endUnix, 10)
              );
              setShowDateRange(false);
            }}
            onHide={() => setShowDateRange(false)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default FilterByDate;
