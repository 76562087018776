import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import { isNil, omit, isEmpty } from 'lodash';
import {
  useAccount,
  QUERY_KEYS as ACCOUNT_QUERY_KEYS
} from 'src/queries/account';
import { useQueryClient } from 'react-query';
import {
  useReport,
  updateReportQuery,
  QUERY_KEYS as REPORTS_QUERY_KEYS
} from 'src/queries/reports';
import { useTree } from 'src/queries/tree';
import { Stepper, toast } from 'src/components';
import { useNewUserReportContext } from 'src/pagesDashboard/NewUserReport/context/NewUserReportProvider';
import ReportFooter from 'src/pagesDashboard/NewUserReport/components/ReportFooter';
import {
  ALL_STEPS,
  getRoutes,
  PAGE_TITLES,
  getPageSubtitles,
  STEPPER_STEPS,
  getAllRoutes
} from 'src/pagesDashboard/NewUserReport/utils';
import { appUtils } from 'src/components/index';
import commonPermissions from 'common/commonPermissions';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';

const { USER_STATE, REPORT_STATUS } = COMMON_CONSTANTS;

const NewUserReportLayout = (props) => {
  const {
    children,
    parentProps: {
      matches: { userId, rest },
      url
    }
  } = props;
  const queryClient = useQueryClient();
  const { mutateAsync: updateReport, isLoading: isUpdateReportLoading } = updateReportQuery();

  const [reportId] = rest.split('/');
  const isMe = userId === appUtils.getLoggedUser().id;

  const { context, updateContext, resetContext } = useNewUserReportContext();
  const {
    activeStep,
    aiGenerationLoading,
    formRef,
    isLoading: isLoadingContext,
    availableSteps,
    stepperActiveStep,
    refreshReports
  } = context;

  useEffect(() => {
    if (!rest) {
      route(`/dashboard/profile/${userId}/report/new`);
    }
  }, [rest]);

  const { data: viewerAccount, isFetching: isFetchingViewerAccount } = useAccount('me');
  const { data: userData, isFetching: isFetchingAccount } = useAccount(userId);
  const {
    data: { tree },
    isFetching: isFetchingTree
  } = useTree();
  const {
    data: report,
    isFetching: isFetchingReport,
    error: errorReport
  } = useReport(reportId !== 'new' ? reportId : null);

  const isFetching = isFetchingViewerAccount
    || isFetchingTree
    || isFetchingAccount
    || isFetchingReport;
  const isReady = !isFetching;
  if (!isReady) return null;

  const isLoading = isFetching || isLoadingContext || isUpdateReportLoading;

  const isUnassigned = userData.status === USER_STATE.UNASSIGNED;

  const setDefaultStepValues = (steps, routes) => {
    updateContext({
      activeStep: steps[0]
    });
    const currentStep = steps.includes(activeStep)
      ? steps.indexOf(activeStep)
      : 0;

    updateContext({
      availableSteps: steps,
      availableRoutes: routes,
      stepperActiveStep: currentStep
    });

    const routeToGo = Object.values(routes).includes(url)
      ? url
      : getRoutes(userId, reportId, steps[0]);
    route(routeToGo);
  };

  const updateAvailableSteps = () => {
    // Managers (all directly above the reviewee), report author, and admins can access, edit, share/unshare reports, and view/edit the Setup step.
    if (
      commonPermissions.isAdmin(viewerAccount)
      || commonTreeUtils.isNodeDirectlyAbove(tree, userId, viewerAccount._id)
      || commonPermissions.canManageAccounts(viewerAccount, [userId])
      || report.createdBy === viewerAccount._id
    ) {
      return setDefaultStepValues(
        STEPPER_STEPS.filter((step) => {
          if (isUnassigned) return [ALL_STEPS.PREVIEW].includes(step);
          return true;
        }),
        getAllRoutes(userId, reportId)
      );
    }

    // Reviewee, with basic or manager access, can view and interact with several steps in the report: Feedback, Categories, Goals, Summary, Preview, and Share.
    if (report.user === viewerAccount._id) {
      const newSteps = STEPPER_STEPS.filter(
        (step) => ![ALL_STEPS.SETUP].includes(step)
      );
      const newRoutes = omit(getAllRoutes(userId, reportId), [
        ALL_STEPS.SETUP,
        ALL_STEPS.COMPS
      ]);

      return setDefaultStepValues(newSteps, newRoutes);
    }

    // Users lateral or below the reviewee can add/edit their notes but cannot access Setup or Share steps.
    const newSteps = STEPPER_STEPS.filter((step) => {
      if (isUnassigned) return [ALL_STEPS.PREVIEW].includes(step);
      return ![ALL_STEPS.SETUP, ALL_STEPS.FINALIZE].includes(step);
    });
    const newRoutes = omit(getAllRoutes(userId, reportId), [
      ALL_STEPS.SETUP,
      ALL_STEPS.COMPS,
      ALL_STEPS.FINALIZE
    ]);

    return setDefaultStepValues(newSteps, newRoutes);
  };

  useEffect(() => {
    if (errorReport?.response?.status === 403) {
      route('/dashboard');
    }
  }, [errorReport]);

  useEffect(() => {
    // run when editing
    if (report && !isEmpty(viewerAccount) && !availableSteps.length) {
      updateAvailableSteps();
    }

    // run when new report
    if (reportId === 'new' && !availableSteps.length) {
      setDefaultStepValues(STEPPER_STEPS, getAllRoutes(userId, null));
    }
  }, [reportId, report, viewerAccount, availableSteps]);

  useEffect(() => {
    if (activeStep) {
      updateContext({
        stepperActiveStep: availableSteps.indexOf(activeStep)
      });
    }
  }, [activeStep]);

  const submitStep = (step) => {
    // going forward
    if (step > availableSteps.indexOf(activeStep)) {
      // this triggers the handleSubmit function in the form
      // defined at src/pagesDashboard/NewUserReport, func finalizeReport
      formRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      );
    }
    // going backwards
    if (step < availableSteps.indexOf(activeStep)) {
      const newStep = availableSteps[step];
      const routeToGo = getRoutes(userId, reportId, newStep);
      route(routeToGo);
    }
  };

  const changeStep = (nextStep) => {
    const newStep = availableSteps[nextStep];

    // going forward
    if (nextStep > availableSteps.indexOf(activeStep)) {
      if (reportId !== 'new') {
        const routeToGo = getRoutes(userId, reportId, newStep);
        return route(routeToGo);
      }
      return toast.error('You need to complete the Setup step!');
    }

    // going backwards
    if (nextStep < availableSteps.indexOf(activeStep)) {
      const routeToGo = getRoutes(userId, reportId, newStep);
      route(routeToGo);
    }
  };

  const goBack = () => submitStep(availableSteps.indexOf(activeStep) - 1);
  const goNext = () => {
    if (isUnassigned) return route(appUtils.getHomeRoute());
    submitStep(availableSteps.indexOf(activeStep) + 1);
  };

  const goBackToReports = () => {
    if (isMe) return route(appUtils.getDashRoute(null, 'reports'));
    if (isUnassigned) return route(appUtils.getHomeRoute());
    return route(appUtils.getDashRoute(userId, 'reports'));
  };

  const exit = () => {
    resetContext();
    if (refreshReports) {
      queryClient.invalidateQueries([REPORTS_QUERY_KEYS.REPORTS]);
    }
    goBackToReports();
  };

  const isFinishStep = activeStep === ALL_STEPS.FINALIZE;

  const isReportFinished = report && report.status === REPORT_STATUS.FINISHED;

  const updateStatus = async (newStatus) => {
    try {
      await updateReport({
        reportId,
        status: newStatus
      });
      if (newStatus === REPORT_STATUS.IN_PROGRESS) {
        updateContext({
          reopenReport: true
        });
      }
      queryClient.invalidateQueries([REPORTS_QUERY_KEYS.REPORT, reportId]);
      queryClient.invalidateQueries(REPORTS_QUERY_KEYS.SHARED_REPORTS);
      if (newStatus === REPORT_STATUS.FINISHED) {
        queryClient.invalidateQueries([REPORTS_QUERY_KEYS.REPORTS]);
        queryClient.invalidateQueries([ACCOUNT_QUERY_KEYS.TASKS]);
        goBackToReports();
      }
      toast.show('Report status updated');
    } catch (error) {
      console.error('Failed to update report status', error);
      return toast.error(error ?? 'Failed to update report status');
    }
  };

  const reopenReportFn = () => updateStatus(REPORT_STATUS.IN_PROGRESS);
  const finalizeReport = () => updateStatus(REPORT_STATUS.FINISHED);

  if (!availableSteps.length || !activeStep || isNil(stepperActiveStep)) return <div />;

  const userName = userData.name;

  return (
    <div
      className={`bg-white text-black h-full md:min-h-98screen max-w-1440 min-w-1/2 flex flex-col items-start p-10 ${
        isFetching && !aiGenerationLoading ? 'sectionbox-loading-content' : ''
      }`}
    >
      {aiGenerationLoading ? null : (
        <section
          className={`flex flex-col ${isFinishStep ? '' : 'mb-6 md:mb-16'}  w-full`}
        >
          <div className='flex flex-col md:flex-row justify-between text-xl w-full mb-10 items-center'>
            <h3 className='text-xl inline'>{` ${userName}`}</h3>
            <Stepper
              steps={availableSteps}
              activeStep={stepperActiveStep}
              setActiveStep={changeStep}
              customClasses='hidden md:flex'
            />
          </div>

          <div>
            <h1 className='text-4xl font-semibold'>
              {PAGE_TITLES[activeStep]}
            </h1>
            <h5 className='text-xl text-gray-600'>
              {getPageSubtitles(userName)[activeStep]}
            </h5>
            {isUnassigned ? (
              <div className='mt-2 bg-zinc-50 border border-zinc-300 text-black rounded p-2 w-fit'>
                <p className='m-0 text-xl text-secondary-gray'>
                  Team member is archived - please restore user from the
                  {' '}
                  <a
                    className='text-xl text-secondary-gray underline'
                    href='/dashboard/people'
                  >
                    People page
                  </a>
                  {' '}
                  in order to edit their report.
                </p>
              </div>
            ) : null}
          </div>
        </section>
      )}

      {children}

      {aiGenerationLoading ? null : (
        <ReportFooter
          userId={userId}
          isLoading={isLoading}
          isNextButtonDisabled={false}
          showPreviousButton={availableSteps.indexOf(activeStep) > 0}
          previousButtonAction={goBack}
          nextButtonAction={goNext}
          exitButtonAction={exit}
          nextButtonText={
            availableSteps.indexOf(activeStep) === availableSteps.length - 1
              ? 'Finish'
              : 'Next'
          }
          isFinishStep={isFinishStep}
          isReportFinished={isReportFinished}
          reopenButtonAction={reopenReportFn}
          finalizeButtonAction={finalizeReport}
          isUpdateReportLoading={isUpdateReportLoading}
        />
      )}
    </div>
  );
};

export default NewUserReportLayout;
