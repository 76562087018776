import { h } from 'preact';
import { route } from 'preact-router';
import {
  ConnectToSlack, Button, appUtils, toast
} from 'src/components/';
import COMMON_CONSTANTS from 'common/commonConstants';
import OnboardingBase from 'src/pagesDashboard/Onboarding/OnboardingBase';
import SlackIconSVG from 'src/assets/svg/slack-icon.svg';

const { SLACK_REDIRECT_URI } = COMMON_CONSTANTS;

const OnboardingSlack = () => {
  const loggedUser = appUtils.getLoggedUser();

  if (!loggedUser) {
    toast.show('Please login to continue');
    return route(appUtils.getBaseUrl());
  }

  return (
    <OnboardingBase>
      <div className='w-full text-left'>
        <SlackIconSVG className='w-12 h-12 m-3' />
        <p className='text-xl font-bold m-0 text-black'>Slack Set Up</p>
        <p className='m-0'>
          You’ll be asked to authorize the WorkStory application to give us
          access to chat with you directly. Please make sure to select the
          correct Slack workspace.
        </p>
      </div>

      <div className='flex justify-between gap-4 w-full'>
        <Button
          variant='empty-with-border'
          classes='!rounded-md !w-1/2 !h-11'
          onClick={() => route(`/dashboard/onboarding/comms`)}
        >
          Previous
        </Button>
        <ConnectToSlack
          classes='!rounded-md !w-1/2 !h-11'
          redirectUri={SLACK_REDIRECT_URI.ONBOARDING_COMPLETE}
        />
      </div>
    </OnboardingBase>
  );
};

export default OnboardingSlack;
