import { h, Component, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { get } from 'lodash';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { route } from 'preact-router';
import { connect } from 'react-redux';
import Routes from 'src/app/Routes';
import InitApp from 'src/components/InitApp/InitApp';
import rollbarConfig from 'src/utils/rollbar';
import {
  SignUp,
  Dashboard,
  Success,
  Home,
  Admin,
  Page404,
  DocumentsRedirect,
  IntroRedirect,
  RedirectPage,
  Slack,
  SlackSignUp,
  Product
} from '../pages';
import { ErrorUI } from '../components';
import { ToastContainer, TOAST_CONTAINER_ID } from 'src/components/Toast/Toast';
import { Header, Footer, MobileMenu, ModalsContainer } from '../containers';
import appUtils from './appUtils';
import appThunks from '../thunks/appThunks';
import { appActions, sidebarActions } from '../reducers/actions';
import { CONSTANTS, APP_CONSTANTS } from '../constants';
import './app.scss';
import api from '../services/api';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useFlags } from 'launchdarkly-react-client-sdk';

const { USER_STATE } = COMMON_CONSTANTS;

const isAdPage = () => {
  return /^\/$/.test(window.location.pathname);
};

const DashPage = (props) => (
  <DashProvider>
    <Dash {...props} />
  </DashProvider>
);

const DashTeamPage = (props) => (
  <DashTeamProvider>
    <DashTeam {...props} />
  </DashTeamProvider>
);

const OrganizationPage = (props) => (
  <OrganizationProvider>
    <Organization {...props} />
  </OrganizationProvider>
);

const UserProfilePage = (props) => (
  <UserProfileProvider>
    <UserProfileV2 props={props} />
  </UserProfileProvider>
);

const TeamPage = (props) => (
  <TeamProvider>
    <Team props={props} />
  </TeamProvider>
);

const SettingsPage = (props) => (
  <SettingsProvider>
    <Settings {...props} />
  </SettingsProvider>
);

const TopLevel = (props) => {
  const [ready, setReady] = useState(false);
  // React defers running useEffect with no dependency until after the browser has rendered
  // https://legacy.reactjs.org/docs/hooks-effect.html
  useEffect(() => {
    const doesToastContainerExist = document.getElementById(TOAST_CONTAINER_ID);
    if (!doesToastContainerExist) {
      return console.error('Toast container not loaded in TopLevel');
    }
    setReady(true);
  });

  return (
    <Fragment>
      <ToastContainer />
      {ready ? <Fragment>{props.children}</Fragment> : null}
    </Fragment>
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullSize: this.isFullSize(),
      showIntroFooter: isAdPage()
    };
    if (!window.speedMatchMediaWatcher) {
      this.initWatchWidthOfScreen();
    }
  }

  componentDidMount = () => {
    this.isUserLoggedIn();
  };

  componentDidUpdate = () => {
    const env = process.env.NODE_ENV;
    const userLogged = get(this, 'props.app.userLogged');
    const userId = get(this, 'props.app.userLogged.id');

    if (env === 'production' && userId) {
      const name = get(userLogged, 'name');
      const email = get(userLogged, 'name');
    }
  };

  isUserLoggedIn = async () => {
    try {
      const response = await api.get('/user/me');

      if (response && response.success) {
        const { dispatch } = this.props;
        if (!appUtils.isOnGetSpeedback()) {
          appThunks.logUserIn(response.user)(dispatch);
          const canLogin = get(response, 'user.permissions.canLogin');
          const isUnassigned =
            get(response, 'user.status') === USER_STATE.UNASSIGNED;
          const autoLogout = canLogin === false || isUnassigned;
          if (response && response.success && autoLogout) {
            appThunks.logOut()(dispatch);
          }
        }
      }
    } catch (error) {
      console.error('app.js.isUsedLoggedIn', error);
      const shouldLogUserOut = get(error, 'response.data.logUserOut');
      if (shouldLogUserOut) {
        const { dispatch } = this.props;
        appThunks.logOut()(dispatch);
      }
    }
  };

  initWatchWidthOfScreen = () => {
    // const WidthChange = (mq) => {
    // 	if (mq.matches) {
    // 		console.log('greater than 500');
    // 	} else {
    // 		console.log('less than 500');
    // 	}
    // };

    const WidthChange768 = (mq) => {
      const {
        app: { showMobileMenu }
      } = this.props;
      if (mq.matches) {
        if (showMobileMenu) {
          const { dispatch } = this.props;
          dispatch(appActions.showMobileMenu(false));
        }
      }
    };

    const WidthChange1200 = (mq) => {
      const { dispatch } = this.props;
      if (mq.matches) {
      } else {
        dispatch(sidebarActions.minimizeSidebar());
      }
    };

    if (matchMedia) {
      const mq = window.matchMedia('(min-width: 1000px)');
      const mq768 = window.matchMedia('(min-width: 768px)');
      const mq1200 = window.matchMedia(
        `(min-width: ${APP_CONSTANTS.FORCE_MINIMUM_DASH_SIDEBAR_AT_WIDTH}px)`
      );

      mq1200.addListener(WidthChange1200);
      mq768.addListener(WidthChange768);
    }
  };

  isFullSize = () => {
    return /\/dashboard\/*/.test(window.location.href);
  };
  /** Gets fired when the route changes.
   *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
   *	@param {string} event.url	The newly routed URL
   */
  handleRoute = (e) => {
    // scroll to top of the page on every route change. add exceptions here if needed
    window.scrollTo(0, 0);
    // const appEl = document.getElementById("app");
    // document.body.scrollIntoView();

    const {
      app: { userLogged }
    } = this.props;
    if (
      e.url === '/' &&
      userLogged &&
      userLogged.email === 'admin1@getspeedback.com'
    ) {
      const { dispatch } = this.props;
      appThunks.logOut()(dispatch);
    }

    const { fullSize } = this.state;
    const shouldBeFullSize = this.isFullSize();
    const showIntroFooter = isAdPage();

    if (shouldBeFullSize && !fullSize) {
      this.currentUrl = e.url;
      return this.setState({
        fullSize: true,
        showIntroFooter
      });
    }
    if (!shouldBeFullSize && fullSize) {
      this.currentUrl = e.url;
      return this.setState({
        fullSize: false,
        showIntroFooter
      });
    }

    if (e.url !== this.currentUrl) {
      return this.setState({
        showIntroFooter
      });
    }

    this.currentUrl = e.url;
  };

  render() {
    const { fullSize, showIntroFooter } = this.state;
    const {
      app: { showMobileMenu, userLogged },
      dispatch
    } = this.props;

    let isSuperUser = userLogged ? userLogged.isSuperUser : false;

    const { enableRollbar } = useFlags();
    const rollbarLogConfig = rollbarConfig(enableRollbar);

    console.log('version 704pm');
    return (
      <Provider config={rollbarLogConfig}>
        <ErrorBoundary fallbackUI={ErrorUI}>
          <TopLevel>
            <InitApp />
            {/* <TestError /> */}
            <ModalsContainer />
            {showMobileMenu ? <MobileMenu /> : null}
            <canvas id='myChart'></canvas>

            <div
              class={`getspeedback full-size ${
                showIntroFooter ? 'getspeedback-intro-footer' : ''
              }`}
            >
              <Routes />
            </div>
          </TopLevel>
        </ErrorBoundary>
      </Provider>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    app: state.appReducer
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
