import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import DOMPurify from 'dompurify';
import { useCompany } from 'src/queries/company';
import { getReportAiNoteQuery, useReportNotes } from 'src/queries/reports';
import { useCreateNote } from 'src/hooks/UserReports/useNote';
import { useSearchUsers } from 'src/queries/user';
import { Button } from 'src/components';
import NotesNewReport from 'src/pagesDashboard/NewUserReport/components/NotesNewReport';
import AddNoteNewReport from 'src/pagesDashboard/NewUserReport/components/AddNoteNewReport';

import COMMON_CONSTANTS from 'common/commonConstants';
import commonDateUtils from 'common/commonDateUtils';
import commonGoalUtils from 'common/commonGoalUtils';

const { REPORT_TEMPLATE_TYPES, REPORT_NOTE_TYPES, REPORT_PROMISE_TYPES } = COMMON_CONSTANTS;

const GoalsUpdatesList = ({
  updates = [],
  type,
  onAction,
  actionText,
  showActionRule
}) => {
  const fetchUserIds = updates.map((update) => update.author);
  const useSearchUsersEnabled = Boolean(fetchUserIds.length);
  const { data, isFetching: isAuthorsFetching } = useSearchUsers(
    {
      ids: fetchUserIds
    },
    {
      page: 1,
      size: fetchUserIds.length
    },
    useSearchUsersEnabled
  );

  if (!updates.length) {
    return 'No updates yet';
  }

  const isRenderReady = !isAuthorsFetching && data;
  if (!isRenderReady) return null;

  const { data: authors } = data;

  return (
    <div className='mx-2 my-2 flex flex-col text-black font-bold'>
      <div className='mt-4'>
        <div className='mb-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm'>
          <p className='mb-0 pl-1 w-2/12 inline-block font-bold'>Date</p>
          <p className='mb-0 w-2/12 inline-block font-bold'>Name</p>
          <p className='mb-0 w-2/12 inline-block font-bold'>Progress</p>
          <p className='mb-0 w-5/12 inline-block font-bold'>Comments</p>
        </div>

        <div className='max-h-16rem overflow-y-scroll'>
          {updates.map((goalUpdate) => {
            const updateData = { ...goalUpdate };
            updateData.timestamp = commonDateUtils
              .unixToMonthDayYearFormat(goalUpdate.timestamp)
              .toString();
            const authorName = authors.find(
              (author) => author.id === goalUpdate.author
            ).name;
            return (
              <div className='mb-2 py-1 px-1 text-black font-bold rounded-sm'>
                <p className='mb-0 pl-1 w-2/12 inline-block'>
                  {updateData.timestamp}
                </p>
                <p className='mb-0 w-2/12 inline-block'>{authorName}</p>
                <p className='mb-0 w-2/12 inline-block'>
                  {commonGoalUtils.getGoalProgressLabel({
                    type,
                    progress: updateData.progress
                  })}
                </p>
                <p className='mb-0 w-4/12 inline-block'>{updateData.text}</p>
                {onAction && showActionRule(goalUpdate) ? (
                  <div className='md:w-2/12 inline-block align-top text-right'>
                    <button
                      type='button'
                      onClick={() => onAction(goalUpdate._id)}
                      className='bg-success-green hover:bg-success-green-hover whitespace-nowrap transition-colors duration-300 px-2 py-2 rounded text-xs text-white bold'
                    >
                      {actionText}
                    </button>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const GoalNewReport = ({
  goal,
  reportId,
  previousReport,
  viewOnly = false,
  isEditorOpenByDefault = true
}) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: goalNotes,
    isFetching: isFetchingGoalNotes,
    isError: isErrorGoalNotes
  } = useReportNotes(reportId, {
    type: REPORT_NOTE_TYPES.GOAL,
    meta: { goalId: goal._id }
  });

  const {
    data: previousGoalsNotes,
    isFetching: isFetchingPreviousGoalNotes,
    isError: isErrorPreviousGoalNotes
  } = useReportNotes(previousReport, {
    type: REPORT_NOTE_TYPES.GOAL,
    meta: { goalId: goal._id }
  });

  const [notesOpen, setNotesOpen] = useState(null);
  const [includedEditNoteGoal, setIncludedEditNoteGoal] = useState('');
  const [includedNewNoteGoal, setIncludedNewNoteGoal] = useState('');

  const { saveNote } = useCreateNote();

  const [isGoalOpen, setIsGoalOpen] = useState(false);

  const isFetching = isFetchingCompany || isFetchingGoalNotes || isFetchingPreviousGoalNotes;
  const isError = isErrorCompany || isErrorGoalNotes || isErrorPreviousGoalNotes;
  const isRenderReady = !isFetching && !isError && company;

  if (!isRenderReady) return <div />;

  useEffect(() => {
    if (goalNotes && goalNotes.length) {
      setIsGoalOpen(true);
    }
  }, [goalNotes.length]);

  const goalNotesTemplates = company.templates
    .filter((t) => t.type === REPORT_TEMPLATE_TYPES.GOAL_TEMPLATE)
    .map((t) => ({
      id: t.id,
      label: t.name,
      content: t.content
    }));
  goalNotesTemplates.unshift({
    id: 'clear',
    label: 'No template',
    content: ''
  });

  const toggleGoal = () => {
    setIsGoalOpen(!isGoalOpen);
  };

  const clearIncludedText = (input) => {
    if (input === 'edit') {
      setIncludedEditNoteGoal('');
      clearIncludedText('new');
    }
    if (input === 'new') setIncludedNewNoteGoal('');
    setNotesOpen(null);
  };

  const addToNotes = (noteId) => {
    if (noteId) {
      const goalUpdate = goal.updates.find((r) => r._id === noteId);
      if (goalUpdate) {
        if (notesOpen === 'edit') {
          setIncludedEditNoteGoal(`${goalUpdate.text}\n`);
          setTimeout(() => setIncludedEditNoteGoal(''), 20);
        }
        if (!notesOpen || notesOpen === 'new') {
          setIncludedNewNoteGoal(`${goalUpdate.text}\n`);
          setTimeout(() => setIncludedNewNoteGoal(''), 20);
        }
      }
    }
  };

  const sanitizedGoalDescription = () => ({
    __html: DOMPurify.sanitize(goal.description)
  });

  const { mutateAsync: getReportAiNote, isLoading: isLoadingGetReportAiNote } = getReportAiNoteQuery();
  const generateAiNote = async () => {
    const params = {
      reportId,
      userId: goal.assignee,
      type: REPORT_PROMISE_TYPES.CATEGORIES_SINGLE,
      goalId: goal._id
    };
    return getReportAiNote(params);
  };

  return (
    <div className='mb-4 shadow border border-zinc-400 px-6'>
      <button
        className='focus:outline-none text-xl pt-4 pb-4 bg-white w-full rounded flex'
        type='button'
      >
        <div className='flex flex-col text-left w-2/3 mb-2'>
          <span className='ml-4 text-2xl font-bold mb-4'>{goal.title}</span>

          <span className='ml-4 mb-0 text-lg text-gray-500 font-semibold'>
            {`Current Status: ${goal.status ?? ''}`}
          </span>

          {/* <span className='ml-4 mb-0 text-md text-gray-500'>
            Previous Comparison Status: -
          </span> */}
        </div>

        <div className='flex flex-row ml-auto pr-4 my-auto'>
          <div className='inline-block text-sm w-full'>
            <Button
              type='button'
              variant={isGoalOpen ? 'empty-with-border' : 'custom'}
              classes={
                !isGoalOpen
                  ? 'min-w-6.82rem bg-purple hover:bg-hover-purple transition-colors duration-300 px-5 py-2 rounded-sm text-white bold text-lg'
                  : 'min-w-6.82rem'
              }
              onClick={() => {
                toggleGoal(goal.id);
              }}
            >
              {isGoalOpen ? 'Collapse' : 'View'}
            </Button>
          </div>
        </div>
      </button>
      {isGoalOpen ? (
        <div className='p-4 mt-2'>
          <p
            className='text-gray-600 font-semibold'
            dangerouslySetInnerHTML={sanitizedGoalDescription()}
          />
          <p className='text-gray-600 font-semibold mb-0'>
            Goal Type:
            {` ${goal.type}`}
          </p>
          <p className='text-gray-600 font-semibold'>
            Due Date:
            {` ${commonDateUtils.dateToMonthDayYearFormat(
              new Date(goal.deadline)
            )}`}
          </p>

          <GoalsUpdatesList
            updates={goal.updates}
            type={goal.type}
            onAction={addToNotes}
            actionText='Add to notes'
            showActionRule={(goalUpdate) => !viewOnly && goalUpdate.text && goalUpdate.text.length}
          />

          <NotesNewReport
            reportId={reportId}
            title='Notes'
            notes={goalNotes ?? []}
            previousNotes={previousGoalsNotes ?? []}
            includeText={includedEditNoteGoal}
            clearIncludeText={() => clearIncludedText('edit')}
            onFieldOpen={() => setNotesOpen('edit')}
            viewOnly={viewOnly}
          />

          {!viewOnly && notesOpen !== 'edit' ? (
            <AddNoteNewReport
              title='Goal note'
              reportId={reportId}
              noteType={REPORT_NOTE_TYPES.GOAL}
              includeText={includedNewNoteGoal}
              templatesOptions={goalNotesTemplates}
              isOpenByDefault={isEditorOpenByDefault ?? !goalNotes.length}
              meta={{
                goalId: goal._id
              }}
              parentValues={{
                noteCount: goalNotes.length,
                dataCount: goal?.updates?.length ?? 0
              }}
              generateNoteCallback={generateAiNote}
              isGenerateNoteLoading={isLoadingGetReportAiNote}
              onSave={saveNote}
              clearIncludeText={() => clearIncludedText('new')}
              onFieldOpen={() => setNotesOpen('new')}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default GoalNewReport;
