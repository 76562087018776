import { h } from 'preact';
import { useState } from 'preact/hooks';
import { GoogleLogin } from '@react-oauth/google';
import COMMON_CONSTANTS from 'common/commonConstants';
import { Base, toast } from 'src/components';
import { update } from 'src/queries/user';
import STYLE from 'src/constants/style';
import { useAccount } from 'src/queries/account';
import { get } from 'lodash';

const { LOGIN_TYPES } = COMMON_CONSTANTS;

const GoogleIntegration = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount,
    refetch: refetchAccount
  } = useAccount('me');

  const isFetching = isFetchingAccount;
  const isError = isErrorAccount;
  const isReady = account && account._id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const { mutateAsync: updateUser } = update();

  const disconnectGoogle = async () => {
    try {
      setIsLoading(true);
      const updated = await updateUser({
        id: account._id,
        googleId: null,
        googleOauth: null,
        loginType: LOGIN_TYPES.SPEEDBACK_INVITED
      });

      if (updated.error) toast.error(updated.error);
      else if (updated.success) toast.show('Google SSO disconnected!');
      refetchAccount();

      setIsLoading(false);
    } catch (error) {
      console.error('diconnectGoogle', error);
    }
  };

  const googleLogin = async (credentialResponse) => {
    try {
      setIsLoading(true);
      const updated = await updateUser({
        ...credentialResponse,
        id: account._id
      });

      if (updated.error) toast.error(updated.error);
      else if (updated.success) toast.show('Google account connected!');

      refetchAccount();

      setIsLoading(false);
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      setIsLoading(false);
    }
  };

  return (
    <Base classes={STYLE.CONTAINER_WHITE} loading={isLoading}>
      <h5 className='mb-2 text-left pt-2'>Google Integration</h5>

      {account.loginType === LOGIN_TYPES.GOOGLE ? (
        <p className='pb-2 mb-0'>
          <b>
            {get(account, 'googleOauth.email', 'A Google account')}
          </b>
          {' '}
          is connected. You can use this email to login.
          {' '}
          <button onClick={disconnectGoogle}>Disconnect</button>
        </p>
      ) : (
        <div className='mb-3'>
          <p className='pb-3 mb-0'>
            Connect your Google account to enable Google Sign On.
          </p>
          <GoogleLogin
            text='continue_with'
            onSuccess={googleLogin}
            onError={() => {
              console.error('Login Failed');
            }}
          />
        </div>
      )}
    </Base>
  );
};

export default GoogleIntegration;
