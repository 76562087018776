import { Fragment, h } from 'preact';
import { useEffect, useState, useContext } from 'preact/hooks';
import { Base, Pagination } from 'src/components';
import Goal from 'src/containers/Goals/Goal';
import ReportSVG from 'src/assets/svg/report.svg';
import { GoalsContext } from 'src/context/Goals/GoalsProvider';
import STYLE from 'src/constants/style';
import { useGoals } from 'src/queries/goal';

// renders on Organization - Goals page
const GoalsList = () => {
  const { context } = useContext(GoalsContext);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: goals,
    meta: {
      page: { totalPages }
    },
    isFetching: isFetchingGoals,
    isError: isErrorGoals
  } = useGoals(
    { isTopLevel: true, ...context },
    {
      page: {
        number: currentPage,
        size: 10
      },
      sort: {
        field: 'createdDate',
        order: 'asc'
      }
    }
  );

  const isFetching = isFetchingGoals;
  const isError = isErrorGoals;
  const isReady = goals && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  useEffect(() => {
    setCurrentPage(1);
  }, [context]);

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <div className='mb-2 mt-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm'>
        <p className='mb-0 pl-1 w-5/12 inline-block font-bold'>Title</p>
        <p className='mb-0 w-2/12 inline-block font-bold'>Assignee</p>
        <p className='mb-0 w-2/12 inline-block font-bold'>Progress</p>
        <p className='mb-0 w-2/12 inline-block font-bold'>Status</p>
        <p className='mb-0 w-1/12 inline-block font-bold'>Action</p>
      </div>

      <div>
        {goals ? (
          <Fragment>
            {!goals.length ? (
              <div className='mt-10 mb-8 text-center font-bold'>
                <div className='w-10 h-10 mx-auto my-2'>
                  <ReportSVG />
                </div>
                <p className='cursor-default'>No goals created yet.</p>
              </div>
            ) : null}
            {goals.length
              ? goals.map((goal) => (
                <Goal goal={goal} options={{ showAddGoal: true }} />
              ))
              : null}
          </Fragment>
        ) : null}
        <div className='mt-6'>
          <Pagination
            name='Goals'
            totalPages={totalPages}
            currentPage={currentPage}
            selectPage={setCurrentPage}
          />
        </div>
      </div>
    </Base>
  );
};

export default GoalsList;
