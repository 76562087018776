import { Fragment, h } from 'preact';
import { useContext, useState } from 'preact/hooks';
import { useReports } from 'src/queries/reports';
import { DashContext } from 'src/pagesDashboard/Dash/context/DashProvider';
import { route } from 'preact-router';
import { Base } from 'src/components/';
import { useTree } from 'src/queries/tree';
import commonTreeUtils from 'common/commonTreeUtils';
import commonDateUtils from 'common/commonDateUtils';
import appUtils from 'src/components/appUtils';
import STYLE from 'src/constants/style';
import FilterView from 'src/containers/UserProfile/FilterView/FilterView';
import COMMON_CONSTANTS from 'common/commonConstants';
import { Table } from 'src/componentsTailwind';
import { TYPES } from 'src/componentsTailwind/Table/Table';
import { BADGE_COLOR_CLASSES } from 'src/componentsTailwind/tailwindConstants';
import commonQuestions from 'common/commonQuestions';
import { useCompany } from 'src/queries/company';

const { REPORT_TYPE } = COMMON_CONSTANTS;

const MyReports = ({ userId }) => {
  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = appUtils.getLoggedUserId();
  const { context } = useContext(DashContext);
  const { range } = context;
  const {
    data: { tree, deleted },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: reportsData,
    isFetching: isFetchingReports,
    isRefetching: isRefetchingReports,
    isError: isErrorReports
  } = useReports(
    {
      userId,
      companyid: loggedUser.companyid,
      lastUpdated: {
        start: range.start,
        end: range.end
      }
    },
    {
      page: {
        number: currentPage,
        size: 10
      }
    },
    {
      keepPreviousData: true
    }
  );

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isInitialFetchingReports = isFetchingReports && !isRefetchingReports;
  const isFetching = isInitialFetchingReports || isFetchingTree || isFetchingCompany;
  const isError = isErrorReports || isErrorTree || isErrorCompany;
  const isReady = tree
    && tree.id
    && company
    && company.id
    && reportsData
    && !isError
    && !isFetching;

  if (!isReady) {
    return null;
  }

  const viewReport = (report) => {
    const encoded = appUtils.encodeURIString(report._id || report.id);
    return route(`/dashboard/profile/${userId}/report/${encoded}/preview`);
  };

  const createReport = () => route(`/dashboard/profile/${loggedUserId}/report/new`);

  const { data: reports, meta } = reportsData;
  const { page } = meta;

  const pagination = {
    currentPage,
    setCurrentPage,
    totalPages: page.totalPages
  };

  const columns = [
    {
      label: 'Last Updated',
      span: 1.5
    },
    {
      label: 'Reviewer',
      span: 1.5
    },
    {
      label: 'Reviewee',
      span: 1.5
    },
    {
      type: TYPES.BADGES,
      label: 'Roles',
      span: 1.75
    },
    {
      label: 'Type'
    },
    {
      type: TYPES.BADGES,
      label: 'Status'
    },
    {
      type: TYPES.ACTION,
      label: 'View',
      hidden: true,
      span: 0.5
    }
  ];

  const rows = reports.map((report) => {
    const authorNode = commonTreeUtils.findNodeById(tree, report.createdBy)
      || deleted.find((node) => node.id === report.createdBy);

    const revieweeNode = commonTreeUtils.findNodeById(tree, report.user)
      || deleted.find((node) => node.id === report.user);

    let roleBadges = [
      {
        label: 'All Roles',
        colorClasses: BADGE_COLOR_CLASSES.GRAY
      }
    ];

    if (report.roles.length > 0) {
      const roleIdsToShow = report.roles.slice(0, 3);
      const roleObjects = roleIdsToShow.map((roleId) => commonQuestions.getRoleById(roleId, company.questions));
      roleBadges = roleObjects.map((roleObject) => ({
        label: roleObject.label,
        colorClasses: BADGE_COLOR_CLASSES.GRAY
      }));
      if (report.roles.length > 3) {
        roleBadges.push({
          label: `and more`,
          color: BADGE_COLOR_CLASSES.GRAY
        });
      }
    }

    return [
      {
        label: commonDateUtils.unixToMonthDayYearFormat(report.lastUpdated)
      },
      {
        label: authorNode.name
      },
      {
        label: revieweeNode.name
      },
      {
        type: TYPES.BADGES,
        badges: roleBadges
      },
      {
        label:
          report.user === authorNode.id
            ? REPORT_TYPE.SELF_REVIEW
            : REPORT_TYPE.REVIEW
      },
      {
        type: TYPES.BADGES,
        badges: [
          {
            label: report.status,
            color: BADGE_COLOR_CLASSES.GREEN
          }
        ]
      },
      {
        type: TYPES.ACTION,
        label: 'View',
        onClick: () => viewReport(report)
      }
    ];
  });

  const isLoading = isRefetchingReports;

  return (
    <Fragment>
      <FilterView userId={userId} show={['date']} />
      <Base classes={STYLE.CONTAINER_WHITE} loading={isLoading}>
        <div className='flex justify-between'>
          <div className='w-3/5'>
            <h5 className='text-black text-left text-xl mb-2'>Reviews</h5>
          </div>
        </div>
        <Table
          columns={columns}
          rows={rows}
          pagination={pagination}
          placeholderMessage='You have no reports'
        />
      </Base>
    </Fragment>
  );
};

export default MyReports;
