import { h, Fragment } from 'preact';
import { useForm, Controller } from 'react-hook-form';
import { useState, useEffect, useContext } from 'preact/hooks';
import { route } from 'preact-router';
import { AppContext } from 'src/app/context/AppProvider';
import {
  getEmailReviewQuery,
  submitEmailReviewQuery
} from 'src/queries/reviews';
import { get } from 'lodash';
import {
  Base,
  toast,
  Button,
  Radio,
  Circle,
  ReviewAiSuggestions
} from 'src/components/';
import COMMON_CONSTANTS from 'common/commonConstants';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import { sharedReviewUtils } from 'src/common';
import commonCompanyUtils from 'common/commonCompanyUtils';

const { QUESTION_ANSWER_TYPES } = COMMON_QUESTION_CONSTANTS;

const EMAIL_PAGE_STYLE = 'right-standard m-3 sm:w-38rem md:w-45rem sm:mx-auto mb-12 p-2 px-4 sm:px-1 rounded-md bg-cloud-blue mt-0';
const {
  REVIEW_VISIBILITY,
  COMMENT_VISIBILITY,
  REVIEW_ANSWER_TYPE,
  IM_NOT_SURE,
  HELLO_WORKSTORY_EMAIL
} = COMMON_CONSTANTS;

const getAnswerObject = (answer, questionObject) => {
  if (answer.id === REVIEW_ANSWER_TYPE.NOT_AVAIL || answer === '-1') {
    return {
      id: REVIEW_ANSWER_TYPE.NOT_AVAIL,
      value: IM_NOT_SURE,
      answer: '-1'
    };
  }
  return questionObject.answers.custom.find(
    (a) => a.id.toString() === answer.toString()
  );
};

const Loading = ({ message, loading = true }) => (
  <div className='pt-10 h-20 w-full'>
    <div className={EMAIL_PAGE_STYLE}>
      <Base
        variant='transparent'
        classes='text-center pb-20 pt-20'
        loadingClass=''
        loading={loading}
      >
        <p>{message ? message.toString() : 'Loading..'}</p>
      </Base>
    </div>
  </div>
);

const getBrowser = () => {
  try {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(
      /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
    ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return `IE ${tem[1] || ''}`;
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return {
      browser: M[0],
      version: M[1]
    };
  } catch (error) {
    return {};
  }
};

const BrowserError = () => (
  <div className='pt-10 h-20 w-full'>
    <div className={`${EMAIL_PAGE_STYLE} border `}>
      <Base
        variant='transparent'
        classes='text-center py-4 px-4 font-bold'
        loadingClass=''
        loading={false}
      >
        <p className='mb-0 font-bold'>
          <p className='mb-4 font-bold'>
            🔔 Update Needed for Better Performance!
            {' '}
          </p>
          You might be using an older version of your browser, which may affect
          completing performance reviews. For a smoother experience, please
          update your browser.
          {' '}
          <br />
          <br />
          Alternatively, you can log in to WorkStory and visit the "Reviews Due"
          page to complete your reviews.
          {' '}
          <br />
          <br />
          {' '}
          Need help?
          {' '}
          <a style='font-weight: 900' href={`mailto:${HELLO_WORKSTORY_EMAIL}`}>
            <b>{`Contact our support team (${HELLO_WORKSTORY_EMAIL})`}</b>
          </a>
          . 🚀
        </p>
      </Base>
    </div>
  </div>
);

const NewEmailReview = () => {
  const { globalContext } = useContext(AppContext);

  const [state, setState] = useState({
    reviewId: null,
    result: {},
    comments: '',
    reviewVisibility: REVIEW_VISIBILITY.VISIBLE_TO_MANAGER,
    commentVisibility: COMMENT_VISIBILITY.VISIBLE_TO_REVIEWEE,
    anonymous: false,
    shareComment: false,
    company: null,
    isError: null
  });
  const { mutateAsync: getEmailReview, isLoading: getReviewLoading } = getEmailReviewQuery();
  const { mutateAsync: submitEmailReview, isLoading: submitReviewLoading } = submitEmailReviewQuery();

  const [reviewVisibilitySettings, setReviewVisibilitySettings] = useState({});
  const [
    availableShareReviewVisibilityOptions,
    setAvailableShareReviewVisibilityOptions
  ] = useState([]);
  const [answersOptions, setAnswersOptions] = useState([]);
  const [emailOption, setEmailOption] = useState([]);
  const [commentsRequired, setCommentsRequired] = useState();
  const [isFreeTextAnswerType, setIsFreeTextAnswerType] = useState(false);
  const {
    watch,
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm({
    defaultValues: {
      commentVisible: null,
      reviewAnswer: null,
      reviewComments: ''
    }
  });

  const commentVisible = watch('commentVisible');
  const reviewAnswer = watch('reviewAnswer');
  const reviewComments = watch('reviewComments');
  const companySettings = get(state, 'result.company.settings', false);

  const redirect = () => route('/dashboard/email-review-transition');

  const getEmailAnswer = (allAnswerOptions) => {
    const emailAnswer = state.result.answer === -1
      ? { id: REVIEW_ANSWER_TYPE.NOT_AVAIL, value: IM_NOT_SURE }
      : allAnswerOptions.find((option) => option.id === state.result.answer);
    return emailAnswer;
  };

  useEffect(async () => {
    if (globalContext && globalContext.matches && globalContext.matches.id) {
      const { matches } = globalContext;
      const result = await getEmailReview(matches);
      if (!result || !result.success) {
        return setState({
          ...state,
          isError:
            result.message
            || 'We ran into an issue. Please refresh to try again!'
        });
      }
      setState({ ...state, reviewId: matches.id, result });
    }
  }, [globalContext]);

  if (state.isError) {
    const browser = getBrowser();
    console.log('User browser:', browser);
    if (
      (browser.browser === 'Chrome' && browser.version === '100')
      || browser.version === '101'
    ) {
      console.log('Display BrowserError');
      return <BrowserError />;
    }

    return <Loading loading={false} message={state.isError} />;
  }

  useEffect(() => {
    const opts = get(state, 'result.company.settings.reviewVisibilityOptions');
    if (opts) {
      setReviewVisibilitySettings(opts);
    }
  }, [state.result]);

  useEffect(() => {
    if (
      companySettings
      && state.result
      && state.result.revieweeName
      && Object.values(reviewVisibilitySettings).length > 0
    ) {
      const visibilityOptions = sharedReviewUtils.renderShareCommentsVisibilityOptions(
        state.result.revieweeName
      );

      const shareCommentOptions = sharedReviewUtils.getShareCommentOptions(
        reviewVisibilitySettings,
        visibilityOptions
      );

      const commentVisibleInitialValue = companySettings?.reviewVisibilityDefaultOption
        ?? shareCommentOptions[0].value;
      setState({
        ...state,
        shareComment: commentVisibleInitialValue
      });

      setValue('commentVisible', commentVisibleInitialValue);
      setAvailableShareReviewVisibilityOptions(shareCommentOptions);
    }
  }, [reviewVisibilitySettings, state.result]);

  useEffect(() => {
    // added try catch for rollbar https://app.rollbar.com/a/workstory/fix/item/frontend/360#detail
    try {
      if (state.result && state.result.questionObject) {
        const { questionObject } = state.result;

        if (questionObject.answers) {
          const allAnswerOptions = [
            ...questionObject.answers.custom.sort((a, b) => (a.score < b.score ? 1 : -1)),
            {
              id: REVIEW_ANSWER_TYPE.NOT_AVAIL,
              value: IM_NOT_SURE
            }
          ];
          const emailAnswer = getEmailAnswer(allAnswerOptions);
          setAnswersOptions(allAnswerOptions);
          setEmailOption(emailAnswer);
        }
        if (questionObject.answers.type === REVIEW_ANSWER_TYPE.FREE_TEXT) {
          setIsFreeTextAnswerType(true);
        }
      }
    } catch (error) {
      console.error('EmailReview/useEffect(state.result)', error, {
        state
      });
    }
  }, [state.result]);

  useEffect(() => {
    if (emailOption) {
      setValue('reviewAnswer', emailOption);
    }
  }, [emailOption]);

  const { reviewId, result } = state;

  const { questionObject, answer: emailAnswer } = result;
  const { companyid } = globalContext.matches;

  const saveReview = async () => {
    try {
      const currentAnswer = reviewAnswer !== '' ? reviewAnswer.id : emailAnswer;
      const params = {
        id: reviewId,
        comments: reviewComments === '' ? null : reviewComments,
        shareComment:
          commentVisible !== ''
            ? commentVisible
            : availableShareReviewVisibilityOptions[0].value,
        companyid
      };

      const isNumericAnswer = [
        QUESTION_ANSWER_TYPES.ONE_TO_TEN,
        QUESTION_ANSWER_TYPES.ONE_TO_FIVE,
        QUESTION_ANSWER_TYPES.CUSTOM
      ].includes(questionObject.answers.type);

      const isNA = currentAnswer === REVIEW_ANSWER_TYPE.NOT_AVAIL;
      if (isNA) {
        params.answer = '-1';
        params.score = null;
        params.isNA = true;
      }

      if (isNumericAnswer && !isNA) {
        const answerObj = getAnswerObject(currentAnswer, questionObject);
        const { score, value: answer } = answerObj;
        params.answer = answer;
        params.score = score;
      }

      await submitEmailReview(params);
      toast.show('Review saved!');
      redirect();

      return true;
    } catch (error) {
      console.error('EmailReview/saveReview error', error);
      toast.error(error);
      return false;
    }
  };

  useEffect(() => {
    if (companySettings && reviewAnswer) {
      const required = commonCompanyUtils.doesRequireComments(
        companySettings,
        reviewAnswer.score
      ) || isFreeTextAnswerType;
      setCommentsRequired(required);
    }

    if (
      !isDirty
      && reviewAnswer
      && reviewAnswer.id === REVIEW_ANSWER_TYPE.NOT_AVAIL
    ) {
      saveReview();
    }
  }, [companySettings, reviewAnswer, isFreeTextAnswerType, isDirty]);

  const isAnswerNa = reviewAnswer && reviewAnswer.id === REVIEW_ANSWER_TYPE.NOT_AVAIL;
  const isSubmitDisabled = () => {
    if (isAnswerNa) {
      if (!reviewAnswer) return true;
      return false;
    }

    if (isFreeTextAnswerType) {
      if (!reviewComments) {
        return true;
      }
      return false;
    }

    if (!reviewAnswer || (commentsRequired && !reviewComments)) {
      return true;
    }
    return false;
  };

  if (get(state, 'result.success', null) === false) {
    return (
      <div className='pt-10 h-20 w-full'>
        <div className={EMAIL_PAGE_STYLE}>
          <Base variant='transparent' classes='text-center pb-6'>
            <p className='font-bold mt-4 mb-0 text-xl'>
              {state.result.message}
            </p>
          </Base>
        </div>
      </div>
    );
  }

  if (
    getReviewLoading
    || !globalContext
    || !globalContext.matches
    || !globalContext.matches.id
    || !state.result
    || !state.result.company
    || !state.result.company.settings
    || state.result.answer === -1
    || reviewAnswer === ''
  ) {
    return <Loading />;
  }

  const showCommentVisibilitySection = !state.result.isSelfReview
    && availableShareReviewVisibilityOptions.length > 1
    && !isAnswerNa;

  const getAnswerLabels = () => {
    if (questionObject.answers.type === QUESTION_ANSWER_TYPES.ONE_TO_TEN) {
      return `1 = ${questionObject.headers.left}, 10 = ${questionObject.headers.right}`;
    }
    if (questionObject.answers.type === QUESTION_ANSWER_TYPES.ONE_TO_FIVE) {
      return `1 = ${questionObject.headers.left}, 5 = ${questionObject.headers.right}`;
    }
    if (questionObject.answers.type === QUESTION_ANSWER_TYPES.CUSTOM) {
      return `Low = ${questionObject.headers.left}, High = ${questionObject.headers.right}`;
    }
    return false;
  };

  return (
    <form
      className='flex flex-col items-center w-full'
      onSubmit={handleSubmit(saveReview)}
    >
      <div className='max-w-1240 w-full'>
        <div>
          <div className='h-32 -mb-18 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500' />
          {result && (
            <div className='mb-6 flex flex-col items-center'>
              <Circle
                size='xxl'
                imageUrl={state.result.revieweeImage}
                classes='mb-4 user-setting-profile-image relative h-131 w-131'
              />
              <div className=''>
                <div className='text-2xl bold'>{result.revieweeName}</div>
                <div className='text-base text-gray-400 text-center'>
                  {result.revieweeTitle}
                </div>
              </div>
            </div>
          )}
          <div className='mx-auto w-5/6'>
            <div className='mb-8 flex flex-col font-bold md:flex-row'>
              <div className='uppercase text-gray-500 text-xl mb-2 md:w-2/5'>
                Question
              </div>
              <div className='text-lg md:w-3/5'>{result.questionText}</div>
            </div>
            {!isFreeTextAnswerType && (
              <div className='flex flex-col mb-8 md:flex-row'>
                <div className='text-gray-500 mb-2 md:w-2/5'>
                  <div className='uppercase text-gray-500 font-bold text-xl'>
                    Answer
                  </div>
                  <div className='mb-8'>{getAnswerLabels()}</div>
                </div>
                <div className='md:w-3/5'>
                  {answersOptions.map((option) => (
                    <div className='mb-2 font-bold flex'>
                      <Controller
                        name='reviewAnswer'
                        control={control}
                        rules={{ required: false }}
                        render={({ field: { value } }) => (
                          <Radio
                            name='reviewAnswer'
                            checked={option.id === reviewAnswer.id}
                            value={value}
                            title={option.value}
                            onClick={() => {
                              setValue('reviewAnswer', option, {
                                shouldDirty: true
                              });
                            }}
                          />
                        )}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div>
              <div className='mb-8 flex flex-col md:flex-row'>
                <div className='text-gray-500 mb-2 md:w-2/5'>
                  <div className='uppercase font-bold text-xl mb-1'>
                    Comments
                  </div>
                  <div className='text-base pr-6'>
                    Give an example of what you think
                  </div>
                </div>
                <div className='flex flex-col md:w-3/5 '>
                  <textarea
                    maxLength='2000'
                    {...register('reviewComments', {
                      minLength: {
                        value: commentsRequired ? 10 : 0,
                        message:
                          'Please provide a longer comment to answer this question.'
                      },
                      required: {
                        value: commentsRequired,
                        message:
                          'Please provide a longer comment to answer this question.'
                      }
                    })}
                    className='text-base h-36'
                    placeholder={
                      commentsRequired
                        ? 'Constructive feedback is required.'
                        : 'Please provide constructive feedback.'
                    }
                  />
                  <div className='text-red whitespace-nowrap flex items-center'>
                    {errors?.reviewComments?.message}
                  </div>
                </div>
              </div>

              <ReviewAiSuggestions
                comments={reviewComments}
                userId={result.revieweeId}
                revieweeName={result.revieweeName}
                question={result.questionText}
                companyid={companyid}
                reviewerId={result.reviewerId}
              />

              <div className='flex flex-col mb-4 md:flex-row'>
                {showCommentVisibilitySection ? (
                  <Fragment>
                    <div className='uppercase text-gray-500 font-bold text-xl mb-2 md:w-2/5'>
                      Comment visibility
                    </div>
                    <div className='md:w-3/5'>
                      {showCommentVisibilitySection
                        ? availableShareReviewVisibilityOptions.map(
                          (option) => (
                            <div>
                              <Controller
                                name='commentVisible'
                                control={control}
                                rules={{ required: false }}
                                render={({ field: { value } }) => (
                                  <Radio
                                    classes='font-bold'
                                    name='commentVisible'
                                    value={value}
                                    title={option.title}
                                    checked={commentVisible === option.value}
                                    onClick={() => {
                                      setValue(
                                        'commentVisible',
                                        option.value
                                      );
                                    }}
                                  />
                                )}
                              />
                              <div>
                                <p className='text-dark-grey'>
                                  {option.comments}
                                </p>
                              </div>
                            </div>
                          )
                        )
                        : !state.result.isSelfReview
                          && availableShareReviewVisibilityOptions.length && (
                            <Fragment>
                              <p className='font-bold'>
                                {availableShareReviewVisibilityOptions[0].title}
                              </p>
                              <div>
                                <p className='text-dark-grey'>
                                  {
                                    availableShareReviewVisibilityOptions[0]
                                      .comments
                                  }
                                </p>
                              </div>
                            </Fragment>
                        )}
                    </div>
                  </Fragment>
                ) : null}
              </div>
            </div>
            <div className='flex justify-center md:justify-end mb-8 md:mr-8'>
              <Button
                disabled={submitReviewLoading || isSubmitDisabled()}
                variant='yellow'
                onClick={handleSubmit(saveReview)}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default NewEmailReview;
