import { h, Fragment } from 'preact';
import { useState } from 'preact/hooks';
import { Button, toast, appUtils } from 'src/components/';
import { useAccountUpdate } from 'src/queries/account';
import { route } from 'preact-router';
import OnboardingBase from 'src/pagesDashboard/Onboarding/OnboardingBase';
import commonUtils from 'common/commonUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import MSTeamsIconSVG from 'src/assets/svg/msteams-icon.svg';

const { COMMUNICATION_TYPES } = COMMON_CONSTANTS;

const OnboardingMSTeams = ({ step }) => {
  const loggedUser = appUtils.getLoggedUser();

  if (!loggedUser) {
    toast.show('Please login to continue');
    return route(appUtils.getBaseUrl());
  }

  const loggedUserId = loggedUser._id || loggedUser.id;
  const { update: updateAccount, isLoading: isLoadingAccountUpdate } = useAccountUpdate(loggedUserId);

  const [msTeamsEmail, setMSTeamsEmail] = useState('');
  const isMSTeamsEmailValid = commonUtils.isEmailValid(msTeamsEmail);

  const update = async () => {
    if (!isMSTeamsEmailValid) {
      return toast.show('Please enter a valid email');
    }

    const res = await updateAccount({
      data: {
        msTeamsEmail
        // preferredCommunication: COMMUNICATION_TYPES.MS_TEAMS
      }
    });

    if (!res || !res.success) {
      return toast.error(
        'We encountered an issue. Please try again or contact us.'
      );
    }

    route(`/dashboard/onboarding/msteams/2`);
  };

  return (
    <OnboardingBase>
      {Number(step) === 1 ? (
        <Fragment>
          <div className='w-full text-left'>
            <MSTeamsIconSVG className='w-12 h-12 m-3' />
            <p className='text-xl font-bold m-0 text-black'>
              Microsoft Teams Set Up - Part 1
            </p>
            <p className='m-0'>
              Enter the email you use in your Teams account. This may be
              different from your work email.
            </p>
          </div>
          <div className='w-full text-left'>
            <div className='text-left m-0 border rounded-md p-3'>
              <p className='font-bold m-0'>
                To verify, view your profile in Microsoft Teams before entering.
              </p>
              <ul className='m-0'>
                <li>1. Open Microsoft Teams</li>
                <li>2. Click on your profile image</li>
                <li>3. Your Teams email will be listed</li>
              </ul>
            </div>
          </div>
          <div className='w-full text-left'>
            <p className='m-0 font-bold'>Email used in Teams *</p>
            <input
              placeholder='Enter your email'
              className='bg-white rounded-lg border border-slate-300 h-11 w-[100%]'
              value={msTeamsEmail}
              onChange={(e) => setMSTeamsEmail(e.target.value)}
            />
          </div>
          <div className='flex justify-between gap-4 w-full'>
            <Button
              variant='empty-with-border'
              classes='!rounded-md !w-1/2 !h-11'
              onClick={() => route(`/dashboard/onboarding/comms`)}
            >
              Previous
            </Button>
            <Button
              variant='purple'
              classes='!rounded-md !w-1/2 !h-11'
              onClick={update}
              disabled={!isMSTeamsEmailValid || isLoadingAccountUpdate}
            >
              {isLoadingAccountUpdate ? 'Loading...' : 'Continue'}
            </Button>
          </div>
        </Fragment>
      ) : null}
      {Number(step) === 2 ? (
        <Fragment>
          <div className='w-full text-left'>
            <MSTeamsIconSVG className='w-12 h-12 m-3' />
            <p className='text-xl font-bold m-0 text-black'>
              Microsoft Teams Set Up - Part 2
            </p>
            <p className='m-0'>
              Install the WorkStory app to ensure you can receive notifications
              through Microsoft Teams
            </p>
          </div>
          <div className='w-full text-left'>
            <div className='text-left m-0 border rounded-md p-3'>
              <p className='font-bold m-0'>To add the WorkStory App...</p>
              <ul className='m-0'>
                <li>1. Open Microsoft Teams</li>
                <li>2. Click on Apps</li>
                <li>3. Search for WorkStory</li>
                <li>4. Click on Add for the WorkStory application</li>
                <li>5. A new window will open, click Add again</li>
                <li>6. From here, a chat window will open, send “Hello”</li>
                <li>7. You'll receive a confirmation message</li>
              </ul>
            </div>
          </div>
          <div className='flex justify-between gap-4 w-full'>
            <Button
              variant='empty-with-border'
              classes='!rounded-md !w-1/2 !h-11'
              onClick={() => route(`/dashboard/onboarding/msteams/1`)}
            >
              Previous
            </Button>
            <Button
              variant='purple'
              classes='!rounded-md !w-1/2 !h-11'
              onClick={() => route(`/dashboard/onboarding/complete`)}
            >
              Confirm
            </Button>
          </div>
        </Fragment>
      ) : null}
    </OnboardingBase>
  );
};

export default OnboardingMSTeams;
