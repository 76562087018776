import { Fragment, h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import {
  useReviewsDue,
  submitDirectReviewQuery,
  QUERY_KEYS as REVIEWS_QUERY_KEYS
} from 'src/queries/reviews';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import { Base, ReviewForm, toast } from 'src/components';
import commonTreeUtils from 'common/commonTreeUtils';
import commonQuestions from 'common/commonQuestions';
import appUtils from 'src/components/appUtils';
import { route } from 'preact-router';
import { useQueryClient } from 'react-query';
import commonReviewUtils from 'common/commonReviewUtils';
import { STYLE } from 'src/constants/style';
import { useAccount } from 'src/queries/account';
import commonUtils from 'common/commonUtils';

const clearReviewId = () => {
  const url = new URL(window.location.href);
  const searchString = url.search;
  if (!searchString.includes('reviewId')) return;

  const searchArray = searchString.split('?').pop();
  if (!searchArray.length) return;

  const newSearchArray = searchArray.split('&').filter((param) => !param.includes('reviewId'));
  const newSearchString = newSearchArray.join('&');

  history.replaceState(null, '', `${window.location.pathname}?${newSearchString}`);
};

const ReviewsDueHeader = ({
  index, setIndex, maxIndex, revieweeName
}) => (
  <HeaderV2
    overtitle={(
      <div className='mt-5'>
        {maxIndex > 1 ? (
          <div className='flex justify-between'>
            <p className='m-0'>{`Reviews Due: ${maxIndex} left`}</p>
            <div className='flex gap-2'>
              <button
                type='button'
                onClick={() => {
                  clearReviewId();
                  setIndex(index - 1);
                }}
                className='rounded-sm border border-black text-black p-1 px-3 py-1 w-[92px] disabled:border-gray-450 disabled:text-gray-450'
                disabled={index === 0}
              >
                <p className='m-0 text-sm font-bold'>Previous</p>
              </button>
              <div className='min-w-[92px] flex items-center justify-center'>
                <p className='m-0 text-sm text-black font-bold'>
                  {`Review #${index + 1}`}
                </p>
              </div>
              <button
                type='button'
                onClick={() => {
                  clearReviewId();
                  setIndex(index + 1);
                }}
                className='rounded-sm border border-black text-black p-1 px-3 py-1 w-[92px] disabled:border-gray-450 disabled:text-gray-450'
                disabled={index === maxIndex - 1}
              >
                <p className='m-0 text-sm font-bold'>Next</p>
              </button>
            </div>
          </div>
        ) : (
          'Review Due'
        )}
      </div>
    )}
    title={`Submit review for ${revieweeName}`}
  />
);

const ReviewsDue = () => {
  const params = new URLSearchParams(window.location.search);
  const reviewId = params.get('reviewId');
  const redir = params.get('redir');

  const queryClient = useQueryClient();
  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = loggedUser._id || loggedUser.id;
  const {
    reviews,
    isFetching: isFetchingReviewsDue,
    isError: isErrorReviewsDue,
    isPlaceholderData: isPlaceholderDataReviewsDue
  } = useReviewsDue({ extraIds: reviewId ? [reviewId] : [] });
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const { mutateAsync: submitReview, isLoading: isSubmitReviewLoading } = submitDirectReviewQuery();

  const isFetching = isFetchingCompany && isFetchingTree && isFetchingReviewsDue;
  const isError = isErrorCompany && isErrorReviewsDue && isErrorTree;
  const isReady = company
    && company.id
    && tree
    && tree.id
    && !isPlaceholderDataReviewsDue
    && !isFetching
    && !isError;

  if (!isReady) return null;

  if (!reviews.length) {
    toast.show("You've answered all your reviews due!");
    return route(redir || appUtils.getHomeRoute());
  }

  useEffect(() => {
    if (reviewId) {
      const doesDefaultReviewExist = reviews.some((r) => r.id === reviewId);
      if (!doesDefaultReviewExist) {
        toast.show('Review unavailable! Redirecting to your reviews due...');
        route('/dashboard/inbox/reviews');
      }
    }
  }, [reviewId]);

  const [index, setIndex] = useState(0);
  const getReview = () => {
    if (reviewId) {
      const defaultReview = reviews.find((r) => r.id === reviewId);
      if (defaultReview) {
        const defaultIndex = reviews.indexOf(defaultReview);
        setIndex(defaultIndex);
        return defaultReview;
      }
    }

    if (index >= reviews.length) {
      const newIndex = reviews.length - 1;
      setIndex(newIndex);
      return reviews[newIndex];
    }

    return reviews[index];
  };
  const review = getReview();

  const { data: revieweeAccount, isFetching: isFetchingRevieweeAccount } = useAccount(review.reviewee);
  const isSelfReview = revieweeAccount._id === loggedUserId;
  const revieweeName = isSelfReview
    ? 'yourself'
    : commonUtils.shortenFullName(revieweeAccount.name);

  if (isFetchingRevieweeAccount) {
    return (
      <ReviewsDueHeader
        index={index}
        setIndex={setIndex}
        maxIndex={reviews.length}
        revieweeName={revieweeName}
      />
    );
  }

  const COMPANY_QUESTIONS = company.questions;
  const { managerId } = commonTreeUtils.findNodeById(tree, loggedUserId);
  const questionObject = commonQuestions.getQuestion(
    review.questionId,
    COMPANY_QUESTIONS.QUESTIONS
  );

  const submit = async (
    score,
    comments,
    anonymous,
    questionObj,
    answer,
    shareComment,
    timestamp,
    isNA = false
  ) => {
    const data = {
      reviewId: review.id,
      revieweeId: revieweeAccount._id,
      score,
      answer,
      comments: comments && comments.toString(),
      selectedMC: null,
      questionId: review.questionId.toString(),
      categoryId: review.categoryId.toString(),
      roleId: review.roleId.toString(),
      anonymous,
      shareWith: shareComment,
      timestamp,
      isNA
    };
    const result = await submitReview(data);
    queryClient.invalidateQueries([REVIEWS_QUERY_KEYS.REVIEWS_DUE]);
    queryClient.invalidateQueries('reviews');
    queryClient.invalidateQueries('userScores');

    if (!result || !result.success) {
      toast.error('Uh oh, we ran into an issue. Please try again later!');
    }
    toast.show('Thank you!');
  };

  return (
    <Fragment>
      <ReviewsDueHeader
        index={index}
        setIndex={setIndex}
        maxIndex={reviews.length}
        revieweeName={revieweeName}
      />
      <Base classes={STYLE.BASE}>
        <ReviewForm
          isCompanyActive
          revieweeName={revieweeAccount.name}
          revieweeTitle={revieweeAccount.title}
          imageUrl={revieweeAccount.imageUrl}
          email={revieweeAccount.email}
          submitReview={submit}
          loggedUserEmail={loggedUser.email}
          isReviewingManager={managerId === revieweeAccount._id}
          questionObject={questionObject}
          question={commonReviewUtils.filloutReviewQuestion(
            isSelfReview
              ? questionObject.questionYourself
              : questionObject.question,
            revieweeAccount.name,
            company
          )}
          loading={isSubmitReviewLoading}
          options={{
            showNA: true
          }}
          companyFrequency={company.emailFrequency}
          userId={revieweeAccount._id}
        />
      </Base>
    </Fragment>
  );
};

export default ReviewsDue;
