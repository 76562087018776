import { h, Fragment } from 'preact';
import { useState } from 'preact/hooks';
import { route } from 'preact-router';
import {
  Button, Base, Circle, toast
} from 'src/components';
import { useTree } from 'src/queries/tree';
import commonTreeUtils from 'common/commonTreeUtils';
import STYLE from 'src/constants/style';
import { useReviewsV2 } from 'src/queries/reviews';
import commonQuestions from 'common/commonQuestions';
import { useCompany } from 'src/queries/company';
import commonDateUtils from 'common/commonDateUtils';
import DeleteReviewModal from 'src/components/HistoricReviews/DeleteReviewModal';
import commonReviewUtils from 'common/commonReviewUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import appUtils from 'src/components/appUtils';
import InformationCircleSVG from 'src/assets/svg/informationCircle.svg';
import { isNumber } from 'lodash';
import { useAccount } from 'src/queries/account';
import commonPermissions from 'common/commonPermissions';

const { REVIEW_ANSWER_TYPE, ACCESS } = COMMON_CONSTANTS;

const ReviewDetails = ({ parentProps: { reviewId } }) => {
  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: reviews,
    isFetching: isFetchingReviews,
    isError: isErrorReviews
  } = useReviewsV2(
    {
      ids: [reviewId]
    },
    {
      page: {
        size: 1
      },
      include: {
        reviewer: true,
        reviewee: true
      }
    }
  );

  const isFetching = isFetchingCompany
    || isFetchingReviews
    || isFetchingTree
    || isFetchingLoggedAccount;
  const isError = isErrorCompany || isErrorReviews || isErrorTree || isErrorLoggedAccount;
  const isReady = tree
    && tree.id
    && company
    && company.id
    && company.questions
    && !isFetching
    && !isError;

  if (!isReady) {
    return null;
  }

  const [showDeleteReviewModal, setShowDeleteReviewModal] = useState(false);
  const isAdmin = loggedAccount.access === ACCESS.ADMIN;
  const [review] = reviews;
  const { reviewer, reviewee } = review;

  const { sentiment, score } = review;
  const hasScore = isNumber(score);
  const scoreValue = hasScore ? score : 'N/A';
  const hasSentiment = isNumber(sentiment.score);
  const sentimentValues = hasSentiment
    ? sentiment
    : {
      score: 'N/A',
      range: 'N/A',
      reason: 'N/A'
    };
  const role = commonQuestions.getRoleById(review.roleId, company.questions);
  const companyCategories = company.questions && company.questions.CATEGORIES;
  const catObj = commonQuestions.getCategory(
    review.categoryId,
    companyCategories
  );
  const COMPANY_QUESTIONS = company.questions;
  const questionObj = commonQuestions.getQuestion(
    review.questionId,
    COMPANY_QUESTIONS.QUESTIONS
  );
  const filledQuestion = commonReviewUtils.filloutReviewQuestion(
    questionObj.question,
    reviewee.name,
    company
  );
  const isFreeText = questionObj.answers.type === REVIEW_ANSWER_TYPE.FREE_TEXT;

  const previousUrl = document.referrer;

  const handleRedirect = () => {
    const isMyProfile = reviewee._id === loggedAccount._id;

    if (previousUrl.includes(`${process.env.URL}`)) {
      return window.history.back();
    }

    if (reviewee._id === reviewer._id || isAdmin) {
      return route(`/dashboard/me/participation`);
    }

    if (isMyProfile) {
      return route(appUtils.getDashRoute());
    }

    return route(appUtils.getDashRoute(reviewee._id));
  };

  const editReview = () => {
    const url = `/dashboard/reviews/edit/${reviewId}`;
    return route(url);
  };

  const reviewerName = reviewer.name;
  const revieweeName = reviewee.name;

  const isAbove = commonTreeUtils.isNodeDirectlyAbove(
    tree,
    reviewee._id,
    loggedAccount._id
  );
  const canManageAccount = commonPermissions.canManageAccounts(loggedAccount, [
    reviewee._id
  ]);
  if (!isAbove && !isAdmin && !canManageAccount) {
    toast.error('You do not have enough permissions to see this review');
    route(appUtils.getDashRoute());
  }

  return (
    <Base
      variant='transparent'
      classes={STYLE.BASE}
      loading={isFetchingReviews}
    >
      <Base classes={`${STYLE.CONTAINER_WHITE_PADDINGLESS}`}>
        {showDeleteReviewModal ? (
          <DeleteReviewModal
            className='mt-48'
            close={() => setShowDeleteReviewModal(false)}
            data={review}
          />
        ) : null}
        <div>
          <div className='h-32 -mb-18 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-t-md' />
          <div className='pb-6 flex flex-col items-center'>
            <Circle
              size='xxl'
              imageUrl={reviewee.imageUrl}
              classes='mb-4 user-setting-profile-image relative h-131 w-131'
            />
            <div className='text-center'>
              <div className='text-2xl bold'>{reviewee.name}</div>
              <div className='text-base text-gray-400'>{reviewee.title}</div>
            </div>
          </div>
        </div>
        <div className='p-4'>
          <div className='inline-block w-2/5 align-top text-left pl-2'>
            <h5 className='text-black focus:outline-none text-2xl mr-5 mb-2 inline-block font-bold mt-2'>
              Review Summary
            </h5>
          </div>
        </div>
        <div className='inline-block w-4/5 pr-16 pl-6 py-4'>
          <div className='flex justify-between'>
            <p className='mb-0 font-bold text-base text-gray-500'>REVIEWER</p>
            <div className='pl-8 pr-6 w-4/6'>
              <p>{reviewerName}</p>
            </div>
          </div>
        </div>
        <div className='inline-block w-4/5 pr-16 pl-6 py-4'>
          <div className='flex justify-between'>
            <p className='mb-0 font-bold text-base text-gray-500'>REVIEWEE</p>
            <div className='pl-8 pr-6 w-4/6'>
              <p>{revieweeName}</p>
            </div>
          </div>
        </div>
        <div className='inline-block w-4/5 pr-16 pl-6 py-4'>
          <div className='flex justify-between'>
            <p className='mb-0 font-bold text-base text-gray-500'>DATE</p>
            <div className='pl-8 pr-6 w-4/6'>
              <p>
                {commonDateUtils.dateToMonthDayYearTimeFormat(
                  new Date(review.reviewedDate)
                )}
              </p>
            </div>
          </div>
        </div>
        <div className='inline-block w-4/5 pr-16 pl-6'>
          <div className='flex justify-between py-4'>
            <p className='mb-0 font-bold text-base text-gray-500'>QUESTION</p>
            <div className='pl-8 pr-6 w-4/6'>
              <p>{filledQuestion}</p>
            </div>
          </div>
          <div className='flex justify-between py-4'>
            <p className='mb-0 font-bold text-base text-gray-500'>ROLE</p>
            <div className='pl-8 pr-6 w-4/6'>
              <p>{role.label}</p>
            </div>
          </div>
          <div className='flex justify-between py-4'>
            <p className='mb-0 font-bold text-base text-gray-500'>CATEGORY</p>
            <div className='pl-8 pr-6 w-4/6'>
              <p>{catObj.label}</p>
            </div>
          </div>
          {!isFreeText ? (
            <Fragment>
              <div className='flex justify-between py-4'>
                <p className='mb-0 font-bold text-base text-gray-500'>SCORE</p>
                <div className='pl-8 pr-6 w-4/6'>
                  <p>{scoreValue}</p>
                </div>
              </div>
              <div className='flex justify-between py-4'>
                <p className='mb-0 font-bold text-base text-gray-500'>
                  REVIEW ANSWER
                </p>
                <div className='pl-8 pr-6 w-4/6'>
                  <p>{review.answer}</p>
                </div>
              </div>
            </Fragment>
          ) : null}
          <div className='flex justify-between py-4'>
            <div className='mb-0 font-bold text-base text-gray-500'>
              SENTIMENT
              <div className='tooltip w-5 h-5 inline-block ml-2 align-middle'>
                <span className='bg-black text-white ml-6 -mt-8 tooltip-text w-20rem'>
                  Positive/negative designation is based on sentiment analysis
                  of each feedback comment from Negative (-100) to Positive
                  (+100)
                </span>
                <InformationCircleSVG />
              </div>
            </div>
            <div className='pl-8 pr-6 w-4/6'>
              <p>{`${sentimentValues.score}, ${sentimentValues.range}`}</p>
            </div>
          </div>
          <div className='flex justify-between py-4'>
            <p className='mb-0 font-bold text-base text-gray-500'>
              SENTIMENT REASON
            </p>
            <div className='pl-8 pr-6 h-auto w-4/6'>
              <p>{sentimentValues.reason}</p>
            </div>
          </div>

          <div className='flex justify-between py-4'>
            <p className='mb-0 font-bold text-base text-gray-500'>COMMENTS</p>
            <div className='pl-8 pr-6 w-4/6 overflow-y-auto'>
              <p>{review.comments}</p>
            </div>
          </div>
        </div>
        <div className='mt-4 p-4'>
          <div className='inline-block'>
            <div className='inline-block mr-3'>
              <Button onClick={editReview} variant='purple'>
                <span className='text-lg font-bold'>Edit</span>
              </Button>
            </div>
            <div className='inline-block'>
              <Button
                onClick={() => setShowDeleteReviewModal(true)}
                variant='white'
              >
                <span className='text-lg font-bold'>Delete</span>
              </Button>
            </div>
          </div>
          <div className='inline-block float-right'>
            <Button onClick={handleRedirect} variant='yellow' disabled={false}>
              Close
            </Button>
          </div>
        </div>
      </Base>
    </Base>
  );
};

export default ReviewDetails;
