import { h } from 'preact';
import './SummaryAICard.scss';

const SummaryAICardV3 = ({ strings = [] }) => {
  let summaryText = '';
  const summaryIndex = strings.findIndex((str) => str === 'Summary');
  if (summaryIndex !== -1) {
    summaryText = strings[summaryIndex + 1];
  }

  let recommendations = [];
  const recommendationsIndex = strings.findIndex(
    (str) => str === 'Recommendations'
  );
  if (recommendationsIndex !== -1) {
    recommendations = strings.slice(recommendationsIndex + 1);
  }

  return (
    <div className='flex flex-col gap-4'>
      <div>
        <h2 className='text-lg font-bold text-black'>Summary</h2>
        <div className='text-md text-bluish-gray'>{summaryText}</div>
      </div>
      <div>
        <h2 className='text-lg font-bold text-black'>Recommendations</h2>
        {recommendations.map((r, index) => (
          <div className='text-md text-bluish-gray my-0.5'>{`${index + 1}. ${r}`}</div>
        ))}
      </div>
    </div>
  );
};

export default SummaryAICardV3;
