import { h, Fragment } from 'preact';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { route } from 'preact-router';
import { useState, useContext } from 'preact/hooks';
import { useQueryClient } from 'react-query';
import {
  Button,
  Circle,
  NewInput,
  toast,
  Base,
  Multiselect,
  Select,
  SimpleDatePicker,
  Tooltip
} from 'src/components';
import appUtils from 'src/components/appUtils';
import { NewUserContext } from 'src/containers/NewUser/context/NewUserContext';
import { uploadImageQ } from 'src/queries/user';
import { useAccountCreate, useAccounts, useAccount } from 'src/queries/account';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import profileUtils from 'src/containers/NewUser/Profile/utils';
import { useCompany } from 'src/queries/company';
import SelectGroups from 'src/containers/NewUser/Profile/SelectGroups';
import { QUERY_KEYS } from 'src/queries/score';
import { timezoneOptions } from 'src/components/SelectTimezone/SelectTimezone';
import { useTree } from 'src/queries/tree';
import commonUtils from 'common/commonUtils';
import commonDateUtils from 'common/commonDateUtils';
import sharedUtils from 'src/common/sharedUtils';
import STYLE from 'src/constants/style';
import sharedDateUtils from 'src/common/sharedDateUtils';

const { ACCESS, USER_STATE } = COMMON_CONSTANTS;

const activeOptions = [
  { id: 1, label: USER_STATE.ACTIVE },
  { id: 2, label: USER_STATE.INACTIVE },
  { id: 3, label: USER_STATE.PASSIVE }
];
const accessOptions = [
  { id: ACCESS.BASIC, label: ACCESS.BASIC },
  { id: ACCESS.MANAGER, label: ACCESS.MANAGER },
  { id: ACCESS.ADMIN, label: ACCESS.ADMIN }
];
const canLoginOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

const Profile = () => {
  const queryClient = useQueryClient();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: myAccount,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');

  const {
    data: { tree } = {},
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingCompany || isFetchingTree || isFetchingAccount;
  const isError = isErrorCompany || isErrorTree || isErrorAccount;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const {
    state: { newData, sendInviteImmediately, isDataSet },
    updateContext
  } = useContext(NewUserContext);

  if (!isDataSet) return null;

  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(null);

  const updateData = (key, value) => {
    updateContext({
      newData: {
        ...newData,
        [key]: value
      }
    });
  };

  const selectDate = (date) => {
    setStartDate(sharedDateUtils.dateToUnix(date));
  };

  const loggedUser = appUtils.getLoggedUser();
  const isAdmin = loggedUser.access === ACCESS.ADMIN;
  const isManager = loggedUser.access === ACCESS.MANAGER;
  const isAdminOrManager = isAdmin || isManager;
  const canChangeAccess = isAdmin && loggedUser.email !== newData.email;
  const companyQuestions = get(company, 'questions', null);
  const parsedNewStartDate = startDate
    ? commonDateUtils.unixToDateWithTimezone(startDate)
    : null;

  const roleOptions = sharedUtils.getRoleOptions(
    companyQuestions,
    newData.roles
  );

  const idsUnderAccount = commonTreeUtils.getIdsUnderNode(
    tree,
    isAdmin ? tree.id : myAccount._id
  );

  const { create: createAccount, isLoading: isAccountCreateLoading } = useAccountCreate();

  const { data: managerList, isFetching: isFetchingManagerList } = useAccounts(
    {
      ids: [isAdmin ? tree.id : myAccount._id, idsUnderAccount],
      status: [USER_STATE.ACTIVE, USER_STATE.PASSIVE, USER_STATE.INACTIVE]
    },
    {
      page: {
        size: 10
      },
      search: {
        enabled: true,
        field: 'name',
        value: searchText
      }
    }
  );

  const managerOptions = managerList.map((account) => ({
    value: account._id,
    label: account.name,
    data: account
  }));

  const { mutateAsync: uploadImage } = uploadImageQ();
  const onUpload = async (e) => {
    if (!e.target.files.length) {
      return;
    }
    const formData = new FormData();
    formData.append('profileImage', e.target.files[0]);
    formData.append('email', newData.email);

    const result = await uploadImage(formData);

    if (!result || !result.success) {
      return toast.error(
        'We encountered an issue uploading this image. Try again!'
      );
    }
    const { imageUrl: resultImageUrl } = result;
    updateData('imageUrl', resultImageUrl);
  };

  const createAction = async () => {
    try {
      const { email } = newData;
      if (!commonUtils.isEmailValid(email)) {
        return toast.error('Please enter a valid email address');
      }

      toast.show('Creating user...');

      commonTreeUtils.trimNodeAttributes(newData);

      const {
        companyid,
        firstName,
        lastName,
        title,
        roles,
        groups,
        websiteLink,
        access,
        status,
        permissions,
        imageUrl,
        managerId,
        managerEmail
      } = newData;

      const data = {
        companyid,
        firstName,
        lastName,
        email,
        access,
        title,
        status,
        groups,
        roles,
        startDate,
        websiteLink,
        imageUrl,
        permissions
      };

      const nodeData = {
        ...data,
        // node only attributes
        name: commonUtils.getFullName(firstName, lastName),
        firstName: undefined,
        lastName: undefined,
        status: undefined,
        managerId,
        managerEmail,
        order: 1,
        children: [],
        active: status,
        tz: -300
      };
      data.nodeData = nodeData;

      let res = null;
      try {
        res = await createAccount({
          data,
          options: {
            sendInviteImmediately
          }
        });
      } catch (error) {
        return toast.error(error);
      }

      if (!res || !res.success) {
        return toast.error(
          "We've encountered an error updating this user. Please try again or contact us."
        );
      }

      const {
        data: { createdAccount }
      } = res;

      queryClient.invalidateQueries('account');
      queryClient.invalidateQueries('myAccount');
      queryClient.invalidateQueries('accounts');
      queryClient.invalidateQueries('companyTree');
      queryClient.invalidateQueries('events');
      queryClient.invalidateQueries('reviews');
      queryClient.invalidateQueries(QUERY_KEYS.SCORE);
      queryClient.invalidateQueries('users');
      if (sendInviteImmediately) {
        toast.show(`User created and invited!`);
      } else toast.show('User created!');

      toast.show('Please assign their review relationships');
      route(
        `/dashboard/profile/${createdAccount._id}/information/review-relationships`
      );
    } catch (error) {
      return toast.error(error);
    }
  };

  const managerData = managerList.find((m) => m._id === newData.managerId);

  const isSaveDisabled = profileUtils.isSaveDisabled(newData);

  return (
    <Base
      classes={STYLE.CONTAINER_LIGHT_GRAY_PADDINGLESS_MARGINLESS}
      loading={isAccountCreateLoading}
    >
      <div className='pt-5 flex flex-col items-center'>
        <div className='mt-4 pb-6'>
          <Circle onUpload={onUpload} imageUrl={newData.imageUrl} />
        </div>
        <div className='mb-6'>
          <NewInput
            variant='white'
            label='First Name *'
            inputWidthClass='w-80'
            placeholder='First Name'
            onChange={(e) => updateData('firstName', e.target.value)}
            value={newData.firstName}
            labelClass='min-w-44'
          />
        </div>
        <div className='mb-6'>
          <NewInput
            variant='white'
            label='Last Name *'
            inputWidthClass='w-80'
            placeholder='Last Name'
            onChange={(e) => updateData('lastName', e.target.value)}
            value={newData.lastName}
            labelClass='min-w-44'
          />
        </div>
        <div className='mb-6'>
          <NewInput
            variant='white'
            inputWidthClass='w-80'
            onChange={(e) => updateData('email', e.target.value)}
            label='Email *'
            labelClass='min-w-44'
            placeholder='Email'
            value={newData.email}
          />
        </div>
        <div className='mb-6'>
          <NewInput
            variant='white'
            inputWidthClass='w-80'
            label='Title *'
            placeholder='Title'
            onChange={(e) => updateData('title', e.target.value)}
            labelClass='min-w-44'
            value={newData.title}
          />
        </div>

        <div className='mb-6 items-center'>
          <span className='inline-block min-w-44 text-left'>Role(s) *</span>
          <div className='inline-block'>
            <Multiselect
              classes='w-80'
              multiSelectSelectedItemClasses='w-8.5rem'
              options={roleOptions}
              dataCy='role-selector'
              placeholder='Select roles'
              other={{
                variant: 'sm',
                size: 300
              }}
              multiselect
              onChange={(option, type) => {
                if (type === 'select') {
                  const newRoles = option.checked
                    ? newData.roles.filter((r) => r !== option.id)
                    : [...newData.roles, option.id];
                  updateData('roles', newRoles);
                }
              }}
            />
            <a
              className='block italic underline underline-offset-2 mt-2 mb-0 text-left'
              href='https://home.workstory.team/faq/creating-new-role'
              target='_blank'
              rel='noreferrer'
            >
              How to create a new role
            </a>
          </div>
        </div>

        {loggedUser.access === ACCESS.ADMIN ? (
          <div className='mb-6 items-center'>
            <SelectGroups />
          </div>
        ) : null}
        <div className='mb-6 flex items-center'>
          <div>
            <span className='inline-block min-w-44 text-left pr-3'>
              Reports to *
            </span>
            <div className='inline-block'>
              <Select
                classes='w-80 self-center'
                placeholder='Manager *'
                options={managerOptions}
                title={managerData ? managerData.name : newData?.managerName}
                onChange={({ data }) => {
                  updateContext({
                    newData: {
                      ...newData,
                      managerId: data._id,
                      managerEmail: data.email,
                      managerName: data.name
                    }
                  });
                }}
                loading={isFetchingManagerList}
                showSearch
                onSearch={(value) => setSearchText(value)}
                onDropdownClose={() => setSearchText('')}
                disabled={company.demo}
              />
            </div>
          </div>
        </div>
        <div className='mb-6'>
          <NewInput
            variant='white'
            inputWidthClass='w-80'
            labelClass='min-w-44'
            label='Website link'
            placeholder='Website link'
            onChange={(e) => updateData('websiteLink', e.target.value)}
            value={newData.websiteLink}
          />
        </div>

        <div className='mb-6'>
          <span className='inline-block min-w-44 text-left pr-3'>
            Employee start date
          </span>
          <div className='inline-block w-80'>
            <SimpleDatePicker
              date={parsedNewStartDate}
              onSelect={selectDate}
              inputClasses='bg-white w-full'
              properties={{
                dropdowns: {
                  minYear: 1950,
                  years: true,
                  months: true
                }
              }}
              disabled={!isAdminOrManager}
              placeholder='Date Picker'
            />
          </div>
        </div>

        <div className='mb-6'>
          <span className='inline-block min-w-44 text-left pr-3'>Access *</span>
          {canChangeAccess ? (
            <div className='inline-block w-80'>
              <Select
                options={accessOptions}
                title={newData.access}
                onChange={(option) => updateData('access', option.id)}
              />
            </div>
          ) : (
            <div className='inline-block w-80'>
              <NewInput
                type='text'
                classes='inline-block w-full'
                disabled
                variant='white'
                value={newData.access}
              />
              <a
                target='_new'
                className='marginLeft20 marginTop5 text-right block marginBottom0 blue linkBlue'
                href='https://home.workstory.team/faq/changing-user-status'
              >
                Learn More
              </a>
            </div>
          )}
        </div>

        {isAdmin || isManager ? (
          <Fragment>
            <div className='mb-6'>
              <div className='min-w-44 inline-block text-left align-top'>
                <span className='inline-block mr-2 text-left'>Status *</span>
                <Tooltip>
                  <span
                    className='text-white bg-black ml-5 tooltip-text'
                    dangerouslySetInnerHTML={{
                      __html: `<div class="text-left pb-1 text-sm">Change a user's status to enable
                      <br />or prevent review scheduling.</div>`
                    }}
                  />
                </Tooltip>
              </div>
              <div className='inline-block w-80'>
                {isAdminOrManager ? (
                  <Select
                    options={activeOptions}
                    title={newData.status}
                    dataCy='active-select'
                    onChange={(option) => updateData('status', option.label)}
                  />
                ) : (
                  <NewInput
                    type='text'
                    classes='inline-block w-full'
                    disabled
                    variant='white'
                    value={newData.status}
                  />
                )}
                <a
                  className='block italic underline underline-offset-2 mt-2 mb-0 text-left'
                  href='https://home.workstory.team/faq/changing-user-status'
                  target='_blank'
                  rel='noreferrer'
                >
                  See user status FAQ to learn more
                </a>
              </div>
            </div>
            <div className='mb-6'>
              <div className='min-w-44 inline-block text-left'>
                <span className='inline-block text-left'>User can login *</span>
                <Tooltip>
                  <span
                    className='text-white ml-5 bg-black tooltip-text'
                    dangerouslySetInnerHTML={{
                      __html: `<div class="text-left pb-1 text-sm">Determines whether the user can
                      <br />login to the platform and see their
                      <br />own dashboard.</div>`
                    }}
                  />
                </Tooltip>
              </div>
              <div className='inline-block w-80'>
                {isAdminOrManager ? (
                  <Select
                    options={canLoginOptions}
                    title={
                      canLoginOptions.find(
                        (opt) => newData.permissions.canLogin === opt.value
                      ).label
                    }
                    onChange={(option) => updateData('permissions', {
                      canLogin: option.value
                    })}
                  />
                ) : (
                  <NewInput
                    type='text'
                    classes='inline-block w-full'
                    disabled
                    variant='white'
                    value={
                      canLoginOptions.find(
                        (opt) => newData.permissions.canLogin === opt.value
                      ).label
                    }
                  />
                )}
              </div>
            </div>
          </Fragment>
        ) : null}

        {(newData.access === ACCESS.MANAGER
          || newData.access === ACCESS.ADMIN) && (
          <div className='my-4 mx-auto max-w-27rem text-left'>
            <div className='bg-red-warning mx-auto p-3 rounded inline-block'>
              {newData.access === ACCESS.MANAGER
                ? `${
                  newData.name || 'This user'
                } will have access to historic review information for anyone on their team`
                : `${
                  newData.name || 'This user'
                } will have access to historic review information for anyone in the organization`}
            </div>
          </div>
        )}

        {isAdminOrManager ? (
          <div className='block'>
            <label className='block mb-5'>
              <input
                className='mx-auto w-4 h-4 mt-1 mb-0 inline-block'
                type='checkbox'
                checked={sendInviteImmediately}
                onChange={() => updateContext({
                  sendInviteImmediately: !sendInviteImmediately
                })}
              />
              <p className='inline-block ml-4 mb-0'>Send invite immediately</p>
            </label>
            <div className='tooltip text-center'>
              {company.demo ? (
                <span className='tooltip-text bg-black text-white -mt-10 -ml-3'>
                  Disabled in Demo mode
                </span>
              ) : null}
              <Button
                variant='yellow'
                onClick={createAction}
                disabled={isSaveDisabled || company.demo}
              >
                Create User
              </Button>
            </div>
          </div>
        ) : null}

        {loggedUser && loggedUser.isSuperUser ? (
          <Base classes='p-2 mt-4 text-center'>
            <h5>Superuser view:</h5>
            <p>
              User's timezone offset is
              <b>
                {timezoneOptions.find(
                  (o) => o.id === newData.tz || o.timezone === newData.timezone
                ).id / 60}
              </b>
            </p>
          </Base>
        ) : null}
      </div>
    </Base>
  );
};

export const mapStateToProps = (state) => ({
  app: state.appReducer
});

export const mapDispatchToProps = (dispatch) => ({
  reduxDispatch: dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
