import { h, Component } from 'preact';
import { useContext } from 'preact/hooks';
import { toast } from 'src/components';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';

class ErrorBoundary extends Component {
  state = { error: null };

  builderContext = PerformanceBuilderContext;

  static getDerivedStateFromError(error) {
    return { error: error.message };
  }

  componentDidCatch(error) {
    console.error(error);
    const contextProps = useContext(this.builderContext);

    this.setState({ error: error.message });
    contextProps.resetData();
    toast.error('We ran into an error, please try again.');

    // return setTimeout(() => window.location.reload(), 1200);
  }

  render() {
    if (this.state.error) {
      return <div></div>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
