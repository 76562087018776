import { h } from 'preact';
import XCircleSVG from 'src/assets/svg/x-circle.svg';

const XButton = ({
  onClick, centered = false, classes, ...rest
}) => (
  <button
    onClick={onClick}
    className={`w-5 h-5 shrink-0 grow-0 text-purple hover:text-hover-purple focus:outline-none ${centered ? 'm-auto' : 'pt-2 ml-1'} ${classes ?? ''}`}
    {...rest}
  >
    <XCircleSVG />
  </button>
);

export default XButton;
