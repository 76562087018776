import { h } from 'preact';
import { useContext, useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import {
  Base, ConnectToSlack, toast, Button
} from 'src/components/';
import appUtils from 'src/components/appUtils';
import { useCompany } from 'src/queries/company';
import { useTree } from 'src/queries/tree';
import COMMON_CONSTANTS from 'common/commonConstants';
import { SettingsContext } from 'src/pagesDashboard/Settings/context/SettingsProvider';
import { connectSlackQuery, connectMSTeamsQuery } from 'src/queries/user';
import { AppContext } from 'src/app/context/AppProvider';
import STYLE from 'src/constants/style';
import { useAccount } from 'src/queries/account';

const { SLACK_REDIRECT_URI } = COMMON_CONSTANTS;

const SlackIntegration = () => {
  const { globalContext } = useContext(AppContext);
  const { context, dispatch } = useContext(SettingsContext);
  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount,
    refetch: refetchAccount
  } = useAccount('me');
  const { mutateAsync: connectSlack, isLoading: connectSlackLoading } = connectSlackQuery();

  const isFetching = isFetchingCompany || isFetchingAccount || isFetchingTree;
  const isError = isErrorAccount || isErrorCompany || isErrorTree;
  const isReady = myTreeRow
    && myTreeRow.id
    && account
    && account._id
    && company
    && company.id
    && !isFetching
    && !isError;

  if (!isReady) {
    return null;
  }

  const slackLoading = false;
  const isUserConnectedToSlack = account && account.slackUserId;

  const handleSlackAuth = async ({ code, state }) => {
    toast.show('Connecting to Slack, please wait');
    const redirectUrl = SLACK_REDIRECT_URI.USER_SETTINGS;

    const result = await connectSlack({ code, state, redirectUrl });
    if (!result || !result.success) {
      toast.error(result.message);
      return;
    }
    toast.show('Slack connected');
    refetchAccount();
  };

  useEffect(() => {
    // this runs when slack redirects back to workstory
    if (globalContext.matches?.code && globalContext.matches?.state) {
      const { code, state } = globalContext.matches;
      handleSlackAuth({ code, state });
    }
  }, [globalContext]);

  if (!company || !myTreeRow || isUserConnectedToSlack) {
    return null;
  }

  return (
    <Base classes={STYLE.CONTAINER_WHITE} loading={slackLoading}>
      <h5>Integration for Slack</h5>
      <p className='mb-4'>
        Receive review requests and other notifications from WorkStory directly
        through Slack
      </p>
      {!isUserConnectedToSlack ? (
        <div className='mb-3 sign-in-with-slack-btn'>
          <ConnectToSlack redirectUri={SLACK_REDIRECT_URI.USER_SETTINGS} />
        </div>
      ) : null}
    </Base>
  );
};

export default SlackIntegration;
