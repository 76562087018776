import { h } from 'preact';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Router, { route } from 'preact-router';
import appUtils from 'src/components/appUtils';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useTree } from 'src/queries/tree';
import { useAccount } from 'src/queries/account';
import commonPermissions from 'common/commonPermissions';
import commonViewPermissions from 'common/commonViewPermissions';
import { isFunction } from 'lodash';
import Button from 'src/components/Button/Button';

const { ACCESS } = COMMON_CONSTANTS;

export const TABS = {
  OVERVIEW: 'overview',
  FEEDBACK: 'feedback',
  ANALYTICS: 'analytics',
  GOALS: 'goals',
  PARTICIPATION: 'participation',
  REPORTS: 'reports',
  INFORMATION: 'information',
  AI: 'ai'
};

const renderActions = (tab, actions = []) => {
  const actionsToRender = actions.filter(
    (action) => action.tabs.includes('*') || action.tabs.includes(tab)
  );

  return (
    <div className='flex items-end justify-end gap-2 mb-2 h-[32px]'>
      {actionsToRender.map((action) => {
        if (action.hidden) return;
        if (action.component) return action.component;
        return (
          <Button
            disabled={action.disabled}
            onClick={isFunction(action.onClick) ? action.onClick : () => {}}
            variant={action.buttonVariant || 'black'}
            classes={`px-2 py-1 whitespace-nowrap h-full ${action.buttonClasses || ''}`}
            paddingless
          >
            {action.buttonText || 'Action'}
          </Button>
        );
      })}
    </div>
  );
};

const TabNavigator = ({ userId, actions = [] }) => {
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');

  const {
    showMydashboardTabActivity,
    showMydashboardTabGoals,
    showMydashboardTabInsights,
    showDashboardItems
  } = useFlags();

  const isFetching = isFetchingTree || isFetchingLoggedAccount;
  const isError = isErrorTree || isErrorLoggedAccount;
  const isReady = tree && tree.id && !isFetching && !isError;

  if (!isReady) return null;

  const loggedUser = appUtils.getLoggedUser();
  const isMyDashboard = userId === loggedUser.id;
  const isManager = loggedUser.access === ACCESS.MANAGER;
  const isAdmin = loggedUser.access === ACCESS.ADMIN;
  const isDirectlyAbove = commonTreeUtils.isNodeDirectlyAbove(
    tree,
    userId,
    loggedUser.id
  );

  const canViewScore = commonViewPermissions.canViewUserScores(
    tree,
    loggedAccount._id || loggedUser.id,
    userId
  );
  const canManageAccount = commonPermissions.canManageAccounts(loggedAccount, [
    userId
  ]);

  const canViewActivityTab = (isMyDashboard || isDirectlyAbove || isAdmin)
    && showMydashboardTabActivity
    && showDashboardItems;
  const canViewGoalsTab = showMydashboardTabGoals && showDashboardItems;
  const canViewInsightsTab = (isMyDashboard || isDirectlyAbove || isAdmin)
    && showMydashboardTabInsights
    && showDashboardItems;
  const canViewProfileTab = isMyDashboard
    || isDirectlyAbove
    || isManager
    || isAdmin
    || canManageAccount;
  const canViewAnalyticsTab = isMyDashboard || canViewScore || canManageAccount || isAdmin;
  const tabButtonStyle = (isSelected) => `flex p-2 items-center gap-1 focus:outline-none border-b-4 border-purple text-xl ${
    isSelected
      ? 'border-opacity-100 text-purple font-bold'
      : 'border-opacity-0 text-zinc-800 font-bold'
  } hover:text-purple hover:border-opacity-100 hover:font-bold transition-colors duration-200 whitespace-nowrap`;

  const baseUrl = isMyDashboard
    ? '/dashboard/me'
    : `/dashboard/profile/${userId}`;

  const currentTab = Router.getCurrentUrl()
    .split('/')
    .find((str) => Object.values(TABS).includes(str));

  return (
    <div className='w-full flex justify-between items-end place-self-end pl-4 h-52px'>
      <div className='flex gap-4'>
        {appUtils.getIsOverviewTabEnabled() ? (
          <button
            className={tabButtonStyle(currentTab === TABS.OVERVIEW)}
            onClick={() => route(`${baseUrl}/overview`)}
          >
            Overview
          </button>
        ) : null}
        {appUtils.getIsFeedbackTabEnabled() ? (
          <button
            className={tabButtonStyle(currentTab === TABS.FEEDBACK)}
            onClick={() => route(`${baseUrl}/feedback`)}
          >
            Feedback
          </button>
        ) : null}
        {canViewAnalyticsTab ? (
          <button
            className={tabButtonStyle(currentTab === TABS.ANALYTICS)}
            onClick={() => route(`${baseUrl}/analytics`)}
          >
            Analytics
          </button>
        ) : null}
        {canViewGoalsTab ? (
          <button
            className={tabButtonStyle(currentTab === TABS.GOALS)}
            onClick={() => route(`${baseUrl}/goals`)}
          >
            Goals
          </button>
        ) : null}
        {canViewActivityTab ? (
          <button
            className={tabButtonStyle(currentTab === TABS.PARTICIPATION)}
            onClick={() => route(`${baseUrl}/participation`)}
          >
            Participation
          </button>
        ) : null}
        <button
          className={tabButtonStyle(currentTab === TABS.REPORTS)}
          onClick={() => route(`${baseUrl}/reports`)}
        >
          Reviews
        </button>
        {canViewProfileTab ? (
          <button
            className={tabButtonStyle(currentTab === TABS.INFORMATION)}
            onClick={() => route(`${baseUrl}/information/profile`)}
          >
            User Profile
          </button>
        ) : null}
        {canViewInsightsTab ? (
          <button
            className={tabButtonStyle(currentTab === TABS.AI)}
            onClick={() => route(`${baseUrl}/ai`)}
          >
            Insights
          </button>
        ) : null}
      </div>
      {renderActions(currentTab, actions)}
    </div>
  );
};

export default TabNavigator;
