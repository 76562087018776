import { h, Fragment } from 'preact';
import { useState, useContext, useEffect } from 'preact/hooks';
import { useTree } from 'src/queries/tree';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { HistoricReviews } from 'src/components';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import FilterView from 'src/containers/UserProfile/FilterView/FilterView';
import WriteNoteModal from 'src/containers/UserProfile/UserFeedback/WriteNoteModal';
import TopScores from 'src/containers/UserProfile/TopScores/TopScores';
import UserChart from 'src/containers/UserProfile/UserChart/UserChart';
import Categories from 'src/containers/UserProfile/Categories/Categories';
import { useUserReviews } from 'src/pagesDashboard/UserProfile/queries';
import commonViewPermissions from 'common/commonViewPermissions';
import COMMON_CONSTANTS from 'common/commonConstants';
import ReviewCount from 'src/components/ReviewCount/ReviewCount';
import { useAccount } from 'src/queries/account';
import commonPermissions from 'common/commonPermissions';

const {
  FEEDBACK_TYPE: { FEEDBACK }
} = COMMON_CONSTANTS;

const UserFeedback = ({ userId }) => {
  const { context, updateContext } = useContext(UserProfileContext);

  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');

  const {
    data: { tree, myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const { showDashboardItems } = useFlags();

  const isFetching = isFetchingTree || isFetchingLoggedAccount;
  const isError = isErrorTree || isErrorLoggedAccount;
  const isReady = tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const {
    reviews,
    pagination: reviewsPagination,
    isLoading: isLoadingHistoricReviews
  } = useUserReviews({ userId, includeAnonymous: true, includeNA: true });

  const [view, setView] = useState(FEEDBACK);
  const [showModal, setShowModal] = useState(null);

  const currentUrl = window.location.href;

  useEffect(() => {
    if (currentUrl.includes('giveFeedback=1')) setView(FEEDBACK);
  }, [currentUrl]);

  if (
    context?.userTree
    && currentUrl.includes('giveFeedback=1')
    && showModal === null
  ) setShowModal(true);

  const selectPage = (page) => {
    updateContext({
      historicReviews: {
        ...context.historicReviews,
        page: parseInt(page, 10)
      }
    });
  };

  const canManageAccount = commonPermissions.canManageAccounts(loggedAccount, [
    userId
  ]);
  const canViewScore = commonViewPermissions.canViewUserScores(
    tree,
    myTreeRow.id,
    userId
  );

  const canRequestReview = commonViewPermissions.canRequestFeedback({
    tree,
    viewerId: loggedAccount._id,
    viewerAccess: loggedAccount.access,
    userId
  });

  return (
    <Fragment>
      {showModal ? (
        <WriteNoteModal
          view={view}
          revieweeId={context.userTree.id}
          close={() => setShowModal(false)}
        />
      ) : null}

      <FilterView
        userId={userId}
        show={['reviewers', 'roles', 'categories', 'date']}
      />
      {showDashboardItems && (canViewScore || canManageAccount) ? (
        <Fragment>
          <TopScores userId={userId} />
          <UserChart userId={userId} />
          <Categories userId={userId} />
          <HistoricReviews
            showVisibleTooltip
            userId={userId}
            reviews={reviews}
            isLoading={isLoadingHistoricReviews}
            showRequestReview={canRequestReview}
            pagination={{
              totalPages: reviewsPagination.pages,
              currentPage: reviewsPagination.current,
              selectPage
            }}
          />
          <ReviewCount userId={userId} />
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default UserFeedback;
