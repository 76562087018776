import { h } from 'preact';
import { useState } from 'preact/hooks';
import { Button, toast, appUtils } from 'src/components/';
import { useAccountUpdate } from 'src/queries/account';
import WebexIconSVG from 'src/assets/svg/webex-icon.svg';
import OnboardingBase from 'src/pagesDashboard/Onboarding/OnboardingBase';
import { route } from 'preact-router';
import commonUtils from 'common/commonUtils';
import COMMON_CONSTANTS from 'common/commonConstants';

const { COMMUNICATION_TYPES } = COMMON_CONSTANTS;

const OnboardingWebex = () => {
  const loggedUser = appUtils.getLoggedUser();

  if (!loggedUser) {
    toast.show('Please login to continue');
    return route(appUtils.getBaseUrl());
  }

  const loggedUserId = loggedUser._id || loggedUser.id;
  const { update: updateAccount, isLoading: isLoadingAccountUpdate } = useAccountUpdate(loggedUserId);

  const [webexEmail, setWebexEmail] = useState('');
  const isWebexEmailValid = commonUtils.isEmailValid(webexEmail);

  const update = async () => {
    if (!isWebexEmailValid) {
      return toast.show('Please enter a valid email');
    }

    const res = await updateAccount({
      data: {
        webexEmail
        // preferredCommunication: COMMUNICATION_TYPES.WEBEX
      }
    });

    if (!res || !res.success) {
      return toast.error(
        'We encountered an issue. Please try again or contact us.'
      );
    }

    route(`/dashboard/onboarding/complete`);
  };

  return (
    <OnboardingBase>
      <div className='w-full text-left'>
        <WebexIconSVG className='w-12 h-12 m-3' />
        <p className='text-xl font-bold m-0 text-black'>Webex Set Up</p>
        <p className='m-0'>
          Enter the email you use in your Webex account. This may be different
          from your work email.
        </p>
      </div>

      <div className='w-full text-left'>
        <div className='text-left m-0 border rounded-md p-3'>
          <p className='font-bold m-0'>
            To verify, view your profile in Webex before entering.
          </p>
          <ul className='m-0'>
            <li>1. Open Webex</li>
            <li>2. Click on your profile image</li>
            <li>3. Your Webex email will be listed</li>
          </ul>
        </div>
      </div>

      <div className='w-full text-left'>
        <p className='m-0 font-bold'>Email used in Webex *</p>
        <input
          placeholder='Enter your email'
          className='bg-white rounded-lg border border-slate-300 h-11 w-[100%]'
          value={webexEmail}
          onChange={(e) => setWebexEmail(e.target.value)}
        />
      </div>

      <div className='flex justify-between gap-4 w-full'>
        <Button
          variant='empty-with-border'
          classes='!rounded-md !w-1/2 !h-11'
          onClick={() => route(`/dashboard/onboarding/comms`)}
        >
          Previous
        </Button>
        <Button
          variant='purple'
          classes='!rounded-md !w-1/2 !h-11'
          onClick={update}
          disabled={!isWebexEmailValid || isLoadingAccountUpdate}
        >
          {isLoadingAccountUpdate ? 'Loading...' : 'Continue'}
        </Button>
      </div>
    </OnboardingBase>
  );
};

export default OnboardingWebex;
