import { h, cloneElement, Fragment } from 'preact';
import { get } from 'lodash';
import { useEffect, useContext } from 'preact/hooks';
import { route } from 'preact-router';
import { useTree } from 'src/queries/tree';
import { Select, toast, appUtils } from 'src/components';
import ProfileHeader from 'src/containers/UserProfile/Header/ProfileHeader';
import commonTreeUtils from 'common/commonTreeUtils';
import commonDateUtils from 'common/commonDateUtils';
import commonViewPermissions from 'common/commonViewPermissions';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import { STYLE } from 'src/constants/style';
import { downloadUserReviewsQuery } from 'src/pagesDashboard/UserProfile/queries';
import { useCompany } from 'src/queries/company';
import { Base } from 'src/components/index';
import COMMON_CONSTANTS from 'common/commonConstants';
import TabNavigator, { TABS } from 'src/components/TabNavigator/TabNavigator';
import BoltSVG from 'src/assets/svg/bolt.svg';
import {
  useAccount,
  useAccountUpdate,
  QUERY_KEYS as ACCOUNT_QUERY_KEYS
} from 'src/queries/account';
import { useQueryClient } from 'react-query';
import commonPermissions from 'common/commonPermissions';

const { ACCESS, USER_STATE } = COMMON_CONSTANTS;

const UserProfile = ({ props, children }) => {
  const {
    parentProps: { id: userId }
  } = props;
  const queryClient = useQueryClient();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: myAccount,
    isFetching: isFetchingMyAccount,
    isError: isErrorMyAccount
  } = useAccount('me');
  const {
    data: userAccount,
    isFetching: isFetchingUserAccount,
    isError: isErrorUserAccount
  } = useAccount(userId);
  const { context, updateContext } = useContext(UserProfileContext);
  const {
    data: { tree, myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingCompany
    || isFetchingMyAccount
    || isFetchingTree
    || isFetchingUserAccount;
  const isError = isErrorCompany || isErrorMyAccount || isErrorTree || isErrorUserAccount;
  const isReady = company
    && company.id
    && tree
    && myAccount
    && userAccount
    && !isFetching
    && !isError;

  if (!isReady) return null;
  if (userAccount.status === USER_STATE.UNASSIGNED) {
    toast.error('User is unassigned! Redirecting to home page...');
    return route(appUtils.getHomeRoute());
  }

  const { mutateAsync: getReviewsFile, isLoading: isDownloadingReview } = downloadUserReviewsQuery();
  const { update: updateAccount, isLoading: isAccountUpdateLoading } = useAccountUpdate(userId);

  const reviewUser = () => {
    if (userAccount.status === USER_STATE.INACTIVE) {
      return toast.error(
        `${userAccount && userAccount.name} is currently inactive`
      );
    }
    return route(`/dashboard/submit-feedback?revieweeId=${userId}`);
  };

  useEffect(() => {
    if (tree.id && !context.userTree) {
      const userTree = commonTreeUtils.findNodeById(tree, userId);
      // if user not found, redirect to own dashboard
      if (!userTree) {
        return route('/dashboard/organization/chart');
      }
      const managerTree = userTree.managerId
        ? commonTreeUtils.findNodeById(tree, userTree.managerId)
        : undefined;
      updateContext({ userTree, managerTree });
    }
  }, [tree]);

  useEffect(() => {
    updateContext({ userId });
  }, []);

  const canExport = commonViewPermissions.canExportUserProfileData(
    tree,
    myTreeRow.id,
    userId
  );

  const canRequestFeedback = commonViewPermissions.canRequestFeedback({
    tree,
    viewerId: myAccount._id,
    viewerAccess: myAccount.access,
    userId
  });

  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = appUtils.getLoggedUserId();
  const isAdmin = loggedUser.access === ACCESS.ADMIN;
  const isManager = loggedUser.access === ACCESS.MANAGER;
  const isAbove = commonTreeUtils.isNodeDirectlyAbove(
    tree,
    userId,
    loggedUserId
  );
  const isManagerAndAbove = isManager && isAbove;
  const canManageAccount = commonPermissions.canManageAccounts(loggedUser, [
    userId
  ]);
  const isSelf = userId === loggedUserId;
  const canCreateGoal = isAdmin || canManageAccount || isSelf || isAbove;
  const canCreateReport = isAdmin || canManageAccount || isManagerAndAbove;

  const actionOptions = [
    ...(canExport ? [{ id: 'excel', label: 'Export Data' }] : []),
    ...(canRequestFeedback
      ? [{ id: 'requestFeedback', label: 'Request Feedback' }]
      : []),
    ...(isAdmin || isManagerAndAbove
      ? [{ id: 'archive', label: 'Archive User' }]
      : [])
  ];

  const action = async (option) => {
    try {
      const isMutating = isAccountUpdateLoading
        || Boolean(
          queryClient.isMutating({
            predicate: (mutation) => {
              const firstMutationKey = get(
                mutation,
                'options.mutationKey[0]',
                null
              );
              if (!firstMutationKey) {
                console.error('UserProfile firstMutationKey is null', mutation);
              }
              return firstMutationKey === ACCOUNT_QUERY_KEYS.ACCOUNT;
            }
          })
        );
      if (isMutating) return toast.error('Please wait for the current action to complete!');

      if (option.id === 'excel') {
        const start = parseFloat(context.range.start);
        const end = context.range.end
          ? parseFloat(context.range.end)
          : commonDateUtils.getUnixDateNow();
        const dateRange = commonDateUtils.unixToRange(start, end);
        return getReviewsFile(
          `${context.userTree.name.replace(' ', '_')}-${dateRange}`
        );
      }
      if (option.id === 'requestFeedback') {
        route(`/dashboard/request-feedback?revieweeId=${userId}`);
      }
      if (option.id === 'archive') {
        const { children: childNodes } = commonTreeUtils.findNodeById(
          tree,
          userId
        );
        if (childNodes.length) {
          let message = `This user has a child user (${childNodes[0].name}). Please archive/move child users before archiving this user.`;
          if (childNodes.length > 1) {
            message = `This user has ${childNodes.length} child users. Please archive/move child users before archiving this user.`;
          }
          return toast.show(message, 8000);
        }

        const r = confirm(
          'Archiving this user will remove them from the organization. Their historic data will remain for other team members. You can unarchive them at any time from the Unassigned list in People.'
        );
        if (!r) return;

        toast.show('Archiving user...');

        await updateAccount({
          data: {
            settings: {
              isArchived: true
            }
          }
        });

        toast.show('User archived!');
        queryClient.removeQueries('account');
        queryClient.removeQueries('accounts');
        queryClient.invalidateQueries();
        route(appUtils.getDashRoute());
      }
    } catch (error) {
      return toast.error(error);
    }
  };

  const tabNavigatorActions = [
    {
      tabs: [TABS.FEEDBACK, TABS.ANALYTICS, TABS.PARTICIPATION],
      hidden: !actionOptions.length,
      component: (
        <Select
          title='Actions'
          classes='!h-full'
          wrapperClasses='!h-full'
          customVariantClasses='!px-2 !py-1'
          topTextClasses='!font-bold'
          variant='black'
          onChange={async (option) => action(option)}
          options={actionOptions}
          icon={<BoltSVG className='w-4 text-black' />}
          optionsWidth='fit'
        />
      )
    },
    {
      tabs: [TABS.FEEDBACK, TABS.ANALYTICS],
      onClick: reviewUser,
      buttonText: 'Give Feedback',
      buttonVariant: 'black'
    },
    {
      tabs: [TABS.GOALS],
      onClick: () => route(`/dashboard/goal/new/${userId}`),
      buttonText: 'Set a Goal',
      buttonVariant: 'black',
      hidden: !canCreateGoal
    },
    {
      tabs: [TABS.REPORTS],
      onClick: () => route(`/dashboard/profile/${userId}/report/new`),
      buttonText: 'Create a Review',
      buttonVariant: 'black',
      hidden: !canCreateReport
    }
  ];

  return (
    <Fragment>
      {isDownloadingReview && toast.show('Exporting data...')}
      <ProfileHeader userId={userId} />
      <div className='w-full flex justify-between items-center'>
        <TabNavigator userId={userId} actions={tabNavigatorActions} />
      </div>
      <Base classes={STYLE.BASE}>
        {cloneElement(children, {
          userId,
          options: {
            showActionBtn: true
          }
        })}
      </Base>
    </Fragment>
  );
};

export default UserProfile;
