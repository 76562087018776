import { h } from 'preact';
import { useState } from 'preact/hooks';
import { Button, Checkbox } from 'src/components';
import ChevronDownSVG from 'src/assets/svg/chevronDown.svg';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import { isEqual } from 'lodash';

const { STATUS, QUESTION_ANSWER_TYPES } = COMMON_QUESTION_CONSTANTS;

const getQuestionObject = (question) => ({
  status: STATUS.ACTIVE,
  question,
  questionYourself: question,
  questionHeaders: {
    left: '',
    right: ''
  },
  answers: {
    type: QUESTION_ANSWER_TYPES.FREE_TEXT,
    custom: []
  },
  recurring: false
});

const CategoryOption = ({
  category,
  checkedQuestions,
  setCheckedQuestions,
  setIsButtonDisabled,
  showCustomQuestion = false,
  isLoadingSaveCustomQuestion = false,
  onSaveCustomQuestion = () => {}
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCustomQuestionOpen, setIsCustomQuestionOpen] = useState(false);
  const [customQuestion, setCustomQuestion] = useState('');
  const resetCustomQuestion = () => {
    setIsCustomQuestionOpen(false);
    setCustomQuestion('');
  };

  const handlerChange = (questionId, categoryId) => {
    let newCheckedQuestions = [...checkedQuestions];
    const isQuestionSelected = newCheckedQuestions.some((cat) => isEqual(cat, { categoryId, questionId }));
    if (isQuestionSelected) {
      newCheckedQuestions = newCheckedQuestions.filter(
        (cat) => !isEqual(cat, { categoryId, questionId })
      );
    } else {
      newCheckedQuestions.push({ categoryId, questionId });
    }
    if (newCheckedQuestions.length === 0) setIsButtonDisabled(true);
    else setIsButtonDisabled(false);
    setCheckedQuestions(newCheckedQuestions);
  };

  return (
    <div>
      <div>
        <button
          className='w-full cursor-pointer flex items-center focus:outline-none justify-between'
          onClick={() => {
            if (isOpen) resetCustomQuestion();
            setIsOpen(!isOpen);
          }}
        >
          <span className='text-left inline-block'>{category.label}</span>
          <span
            className={`border-none focus:ring-0 transform ${
              isOpen ? '' : 'rotate-180'
            } inline-block focus:outline-none w-4 h-4 mr-2`}
          >
            <ChevronDownSVG />
          </span>
        </button>
      </div>
      <div className='border-b mr-2 mb-2' />

      {isOpen ? (
        <div className='pt-2'>
          {category.questionObjects.map((qObj) => (
            <div className='mb-3 text-xs'>
              <Checkbox
                id={category.id}
                classes='text-sm'
                label={qObj.question}
                onChange={() => handlerChange(qObj.id.toString(), category.id.toString())}
                value={checkedQuestions.some(
                  (cat) => cat.categoryId === category.id && cat.questionId === qObj.id
                )}
                checked={checkedQuestions.some(
                  (cat) => cat.categoryId === category.id && cat.questionId === qObj.id
                )}
              />
            </div>
          ))}
          {showCustomQuestion ? (
            <div className='mb-3'>
              <button
                disabled={isLoadingSaveCustomQuestion}
                className='text-purple text-sm font-semibold leading-4 mb-2'
                onClick={() => {
                  if (isCustomQuestionOpen) return resetCustomQuestion();
                  setIsCustomQuestionOpen(!isCustomQuestionOpen);
                }}
              >
                {isLoadingSaveCustomQuestion && 'Saving question...'}
                {!isLoadingSaveCustomQuestion
                  && isCustomQuestionOpen
                  && 'Cancel'}
                {!isLoadingSaveCustomQuestion
                  && !isCustomQuestionOpen
                  && 'Write your own question prompt'}
              </button>
              {isCustomQuestionOpen ? (
                <div className='flex flex-col gap-1'>
                  <textarea
                    value={customQuestion}
                    onChange={(e) => {
                      setCustomQuestion(e.target.value);
                    }}
                    placeholder='Write your own question prompt here'
                    className='w-full h-10 p-2 border border-mid-gray rounded-lg'
                  />
                  <div className='flex w-full justify-between items-center gap-4 mt-1'>
                    <p className='text-left text-sm italic m-0'>
                      Please use "@name" as a placeholder for team member's
                      name. Ex: How well has @name communicated with the team?
                    </p>
                    <Button
                      variant='black'
                      disabled={isLoadingSaveCustomQuestion}
                      onClick={() => {
                        const questionObject = getQuestionObject(customQuestion);
                        onSaveCustomQuestion(questionObject);
                        resetCustomQuestion();
                      }}
                    >
                      Add Question
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default CategoryOption;
