import { h } from 'preact';
import { Base, ColumnTooltip } from 'src/components';
import { useCompany } from 'src/queries/company';
import { useTree } from 'src/queries/tree';
import commonDateUtils from 'common/commonDateUtils';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import STYLE from 'src/constants/style';
import { route } from 'preact-router';

const { REPORT_TEMPLATE_TYPES_LABEL } = COMMON_CONSTANTS;

const ReportTemplatesSection = () => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: { tree, deleted },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingCompany || isFetchingTree;
  const isError = isErrorCompany || isErrorTree;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const goToReportTemplateCreatePage = () => route('/dashboard/reportTemplate/add');
  const goToReportTemplateEditPage = (templateId) => route(`/dashboard/reportTemplate/edit/${templateId}`);

  return (
    <Base
      classes={STYLE.CONTAINER_WHITE}
      loading={isFetchingCompany || isFetchingTree}
    >
      <div className='flex justify-between'>
        <div className='text-left'>
          <h5 className='mb-2'>Report Templates</h5>
        </div>
        <div className='text-right'>
          <button
            onClick={() => goToReportTemplateCreatePage()}
            className='text-purple mr-2 text-lg font-bold'
          >
            Add New Template
          </button>
        </div>
      </div>

      <p>Manage templates.</p>

      <div className='mb-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm'>
        <p className='mb-0 pl-1 w-1/12 inline-block font-bold'>Type</p>
        <p className='mb-0 pl-1 w-4/12 inline-block font-bold'>Name</p>
        <p className='mb-0 w-2/12 inline-block font-bold'>Created by</p>
        <p className='mb-0 w-2/12 inline-block font-bold'>Created on</p>
        <p className='mb-0 w-2/12 inline-block font-bold'>Updated on</p>
      </div>

      <div className='h-70'>
        {company.templates && !company.templates.length ? (
          <p className='text-center mt-8 font-bold'>No templates available</p>
        ) : (
          company.templates
          && company.templates.map((template) => {
            const authorNode = commonTreeUtils.findNodeById(tree, template.author)
              || commonTreeUtils.findNodeByIdArray(deleted, template.author);
            const authorName = authorNode.name;

            const createdDate = commonDateUtils.dateToMonthDayYearFormat(
              commonDateUtils.unixToDate(template.createdAt)
            );

            const updatedDate = template.updatedAt
              ? commonDateUtils.dateToMonthDayYearFormat(
                commonDateUtils.unixToDate(template.updatedAt)
              )
              : '-';

            return (
              <div>
                <p className='pl-2 w-1/12 inline-block'>
                  {REPORT_TEMPLATE_TYPES_LABEL[template.type]}
                </p>
                <p className='pl-2 w-4/12 inline-block'>{template.name}</p>

                <div className='w-2/12 inline-block truncate text-ellipsis pr-3'>
                  <ColumnTooltip text={authorName} />
                  <p className='m-0'>{authorName}</p>
                </div>

                <p className='w-2/12 inline-block truncate text-ellipsis pr-3'>
                  {createdDate}
                </p>
                <p className='w-2/12 inline-block truncate text-ellipsis pr-3'>
                  {updatedDate}
                </p>
                <button
                  className='hover:underline focus:outline-none w-1/12'
                  onClick={() => goToReportTemplateEditPage(template.id)}
                >
                  Edit
                </button>
              </div>
            );
          })
        )}
      </div>
    </Base>
  );
};

export default ReportTemplatesSection;
