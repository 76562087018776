import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import {
  Radio, Base, Button, toast
} from 'src/components';
import { useCompany, updateCompanyQuery } from 'src/queries/company';
import COMMON_CONSTANTS from 'common/commonConstants';
import LockClosedSVG from 'src/assets/svg/lock-closed.svg';
import STYLE from 'src/constants/style';

const { EMAIL_FREQUENCY } = COMMON_CONSTANTS;

const FREQUENCY_STEPS = [
  {
    topText: 'Two times per week',
    key: EMAIL_FREQUENCY.BIWEEKLY.value
  },
  {
    topText: 'Every week',
    key: EMAIL_FREQUENCY.WEEKLY.value
  },
  {
    topText: 'Every 10 days',
    key: EMAIL_FREQUENCY.EVERY_10_DAYS.value
  },
  {
    topText: 'Every 2 weeks (Recommended)',
    key: EMAIL_FREQUENCY.BIMONTHLY.value
  },
  {
    topText: 'Every 3 weeks',
    key: EMAIL_FREQUENCY.EVERY_3_WEEKS.value
  },
  {
    topText: EMAIL_FREQUENCY.MONTHLY.value,
    key: EMAIL_FREQUENCY.MONTHLY.value
  },
  {
    topText: EMAIL_FREQUENCY.EVERY_6_WEEKS.label,
    key: EMAIL_FREQUENCY.EVERY_6_WEEKS.value
  }
  // {
  //   topText: EMAIL_FREQUENCY.ONCE_EVERY_TWO_MONTHS.value,
  //   key: EMAIL_FREQUENCY.ONCE_EVERY_TWO_MONTHS.value,
  // },
];

const getFrequencySteps = (companyid) => {
  const steps = [...FREQUENCY_STEPS];
  if (
    [
      'dff397e4-94f0-4007-8a2d-50e59ad9a6b3',
      '5a50ea7f-c753-4cfe-a3db-bd5c256397ed',
      '9e6f3c43-678d-48e5-82e7-54b140ed765b'
    ].includes(companyid)
  ) {
    steps.push({
      topText: EMAIL_FREQUENCY.EVERY_8_WEEKS.label,
      key: EMAIL_FREQUENCY.EVERY_8_WEEKS.value
    });
    steps.push({
      topText: EMAIL_FREQUENCY.EVERY_QUARTER.label,
      key: EMAIL_FREQUENCY.EVERY_QUARTER.value
    })
  }
  return steps;
};

const ReviewFrequency = () => {
  const {
    data: company,
    refetch: refetchCompany,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const {
    mutateAsync: updateCompany,
    isLoading: updateCompanyLoading
  } = updateCompanyQuery();
  const [local, setLocal] = useState({ frequency: null });
  useEffect(() => {
    if (company) {
      setLocal({ frequency: company.emailFrequency });
    }
  }, [company]);

  const selectFrequency = (frequency) => {
    setLocal({ ...local, frequency });
  };

  const save = async () => {
    toast.show('Updating review frequency..');
    const res = await updateCompany({
      emailFrequency: local.frequency
    });
    toast.show('Saved!');
    refetchCompany();
  };

  const getDisabledTooltip = () => {
    if (!company.active) {
      return 'Company is not active';
    }
    if (company.demo) {
      return 'Disabled in demo mode';
    }
  };
  const steps = getFrequencySteps(company.id);

  return (
    <Base
      classes={`${STYLE.CONTAINER_WHITE} items-start`}
      loading={isFetchingCompany || updateCompanyLoading}
    >
      <h5 className='mb-1 font-bold inline-block'>Review Cycle</h5>
      {company.demo ? (
        <span className='ml-2 mt-1 w-5 h-5 align-top inline-block tooltip'>
          <span className='tooltip-text bg-black text-white -mt-10 -ml-16'>
            {getDisabledTooltip()}
          </span>
          <LockClosedSVG />
        </span>
      ) : null}
      <p className='mb-4'>
        Determines how often reviews are sent for each review relationship as
        well as the frequency of summary updates.
      </p>

      {steps.map((step) => (
        <div className='mb-4'>
          <Radio
            name='reviewFrequency'
            value={step.key}
            title={step.topText}
            onClick={() => {
              selectFrequency(step.key);
            }}
            disabled={company.demo || !company.active}
            checked={local.frequency === step.key}
          />
        </div>
      ))}

      <Button
        classes='mt-2 mb-1'
        onClick={save}
        variant='yellow'
        disabled={
          local.frequency === company.emailFrequency
          || updateCompanyLoading
          || isFetchingCompany
          || !company.active
          || company.demo
        }
      >
        Save
      </Button>
    </Base>
  );
};

export default ReviewFrequency;
