import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { useQueryClient } from 'react-query';
import uuidv4 from 'uuid/v4';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import commonQuestions from 'common/commonQuestions';
import { useDeleteRecord } from 'src/queries/api';
import COMMON_CONSTANTS from 'common/commonConstants';
import {
  useQuestions,
  QUERY_KEYS as COMPANY_QUERY_KEYS
} from '../../../queries/company';
import { organizationThunks } from '../../../thunks';
import X_SVG from '../../../assets/cross.svg';
import {
  SectionBox,
  Button,
  Input,
  NewInput,
  Modal,
  toast
} from '../../../components';
import appUtils from '../../../components/appUtils';
import sharedDateUtils from '../../../common/sharedDateUtils';
import SHARED_CONSTANTS from '../../../common/sharedConstants';
import sharedUtils from '../../../common/sharedUtils';
import { useTree } from '../../../queries/tree';

const { DEFAULT_ROLE, DEFAULT_CATEGORY } = COMMON_CONSTANTS;

const { EDIT_MODES } = SHARED_CONSTANTS;
const { STATUS } = COMMON_QUESTION_CONSTANTS;

// const ROLE_KEYS_NOT_EDITABLE = ['General'];
const ROLE_KEYS_NOT_EDITABLE = [];

const filterCategories = (companyCategories, roleCategories) => {
  const categories = [];
  const availableCategories = [];
  Object.keys(companyCategories).forEach((key) => {
    const categoryObj = companyCategories[key];

    if (categoryObj.id === DEFAULT_CATEGORY.id) return;

    if (roleCategories.indexOf(categoryObj.id) > -1) {
      categories.push(categoryObj);
    } else {
      availableCategories.push(categoryObj);
    }
  });

  return {
    selected: categories,
    available: availableCategories.filter((c) => c.status === STATUS.ACTIVE)
  };
};

// type = edit|add
const getInitValues = (type, role) => {
  if (type === EDIT_MODES.EDIT) {
    return {
      initName: role.label,
      initDesc: role.desc,
      initCategories: role.categories
    };
  }

  if (type === EDIT_MODES.ADD) {
    return {
      initName: '',
      initDesc: '',
      initCategories: []
    };
  }
};

const AvailableCategories = ({ available, addItem }) => {
  const [keyword, setKeyword] = useState('');
  const [filtered, setFiltered] = useState(available);

  useEffect(() => {
    setFiltered(sharedUtils.filterList(available, 'label', keyword, 20));
  }, [available]);

  const filter = (e) => {
    const list = sharedUtils.filterList(available, 'label', e.target.value, 20);
    setKeyword(e.target.value);
    setFiltered(list);
  };

  return (
    <Fragment>
      <Input value={keyword} onChange={filter} placeholder='Search' />
      {filtered.map((category) => (
        <div>
          <p className='inline-block w-2/4 mb-4'>{category.label}</p>
          <a
            onClick={(e) => addItem(e, category)}
            className='text-red inline-block w-2/4 mb-4'
          >
            Add
          </a>
        </div>
      ))}
    </Fragment>
  );
};

// type = add|edit
const NewRole = ({ close, type, role, roleKey, dispatch }) => {
  const queryClient = useQueryClient();
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: companyQuestions,
    isFetching: isFetchingCompanyQuestions,
    isError: isErrorCompanyQuestions,
    refetch
  } = useQuestions(dispatch);

  const isFetching = isFetchingCompanyQuestions || isFetchingTree;
  const isError = isErrorCompanyQuestions || isErrorTree;
  const isReady = tree && tree.id && commonQuestions && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const { initName, initDesc, initCategories } = getInitValues(type, role);
  const { selected, available } = filterCategories(
    companyQuestions.CATEGORIES,
    initCategories
  );
  const { mutateAsync: archiveRoleFn } = useDeleteRecord();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(initName);
  const [desc, setDesc] = useState(initDesc);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [categories, setCategories] = useState(selected);
  const [categoriesAvailable, setCategoriesAvailable] = useState(available);

  const isSaveDisabled = (selectedCategories, newName, newDesc) => {
    if (ROLE_KEYS_NOT_EDITABLE.indexOf(roleKey) > -1) {
      return true;
    }
    if (type === EDIT_MODES.ADD) {
      if (selectedCategories.length === 0 || !newName || newName === '') {
        return true;
      }
      return false;
    }

    // check name and description changes
    if (newName !== role.label) {
      return false;
    }
    if (newDesc !== role.desc) {
      return false;
    }

    // check category changes
    const currentCategories = role.categories;
    if (Object.keys(selectedCategories).length !== currentCategories.length) {
      return false;
    }

    let nomatch = true;
    currentCategories.forEach((id) => {
      const found = selectedCategories.find((cat) => cat.id === id);
      if (!found) {
        nomatch = false;
      }
    });
    return nomatch;
  };

  const addCategory = (e, category) => {
    e.preventDefault();
    let newAvailableCategories = [...categoriesAvailable];
    const newCategories = [...categories];
    newCategories.push(category);
    newAvailableCategories = categoriesAvailable.filter(
      (cat) => cat.id !== category.id
    );
    setCategories(newCategories);
    setCategoriesAvailable(newAvailableCategories);
    setSaveDisabled(isSaveDisabled(newCategories, name, desc));
  };

  const removeCategory = (e, category) => {
    e.preventDefault();
    const newAvailableCategories = Object.assign([], categoriesAvailable);
    let newCategories = Object.assign([], categories);
    newAvailableCategories.push(category);
    newCategories = newCategories.filter((cat) => cat.id !== category.id);
    setCategories(newCategories);
    setCategoriesAvailable(newAvailableCategories);
    setSaveDisabled(isSaveDisabled(newCategories, name, desc));
  };

  const save = () => {
    const newCategories = categories.map((cat) => cat.id);
    if (!newCategories || !newCategories.length) {
      return toast.error('Each role requires at least 1 category');
    }

    const unix = sharedDateUtils.getUnixDateNow();
    if (type === EDIT_MODES.EDIT) {
      const newCompanyQuestions = { ...companyQuestions };
      const roleObj = commonQuestions.getRoleById(role.id, companyQuestions, {
        byReference: true
      });
      roleObj.label = name;
      roleObj.desc = desc;
      roleObj.categories = newCategories;
      roleObj.lastUpdated = unix;
      roleObj.status = COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE;

      setLoading(true);
      toast.show('Updating role..');

      organizationThunks
        .saveQuestions(newCompanyQuestions)(dispatch)
        .then((resp) => {
          toast.show('Role updated!');
          appUtils.scrollToTop();
          setLoading(false);
          setSaveDisabled(true);
          refetch();
          queryClient.invalidateQueries(COMPANY_QUERY_KEYS.COMPANY);
          close();
        });
    }

    if (type === EDIT_MODES.ADD) {
      const newCompanyQuestions = JSON.parse(JSON.stringify(companyQuestions));
      if (newCompanyQuestions.ROLES[name]) {
        toast.error(`Role with name ${name} already exists`);
        return;
      }
      // const categoryList = categories.map((cat) => cat.id);
      newCompanyQuestions.ROLES[name] = {
        id: uuidv4(),
        label: name,
        desc,
        categories: newCategories,
        timestamp: unix,
        lastUpdated: unix,
        status: COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE
      };
      setLoading(true);
      toast.show('Adding new role..');
      organizationThunks
        .saveQuestions(newCompanyQuestions)(dispatch)
        .then((resp) => {
          toast.show('Role added!');
          appUtils.scrollToTop();
          setLoading(false);
          setSaveDisabled(true);
          refetch();
          queryClient.invalidateQueries(COMPANY_QUERY_KEYS.COMPANY);
          close();
        });
    }
  };

  const changeName = (e) => {
    setName(e.target.value);
    setSaveDisabled(isSaveDisabled(categories, e.target.value, desc));
  };

  const changeDesc = (e) => {
    setDesc(e.target.value);
    setSaveDisabled(isSaveDisabled(categories, name, e.target.value));
  };

  const archiveRole = async () => {
    if (!tree) {
      console.error(
        'Failed to get the tree which is a requirement' +
          'before deleting a role from the organization'
      );
      toast.show("We've encountered an issue, please try again");
      return false;
    }
    const users = commonTreeUtils.getUsersWithRole(tree, role.id);
    const userNames = users.map((user) => user.name).join(', ');
    if (users.length) {
      toast.error(
        `There are users currently assigned to this role (${userNames}). Please re-assign them to a different role before deleting this role.`
      );
      return false;
    }

    const answer = confirm(
      `Are you sure you want to delete role ${role.label}?`
    );
    if (!answer) {
      return false;
    }

    if (role.id === DEFAULT_ROLE.id) {
      return toast.show('Cannot delete default role');
    }

    const newCompanyQuestions = JSON.parse(JSON.stringify(companyQuestions));

    const roleObj = commonQuestions.getRoleById(role.id, newCompanyQuestions);
    roleObj.status = COMMON_QUESTION_CONSTANTS.STATUS.ARCHIVED;

    setLoading(true);
    toast.show('Archiving role..');

    const response = await archiveRoleFn({
      endpoint: `/questions/archive/roles`,
      data: {
        roles: [role.id]
      }
    });

    if (!response || !response.success) {
      toast.error('Oops, we ran into an issue. Try again later!');
    } else {
      toast.show('Role archived!');
    }

    refetch();
    queryClient.invalidateQueries(COMPANY_QUERY_KEYS.COMPANY);
    setLoading(false);
    setSaveDisabled(true);
    close();
  };

  const duplicateRole = () => {
    const newCompanyQuestions = JSON.parse(JSON.stringify(companyQuestions));
    let i = 1;
    let newRoleKey = `${role.key}_${i}`;
    while (newCompanyQuestions.ROLES[newRoleKey]) {
      i++;
      newRoleKey = `${role.key}_${i}`;
    }

    const unix = sharedDateUtils.getUnixDateNow();
    const newRoleObject = {
      id: uuidv4(),
      label: newRoleKey,
      desc: role.desc,
      categories: [...role.categories],
      timestamp: unix,
      lastUpdated: unix,
      status: COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE
    };
    newCompanyQuestions.ROLES[newRoleKey] = newRoleObject;
    toast.show('Duplicating role');
    setLoading(true);
    organizationThunks
      .saveQuestions(newCompanyQuestions)(dispatch)
      .then((resp) => {
        toast.show('Role duplicated');
        setLoading(false);
        setSaveDisabled(true);
        refetch();
        queryClient.invalidateQueries(COMPANY_QUERY_KEYS.COMPANY);
        close();
      });
  };

  return (
    <SectionBox
      classes='mt-2'
      innerClasses='paddingBottom0'
      loading={loading}
      sectionType='dash'
    >
      <a onClick={close} className='clear-both float-right'>
        <X_SVG class='width25px height25px' />
      </a>
      <h5 className='font-bold'>
        {type === EDIT_MODES.EDIT ? 'Edit Role' : 'Add Role'}
      </h5>

      {type === EDIT_MODES.EDIT ? (
        <a
          href='javascript:void(0)'
          onClick={() => {
            duplicateRole();
          }}
          className='blue'
        >
          Duplicate role
        </a>
      ) : null}

      <div className='mt-6'>
        <p className='inline-block w-1/5'>Role Name</p>
        {/*
        <Input
          value={name}
          onChange={changeName}
          classes="inline-block w-1/5"
          placeholder="Role Name"
        />
      */}
        <div className='inline-block w-1/5'>
          <NewInput
            value={name}
            classes='w-full'
            onChange={changeName}
            placeholder='Role Name'
          />
        </div>
      </div>
      <div className='mt-6'>
        <p className='inline-block w-1/5'>Description</p>
        <div className='inline-block w-1/5'>
          <textarea
            value={desc}
            onChange={changeDesc}
            className='resize-none w-full h-32'
            placeholder='Role Description'
          />
        </div>
      </div>
      <div className='mt-6'>
        <p className='inline-block w-1/5'>Question Categories</p>
        <div className='inline-block w-2/5'>
          <p>Selected Categories:</p>
          {categories.length === 0 ? (
            <p className='italic'>No categories selected</p>
          ) : null}
          {categories.map((category) => (
            <div>
              <p className='inline-block w-2/4 mb-4'>{category.label}</p>
              <a
                onClick={(e) => removeCategory(e, category)}
                className='text-red inline-block w-2/4 mb-4'
              >
                Remove
              </a>
            </div>
          ))}
        </div>
        <div className='inline-block w-2/5 align-top'>
          <p>Available Categories:</p>
          <AvailableCategories
            available={categoriesAvailable}
            addItem={addCategory}
          />
        </div>
      </div>

      <div className='text-center mt-16'>
        <Button disabled={saveDisabled} onClick={save}>
          Save
        </Button>
        {ROLE_KEYS_NOT_EDITABLE.indexOf(roleKey) > -1 ? (
          <p className='mt-4'>The default "General" role is not editable.</p>
        ) : null}
      </div>
      {type === 'edit' ? (
        <div className='text-left'>
          <a onClick={archiveRole}>Archive Role</a>
        </div>
      ) : null}
    </SectionBox>
  );
};

export default NewRole;
