import { useQueryClient } from 'react-query';
import { Fragment, h } from 'preact';
import {
  get, isEmpty, isNil, isEqual, uniqBy
} from 'lodash';
import { route } from 'preact-router';
import { useContext, useEffect, useState } from 'preact/hooks';
import commonQuestions from 'common/commonQuestions';
import {
  Button,
  Circle,
  NewInput,
  toast,
  Base,
  Multiselect,
  Select,
  Tooltip,
  SimpleDatePicker
} from 'src/components';
import appUtils from 'src/components/appUtils';
import { uploadImageQ } from 'src/queries/user';
import { useAccounts, useAccountUpdate, useAccount } from 'src/queries/account';
import { useTree } from 'src/queries/tree';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useCompany } from 'src/queries/company';
import { timezoneOptions } from 'src/components/SelectTimezone/SelectTimezone';
import commonUtils from 'common/commonUtils';
import sharedUtils from 'src/common/sharedUtils';
import { ProfileContext } from 'src/containers/UserProfile/Profile/ProfileContext';
import STYLE from 'src/constants/style';
import commonDateUtils from 'common/commonDateUtils';
import sharedDateUtils from 'src/common/sharedDateUtils';
import commonPermissions from 'common/commonPermissions';

const {
  ACCESS, USER_STATE, DEFAULT_ROLE, MANAGE_ACCOUNTS_MAX_LENGTH, COLORS
} = COMMON_CONSTANTS;

const BASIC_COLOR = COLORS['tertiary-gray'];
const MANAGER_COLOR = COLORS['tertiary-blue'];
const ADMIN_COLOR = COLORS['tertiary-green'];

const activeOptions = [
  { id: 1, label: USER_STATE.ACTIVE },
  { id: 2, label: USER_STATE.INACTIVE },
  { id: 3, label: USER_STATE.PASSIVE }
];
const accessOptions = [
  { id: ACCESS.BASIC, label: ACCESS.BASIC },
  { id: ACCESS.MANAGER, label: ACCESS.MANAGER },
  { id: ACCESS.ADMIN, label: ACCESS.ADMIN }
];
const canLoginOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

const SelectGroups = () => {
  const { newData, setNewData } = useContext(ProfileContext);
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) return null;

  const addGroup = (g) => {
    const ng = newData.groups ? [...newData.groups, g.id] : [g.id];
    setNewData({ ...newData, groups: ng });
  };

  const removeGroup = (g) => {
    const ng = newData.groups.filter((n) => n !== g.id);
    setNewData({ ...newData, groups: ng });
  };

  const toggleGroup = (g) => {
    const groupExists = newData.groups.find((n) => n === g.id);
    if (groupExists) {
      return removeGroup(g);
    }
    return addGroup(g);
  };

  const groups = company.groups.map((g) => ({
    ...g,
    label: g.name,
    checked: newData.groups && newData.groups.find((i) => i === g.id)
  }));

  return (
    <div>
      <span className='inline-block min-w-44 text-left pr-3'>Groups</span>
      <div className='inline-block'>
        <Multiselect
          classes='w-80'
          multiSelectSelectedItemClasses='w-8.5rem'
          options={groups}
          placeholder='Select groups'
          variant='minimal'
          onChange={(option, type) => {
            if (type === 'select') {
              return toggleGroup(option);
            }
            if (type === 'remove') {
              return removeGroup(option);
            }
          }}
        />
      </div>
    </div>
  );
};

const renderManagerWarning = (oldData, newData, managerData) => (
  <Fragment>
    {newData.managerId !== oldData.managerId ? (
      <div className='bg-red-warning mb-6 text-center mx-auto p-3 rounded inline-block max-w-32rem'>
        <p className='text-black text-sm font-bold text-left m-0'>
          Changing this user's manager does not impact review relationships.
        </p>
        <p className='text-black text-sm font-bold text-left m-0 mb-3'>
          {`Any users under ${newData.name} will also be moved.`}
        </p>
        <p className='p-0 m-0 text-sm text-left'>
          {newData.name}
          's new manager will be
          {' '}
          <strong>{managerData.name}</strong>
          .
        </p>

        <p className='p-0 m-0 text-sm text-left'>
          Changing managers will reset review permissions for this user.
        </p>
        <p className='p-0 m-0 mb-2 text-sm text-left'>
          Moving this user will also move any users underneath them.
        </p>
        <p className='p-0 m-0 text-sm text-left'>
          After making changes, please visit the
          {' '}
          <strong>Reviews</strong>
          {' '}
          tab to
          make changes if necessary.
        </p>
      </div>
    ) : null}
  </Fragment>
);

const renderRootWarning = (isNewRootSelected, selectedRoot) => (
  <Fragment>
    {isNewRootSelected ? (
      <div className='bg-red-warning mb-6 text-center mx-auto p-3 rounded inline-block'>
        <p className='text-black text-sm m-0'>
          <b>{selectedRoot.name}</b>
          {' '}
          will be the user at the top of the
          organization.
        </p>
      </div>
    ) : null}
  </Fragment>
);

const MANAGE_ACCOUNTS_MAX_USERS = 30;

const ProfileTab = () => {
  const {
    newData,
    oldData,
    managerData,
    setNewData,
    setManagerData,
    invalidateData
  } = useContext(ProfileContext);
  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');
  const {
    data: { tree } = {},
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const queryClient = useQueryClient();
  const { update: updateAccount, isLoading: isAccountUpdateLoading } = useAccountUpdate(newData._id);
  // const isMutating = isAccountUpdateLoading
  //   || Boolean(
  //     queryClient.isMutating({
  //       predicate: (mutation) => {
  //         try {
  //           const firstMutationKey = get(mutation, 'options.mutationKey[0]');
  //           if (!firstMutationKey) {
  //             console.error('ProfileTab firstMutationKey is null', mutation, {
  //               firstMutationKey,
  //               ACCOUNT_QUERY_KEYS
  //             });
  //           }
  //           return firstMutationKey === ACCOUNT_QUERY_KEYS.ACCOUNT;
  //         } catch (error) {
  //           console.error('ProfileTab catch', error);
  //           return false;
  //         }
  //       }
  //     })
  //   );
  const isMutating = isAccountUpdateLoading;

  const isFetching = isFetchingCompany || isFetchingTree || isFetchingLoggedAccount;
  const isError = isErrorCompany || isErrorTree || isErrorLoggedAccount;
  const isReady = tree && tree.id && company && company.id && !isFetching && !isError;

  if (!isReady) return null;

  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(oldData.startDate);

  const [removedRoles, setRemovedRoles] = useState([]);
  const [addedRoles, setAddedRoles] = useState([]);
  const [removedRelationships, setRemovedRelationships] = useState([]);

  const selectDate = (date) => {
    setStartDate(sharedDateUtils.dateToUnix(date));
  };

  const updateData = (key, value) => {
    setNewData({
      ...newData,
      [key]: value
    });
  };

  const idsUnderLoggedAccount = commonTreeUtils.getIdsUnderNode(
    tree,
    loggedAccount._id
  );
  const isRoot = tree.id === newData._id;
  const loggedUser = appUtils.getLoggedUser();
  const isAdmin = loggedUser.access === ACCESS.ADMIN;
  const isManager = loggedAccount.access === ACCESS.MANAGER;
  const isAbove = commonTreeUtils.isNodeDirectlyAbove(
    tree,
    newData._id,
    loggedAccount._id
  );
  const isManagerAndAbove = isManager && isAbove;
  const canManageAccount = commonPermissions.canManageAccounts(loggedAccount, [
    newData._id
  ]);
  const canUpdate = isAdmin || isManagerAndAbove || canManageAccount;
  const idsUnderAccount = commonTreeUtils.getIdsUnderNode(tree, newData._id);
  const companyQuestions = get(company, 'questions', null);
  const roleOptions = sharedUtils.getRoleOptions(
    companyQuestions,
    newData.roles
  );
  const ROLES_LIST = commonQuestions.convertCompanyRolesToList(
    companyQuestions.ROLES
  );
  const parsedNewStartDate = startDate
    ? commonDateUtils.unixToDateWithTimezone(startDate)
    : null;

  const [managerSearchText, setManagerSearchText] = useState('');
  const { data: managerList, isFetching: isFetchingManagerList } = useAccounts(
    {
      notIds: [newData._id, ...(isRoot ? [] : idsUnderAccount)],
      status: [USER_STATE.ACTIVE, USER_STATE.PASSIVE, USER_STATE.INACTIVE]
    },
    {
      page: {
        size: MANAGE_ACCOUNTS_MAX_USERS
      },
      search: {
        enabled: true,
        field: 'name',
        value: managerSearchText
      }
    }
  );
  const managerOptions = managerList.map((account) => ({
    value: account._id,
    label: account.name,
    data: account
  }));

  const { isFetching: isFetchingEmailAccount, refetch: refetchEmailAccount } = useAccounts(
    {
      notIds: [newData._id],
      emails: [newData.email]
    },
    {
      page: {
        size: 1
      }
    },
    {
      enabled: false
    }
  );

  const {
    data: currentManageAccountsList,
    isFetching: isFetchingCurrentManageAccountsList
  } = useAccounts(
    {
      ids: newData.manageAccounts
    },
    {
      page: {
        size: newData.manageAccounts.length
      }
    },
    {
      enabled: Boolean(newData.manageAccounts.length),
      keepPreviousData: Boolean(newData.manageAccounts.length)
    }
  );
  const allowedIds = [
    ...idsUnderLoggedAccount,
    ...oldData.manageAccounts
  ].filter((id) => !newData.manageAccounts.includes(id));
  const notAllowedIds = [
    newData._id,
    ...(newData.manageAccounts.length ? newData.manageAccounts : [])
  ];
  const [manageAccountsSearchText, setManageAccountsSearchText] = useState('');
  const { data: manageAccountsList, isFetching: isFetchingManageAccountsList } = useAccounts(
    {
      ...(!isAdmin && { ids: allowedIds }),
      notIds: notAllowedIds,
      notStatus: [USER_STATE.UNASSIGNED]
    },
    {
      page: {
        size: MANAGE_ACCOUNTS_MAX_USERS
      },
      search: {
        enabled: true,
        field: 'name',
        value: manageAccountsSearchText
      }
    },
    {
      keepPreviousData: true,
      enabled: Boolean(isAdmin || allowedIds.length)
    }
  );

  const fullManageAccountsList = [
    ...currentManageAccountsList,
    ...manageAccountsList
  ];
  const manageAccountsOptions = uniqBy(fullManageAccountsList, '_id').map(
    (account) => {
      let color = BASIC_COLOR;

      if (account.access === ACCESS.MANAGER) {
        color = MANAGER_COLOR;
      } else if (account.access === ACCESS.ADMIN) {
        color = ADMIN_COLOR;
      }

      return {
        id: account._id,
        label: account.name,
        checked: newData.manageAccounts.includes(account._id),
        data: account,
        color
      };
    }
  );

  const [selectedRoot, setSelectedRoot] = useState(isRoot ? newData : null);

  useEffect(() => {
    setRemovedRoles(
      oldData.roles
        .filter((r) => !newData.roles.includes(r))
        .map((r) => ({
          id: r,
          label: ROLES_LIST.find((t) => t.id === r)?.label
        }))
    );
    setAddedRoles(
      newData.roles
        .filter((r) => !oldData.roles.includes(r))
        .map((r) => ({
          id: r,
          label: ROLES_LIST.find((t) => t.id === r)?.label
        }))
    );
  }, [newData.roles.length]);

  useEffect(() => {
    const removedReviewRelationships = newData.reviewedBy
      .map((reviewer) => {
        const { roles: relationshipRoles } = reviewer.relationship;
        const rolesRemovedFromRelationship = removedRoles.filter(
          (removedRole) => relationshipRoles.includes(removedRole.id)
        );
        if (rolesRemovedFromRelationship.length) {
          return {
            reviewer: reviewer.name,
            reviewee: commonUtils.getFullName(newData),
            roles: rolesRemovedFromRelationship
          };
        }
        return null;
      })
      .filter((exists) => !!exists);
    setRemovedRelationships(removedReviewRelationships);
  }, [removedRoles.length]);

  const { mutateAsync: uploadImage } = uploadImageQ();
  const onUpload = async (e) => {
    try {
      if (!e.target.files.length) {
        return;
      }
      const formData = new FormData();
      formData.append('profileImage', e.target.files[0]);
      formData.append('email', newData.email);

      const result = await uploadImage(formData);

      if (!result || !result.success) {
        return toast.error(
          'We encountered an issue uploading this image. Try again!'
        );
      }
      const { imageUrl: resultImageUrl } = result;
      updateData('imageUrl', resultImageUrl);
    } catch (err) {
      console.error('ProfileTab onUpload error', err);
    }
  };

  const onUpdate = async () => {
    const { email } = newData;
    if (!commonUtils.isEmailValid(email)) {
      return toast.error('Please enter a valid email address');
    }
    const {
      data: { success: emailSuccess, data: emailData }
    } = await refetchEmailAccount();
    if (!emailSuccess) {
      return toast.error(
        'We encountered an error checking this email. Please try again or contact us.'
      );
    }
    if (!isEmpty(emailData)) {
      return toast.error(
        `Email '${email}' is already registered for your organization`
      );
    }

    toast.show('Updating user...');
    commonTreeUtils.trimNodeAttributes(newData);

    const {
      companyid,
      firstName,
      lastName,
      title,
      roles,
      groups,
      websiteLink,
      imageUrl,
      access,
      status,
      permissions,
      manageAccounts
    } = newData;

    const isRootAndChanged = isRoot && selectedRoot._id !== newData._id;
    const managerId = isRootAndChanged ? selectedRoot._id : newData.managerId;
    const managerEmail = isRootAndChanged
      ? selectedRoot.email
      : newData.managerEmail;

    const data = {
      companyid,
      firstName,
      lastName,
      email,
      title,
      groups,
      roles,
      websiteLink,
      imageUrl,
      startDate,
      access,
      status,
      permissions,
      manageAccounts
    };

    const nodeData = {
      ...data,
      // node only attributes
      name: commonUtils.getFullName(firstName, lastName),
      firstName: undefined,
      lastName: undefined,
      manageAccounts: undefined,
      managerId,
      managerEmail
    };
    data.nodeData = nodeData;

    let res = null;
    try {
      res = await updateAccount({
        data
      });
    } catch (error) {
      return toast.error(error);
    }

    if (!res || !res.success) {
      return toast.error(
        "We've encountered an error updating this user. Please try again or contact us."
      );
    }

    toast.show('User updated!');

    invalidateData();

    const { data: updatedAccount } = res;
    const rolesRemovedDiff = oldData.roles.filter(
      (x) => !newData.roles.includes(x)
    );
    const rolesAddedDiff = newData.roles.filter(
      (x) => !oldData.roles.includes(x)
    );

    // not really used since we don't allow users to access their own Information tab
    if (
      loggedUser.id === updatedAccount._id
      && loggedUser.email !== updatedAccount.email
    ) {
      loggedUser.jwt = updatedAccount.jwt;
      loggedUser.email = updatedAccount.email;
      appUtils.saveLoggedUser(loggedUser);
    }

    if (rolesRemovedDiff.length || rolesAddedDiff.length) {
      toast.show('Roles updated - please verify review relationships!', 10000);
      const selfReviewData = newData.reviews.find(
        (r) => r.userId === newData._id
      );
      if (selfReviewData) {
        selfReviewData.revieweeRoles = newData.roles;
      }
      route(
        `/dashboard/profile/${newData._id}/information/review-relationships`
      );
    }
  };

  const isButtonDisabled = () => {
    if (newData.manageAccounts.length > MANAGE_ACCOUNTS_MAX_LENGTH) return true;

    const isRootAndChanged = isRoot && selectedRoot._id !== newData._id;
    if (isRootAndChanged) return false;

    const requiredFields = [
      'firstName',
      'lastName',
      'email',
      'title',
      'roles',
      'access',
      'status',
      'permissions',
      ...(!isNil(oldData.managerId) ? ['managerId', 'managerEmail'] : [])
    ];

    const areRequiredFieldsEmpty = requiredFields.some((field) => isEmpty(newData[field]));
    const nData = { ...newData, startDate };
    const areFieldsEqual = isEqual(oldData, nData);

    return areRequiredFieldsEmpty || areFieldsEqual;
  };

  return (
    <Base
      classes={STYLE.CONTAINER_LIGHT_GRAY_PADDINGLESS_MARGINLESS}
      loading={isMutating || isFetchingEmailAccount}
    >
      <div className='pt-5 flex flex-col items-center'>
        <Circle
          onUpload={onUpload}
          imageUrl={newData.imageUrl}
          isDisabled={!canUpdate}
        />
        <div className='mb-6'>
          <NewInput
            variant='white'
            label='First Name *'
            inputWidthClass='w-80'
            placeholder='First Name'
            onChange={(e) => updateData('firstName', e.target.value)}
            value={newData.firstName}
            labelClass='min-w-44'
            disabled={!canUpdate}
          />
        </div>
        <div className='mb-6'>
          <NewInput
            variant='white'
            label='Last Name *'
            inputWidthClass='w-80'
            placeholder='Last Name'
            onChange={(e) => updateData('lastName', e.target.value)}
            value={newData.lastName}
            labelClass='min-w-44'
            disabled={!canUpdate}
          />
        </div>
        <div className='mb-6'>
          <NewInput
            variant='white'
            inputWidthClass='w-80'
            onChange={(e) => updateData('email', e.target.value)}
            label='Email *'
            labelClass='min-w-44'
            placeholder='Email'
            value={newData.email}
            disabled={!canUpdate}
          />
        </div>
        <div className='mb-6'>
          <NewInput
            variant='white'
            inputWidthClass='w-80'
            label='Title *'
            placeholder='Title'
            onChange={(e) => updateData('title', e.target.value)}
            labelClass='min-w-44'
            value={newData.title}
            disabled={!canUpdate}
          />
        </div>

        <div className='mb-6 items-center'>
          <span className='inline-block min-w-44 text-left pr-3'>
            Role(s) *
          </span>
          <div className='inline-block'>
            <Multiselect
              classes='w-80'
              multiSelectSelectedItemClasses='w-8.5rem'
              options={roleOptions}
              placeholder='Select roles'
              other={{
                variant: 'sm',
                size: 300
              }}
              multiselect
              onChange={(option, type) => {
                if (type === 'select') {
                  const newRoles = option.checked
                    ? newData.roles.filter((r) => r !== option.id)
                    : [...newData.roles, option.id];
                  updateData('roles', newRoles);
                }
              }}
              disabled={!canUpdate}
            />
            <a
              className='block italic underline underline-offset-2 mt-2 mb-0 text-left'
              href='https://home.workstory.team/faq/creating-new-role'
              target='_blank'
              rel='noreferrer'
            >
              How to create a new role
            </a>
          </div>
        </div>

        <div className='mb-6 items-center tooltip relative'>
          <span className='tooltip-text bg-black text-white -top-[35px] left-[5%] right-[5%]'>
            Additional members that are managed by this team member
          </span>
          <span className='inline-block min-w-44 text-left pr-3'>
            Manage Users
          </span>
          <div className='inline-block'>
            <Multiselect
              textColor={COLORS['tertiary-black']}
              loading={
                isFetchingCurrentManageAccountsList
                || isFetchingManageAccountsList
              }
              classes='w-80'
              multiSelectSelectedItemClasses='w-8.5rem'
              options={manageAccountsOptions}
              placeholder='Select users'
              other={{
                variant: 'sm',
                size: 300
              }}
              multiselect
              onDropdownClose={() => setManageAccountsSearchText('')}
              onSearch={(value) => setManageAccountsSearchText(value)}
              onChange={(option, type) => {
                if (type === 'select') {
                  const newManageAccounts = option.checked
                    ? newData.manageAccounts.filter((r) => r !== option.id)
                    : [...newData.manageAccounts, option.id];
                  updateData('manageAccounts', newManageAccounts);
                }
              }}
              disabled={!canUpdate}
            />
          </div>
        </div>

        {isAdmin ? (
          <Fragment>
            <div className='mb-6 items-center'>
              <SelectGroups />
            </div>
            <div className='mb-6 flex items-center'>
              <div>
                <span className='inline-block min-w-44 text-left pr-3'>
                  {isRoot ? 'Switch primary account' : 'Reports to *'}
                </span>
                <div className='inline-block w-80'>
                  <Select
                    classes='w-full self-center'
                    placeholder='Manager'
                    options={managerOptions}
                    title={isRoot ? selectedRoot.name : managerData.name}
                    onChange={({ data }) => {
                      if (isRoot) {
                        setSelectedRoot(data);
                      } else {
                        setNewData({
                          ...newData,
                          managerId: data._id,
                          managerEmail: data.email
                        });
                        setManagerData(data);
                      }
                    }}
                    loading={isFetchingManagerList}
                    showSearch
                    onSearch={(value) => setManagerSearchText(value)}
                    onDropdownClose={() => setManagerSearchText('')}
                    disabled={company.demo || !canUpdate}
                  />
                </div>
              </div>
            </div>
            {isRoot
              ? renderRootWarning(
                selectedRoot._id !== newData._id,
                selectedRoot
              )
              : renderManagerWarning(oldData, newData, managerData)}
          </Fragment>
        ) : null}

        <div className='mb-6'>
          <NewInput
            variant='white'
            inputWidthClass='w-80'
            labelClass='min-w-44'
            label='Website link'
            placeholder='Website link'
            onChange={(e) => updateData('websiteLink', e.target.value)}
            value={newData.websiteLink}
            disabled={!canUpdate}
          />
        </div>

        <div className='mb-6'>
          <span className='inline-block min-w-44 text-left'>
            Employee start date
          </span>
          <div className='inline-block w-80'>
            <SimpleDatePicker
              date={parsedNewStartDate}
              onSelect={selectDate}
              inputClasses='bg-white w-full'
              properties={{
                dropdowns: {
                  minYear: 1950,
                  years: true,
                  months: true
                }
              }}
              disabled={!canUpdate}
              placeholder='Date Picker'
            />
          </div>
        </div>

        <div className='mb-6'>
          <span className='inline-block min-w-44 text-left'>Access *</span>
          {isAdmin ? (
            <div className='inline-block w-80'>
              <Select
                options={accessOptions}
                classes='w-full'
                title={newData.access}
                onChange={(option) => updateData('access', option.id)}
              />
            </div>
          ) : (
            <div className='inline-block w-80'>
              <NewInput
                type='text'
                classes='inline-block w-full'
                disabled
                variant='white'
                value={newData.access}
              />
            </div>
          )}
        </div>

        {newData.access !== oldData.access
          && (newData.access === ACCESS.MANAGER
            || newData.access === ACCESS.ADMIN) && (
            <div className='mb-6 mx-auto text-left'>
              <div className='bg-red-warning mx-auto p-3 rounded inline-block  max-w-32rem'>
                {newData.access === ACCESS.MANAGER
                  ? `${
                    newData.name || 'This user'
                  } will have access to historic review information for anyone on their team`
                  : `${
                    newData.name || 'This user'
                  } will have access to historic review information for anyone in the organization`}
              </div>
            </div>
        )}

        {canUpdate ? (
          <Fragment>
            <div className='mb-6'>
              <div className='min-w-44 inline-block text-left align-top'>
                <span className='inline-block mr-2 text-left'>Status *</span>
                <Tooltip>
                  <span
                    className='text-white bg-black ml-5 tooltip-text'
                    dangerouslySetInnerHTML={{
                      __html: `<div class="text-left pb-1 text-sm">Change a user's status to enable
                      <br />or prevent review scheduling.</div>`
                    }}
                  />
                </Tooltip>
              </div>
              <div className='inline-block w-80'>
                {canUpdate ? (
                  <Select
                    options={activeOptions}
                    classes='w-full'
                    title={newData.status}
                    dataCy='active-select'
                    onChange={(option) => updateData('status', option.label)}
                  />
                ) : (
                  <NewInput
                    type='text'
                    classes='inline-block w-full'
                    disabled
                    variant='white'
                    value={newData.status}
                  />
                )}
                <a
                  className='block italic underline underline-offset-2 mt-2 mb-0 text-left'
                  href='https://home.workstory.team/faq/changing-user-status'
                  target='_blank'
                  rel='noreferrer'
                >
                  See user status FAQ to learn more
                </a>
              </div>
            </div>
            <div className='mb-6'>
              <div className='min-w-44 inline-block  text-left'>
                <span className='inline-block text-left'>User can login *</span>
                <Tooltip>
                  <span
                    className='text-white ml-5 bg-black tooltip-text'
                    dangerouslySetInnerHTML={{
                      __html: `<div class="text-left pb-1 text-sm">Determines whether the user can
                      <br />login to the platform and see their
                      <br />own dashboard.</div>`
                    }}
                  />
                </Tooltip>
              </div>
              <div className='inline-block w-80'>
                {canUpdate ? (
                  <Select
                    options={canLoginOptions}
                    classes='w-full'
                    title={
                      canLoginOptions.find(
                        (opt) => newData.permissions.canLogin === opt.value
                      ).label
                    }
                    onChange={(option) => updateData('permissions', {
                      canLogin: option.value
                    })}
                  />
                ) : (
                  <NewInput
                    type='text'
                    classes='inline-block w-full'
                    disabled
                    variant='white'
                    value={
                      canLoginOptions.find(
                        (opt) => newData.permissions.canLogin === opt.value
                      ).label
                    }
                  />
                )}
              </div>
            </div>
          </Fragment>
        ) : null}

        {canUpdate ? (
          <div className='block'>
            {addedRoles.length || removedRoles.length ? (
              <div className='my-6 mx-auto max-w-27rem'>
                <div className='bg-red-warning text-center mx-auto p-3 rounded inline-block'>
                  <p className='text-black text-sm font-bold text-left m-0 mb-3'>
                    Removing roles impacts review relationships.
                  </p>
                  {oldData.roles.includes(DEFAULT_ROLE.id) ? (
                    <p className='p-0 m-0 text-sm text-left'>
                      <span className='font-bold text-black text-sm'>
                        Default Role
                      </span>
                      {' '}
                      will be automatically removed.
                    </p>
                  ) : null}

                  {removedRoles.length ? (
                    <p className='p-0 m-0 text-sm text-left'>
                      Role(s) removed:
                      {' '}
                      <span className='font-bold text-black text-sm'>
                        {removedRoles.map((r) => r.label).join(', ')}
                      </span>
                    </p>
                  ) : null}

                  {addedRoles.length ? (
                    <p className='p-0 m-0 text-sm text-left'>
                      Role(s) added:
                      {' '}
                      <span className='font-bold text-black text-sm'>
                        {addedRoles.map((r) => r.label).join(', ')}
                      </span>
                    </p>
                  ) : null}

                  <div>
                    <p className='text-black font-bold text-left m-0 text-sm pt-2'>
                      Review relationships affected:
                    </p>
                    {removedRelationships.length ? (
                      removedRelationships.map((rr) => (
                        <p className='p-0 m-0 text-sm text-left'>
                          {`${rr.reviewer} no longer reviews ${
                            rr.reviewee
                          } by role(s) ${rr.roles
                            .map((r) => r.label)
                            .join(', ')}`}
                        </p>
                      ))
                    ) : (
                      <p className='p-0 m-0 text-sm text-black text-left'>
                        None
                      </p>
                    )}
                    <p className='p-0 m-0 mt-4 text-sm text-left'>
                      After making changes, please visit the
                      {' '}
                      <strong>Reviews</strong>
                      {' '}
                      tab to make changes if necessary.
                    </p>
                  </div>
                </div>
              </div>
            ) : null}

            {newData.manageAccounts.length > MANAGE_ACCOUNTS_MAX_LENGTH ? (
              <div className='my-6 mx-auto max-w-27rem'>
                <div className='bg-red-warning mx-auto p-3 rounded inline-block w-full'>
                  <p className='text-black text-sm text-center m-0'>
                    Users can only manage up to
                    {' '}
                    <strong>{MANAGE_ACCOUNTS_MAX_LENGTH}</strong>
                    {' '}
                    additional
                    members.
                  </p>
                </div>
              </div>
            ) : null}

            <div className='tooltip'>
              {company.demo ? (
                <span className='tooltip-text bg-black text-white -mt-10 -ml-3'>
                  Disabled in Demo mode
                </span>
              ) : null}
              <div className='w-full flex justify-center mb-4'>
                <Button
                  variant='yellow'
                  onClick={onUpdate}
                  disabled={isButtonDisabled()}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        ) : null}

        {loggedUser.isSuperUser ? (
          <Base classes='p-2 mt-4'>
            <h5 className='text-center px-2'>Superuser view:</h5>
            <p>
              {`User's timezone offset is `}
              <b>
                {timezoneOptions.find(
                  (o) => o.id === newData.tz || o.timezone === newData.timezone
                ).id / 60}
              </b>
            </p>
          </Base>
        ) : null}
      </div>
    </Base>
  );
};

export default ProfileTab;
