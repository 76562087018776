import { Fragment, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useQueryClient } from 'react-query';
import { route } from 'preact-router';
import { isNumber } from 'lodash';
import STYLE from 'src/constants/style';
import BulkReportsInputSection from 'src/pagesDashboard/CompanyDash/components/BulkReportsInputSection';
import commonDateUtils from 'common/commonDateUtils';
import { Controller, useForm } from 'react-hook-form';
import FilterByDate from 'src/containers/Dash/FilterView/FilterByDate';
import { useTree } from 'src/queries/tree';
import {
  useCompany,
  QUERY_KEYS as COMPANY_QUERY_KEYS
} from 'src/queries/company';
import {
  toast, Select, Button, Base, Multiselect
} from 'src/components/';
import {
  createTeamReportsQuery,
  useBulkReportsPrepare
} from 'src/queries/reports';
import COMMON_CONSTANTS from 'common/commonConstants';
import AiTextIcon from 'src/assets/svg/ai-text-icon.svg';
import TraditionalReportIcon from 'src/assets/svg/traditional-report-icon.svg';

const {
  TEAM_REPORT_TYPES,
  TEAM_REPORT_SCORES,
  DATE_RANGE_FILTERS,
  REPORT_GENERATION_TYPE
} = COMMON_CONSTANTS;

const reportTypeOptions = [
  {
    value: TEAM_REPORT_TYPES.PERFORMANCE_REVIEW,
    title: 'Performance Review'
  },
  {
    value: TEAM_REPORT_TYPES.SELF_EVALUATION,
    title: 'Self Evaluation'
  }
];

const includeScoresOptions = [
  {
    value: TEAM_REPORT_SCORES.YES,
    title: 'Yes, include scores on report'
  },
  {
    value: TEAM_REPORT_SCORES.NO,
    title: 'No, do not include scores on report'
  }
];

const dateStart = commonDateUtils.getDateFromDaysAgo(
  DATE_RANGE_FILTERS.ONE_YEAR.days
).unix;

const BulkReports = () => {
  const { refetch: refetchQuietCompany, isFetching: isFetchingQuietCompany } = useCompany({
    bulkReports: true
  });

  const {
    data: { treeList },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    watch,
    control,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      includeScores: includeScoresOptions[0],
      reportType: reportTypeOptions[0],
      groups: [],
      dateRange: {
        value: DATE_RANGE_FILTERS.ONE_YEAR.key,
        start: dateStart,
        end: undefined
      },
      generationType: REPORT_GENERATION_TYPE.TRADITIONAL
      /* deadline: null */
    }
  });

  const formValues = watch();

  const {
    includeScores,
    reportType,
    groups,
    dateRange,
    generationType
    /* deadline */
  } = formValues;

  const {
    data: { preparedReportsData },
    isFetching: isFetchingPrepareReports,
    refetch: refetchPrepareReports,
    isError: isErrorPrepareReports,
    isRefetching: isRefetchingPrepareReports,
    isPlaceholderData: isPlaceholderDataPrepareReports
  } = useBulkReportsPrepare(
    {
      groups,
      dateRange
    },
    {},
    { keepPreviousData: true }
  );

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  useEffect(() => {
    refetchPrepareReports();
  }, [groups, dateRange]);

  const isInitialFetchingPrepareReports = isFetchingPrepareReports
    && (isPlaceholderDataPrepareReports || !isRefetchingPrepareReports);
  const isFetching = isFetchingTree || isFetchingCompany || isInitialFetchingPrepareReports;
  const isError = isErrorTree || isErrorCompany || isErrorPrepareReports;
  const isReady = company
    && company.id
    && treeList
    && treeList.length
    && !isFetching
    && !isError;

  if (!isReady) return null;

  const getAllGroups = () => {
    const teams = treeList
      .filter((node) => node.children.length)
      .map((node) => ({
        id: node.id,
        name: `Team ${node.name}`
      }));
    return [...company.groups, ...teams];
  };

  const getAllGroupOptions = () => {
    if (!company.groups && !treeList.length) {
      return [];
    }
    const allGroups = getAllGroups();
    const allOptions = allGroups.map((g) => ({
      ...g,
      label: g.name,
      checked: false
    }));
    return allOptions;
  };

  const now = commonDateUtils.getUnixDateNow() + 60 * 60 * 24;

  const removeGroup = (groupObj) => {
    const uncheckedGroup = { ...groupObj, checked: false };
    const checkedGroups = formValues.groups.filter(
      (group) => group.id !== uncheckedGroup.id
    );
    setValue('groups', [...checkedGroups, uncheckedGroup]);
  };

  const addGroup = (groupObj) => {
    const checkedGroup = { ...groupObj, checked: true };
    const uncheckedGroups = formValues.groups.filter(
      (group) => group.id !== checkedGroup.id
    );
    setValue('groups', [...uncheckedGroups, checkedGroup]);
  };

  const toggleGroup = (group) => {
    const shouldRemove = formValues.groups.length ? group.checked : false;
    if (shouldRemove) {
      removeGroup(group);
      return;
    }
    addGroup(group);
  };

  const { mutateAsync: createTeamReports, isLoading: isCreateReportLoading } = createTeamReportsQuery();

  useEffect(() => {
    if (company && company.groups && treeList && treeList.length) {
      const allOptions = getAllGroupOptions();
      setValue('groups', allOptions);
    }
  }, [company, treeList]);

  const submit = async () => {
    toast.show('Creating Reviews...');
    const result = await createTeamReports({
      includeScores: includeScores.value,
      reportType: reportType.value,
      groups,
      dateRange,
      generationType
      // deadline
    });

    if (result.warning) return toast.error(result.message);
    if (!result.success) return toast.error('Failed to create reviews');

    await refetchQuietCompany();
    route('/dashboard/organization/reports');
  };

  const renderWarning = () => {
    if (isCreateReportLoading) {
      return (
        <div className='flex items-center bg-red h-6 px-2 mr-2 rounded-sm mb-2 text-white z-50'>
          Reviews are currently being created for your organization. Please wait
          before creating more.
        </div>
      );
    }
  };

  const { reportsToBeCreatedCount, revieweesCount } = preparedReportsData;

  const revieweesWithoutReviews = revieweesCount - reportsToBeCreatedCount;

  const isConfirmDisabled = () => {
    if (
      isFetchingTree
      || isFetchingCompany
      || isFetchingPrepareReports
      || isCreateReportLoading
      || isFetchingQuietCompany
      || !reportsToBeCreatedCount
    ) {
      return true;
    }
    if (groups.every((g) => !g.checked)) {
      return true;
    }
    return false;
  };

  const reportsToBeCreatedMessage = {
    firstPart: `Action will create ${reportsToBeCreatedCount}/${revieweesCount} review(s)`,
    secondPart: `${revieweesWithoutReviews} team ${revieweesWithoutReviews === 1 ? 'member has' : 'members have'} no review data given the parameters specified above.`
  };

  const isLoading = isFetchingTree
    || isFetchingCompany
    || isFetchingPrepareReports
    || isCreateReportLoading;

  return (
    <Fragment>
      <Base classes={STYLE.CONTAINER_WHITE} loading={isLoading}>
        <div className='pl-10 pt-2'>
          <h1 className='mt-5'>Review Cycle Setup</h1>
          <div className='flex w-full justify-between'>
            <p className='text-gray-450 text-lg font-light mb-14'>
              Define the performance review parameters
            </p>
            {renderWarning()}
          </div>
          <div className='w-[70%]'>
            <BulkReportsInputSection
              id='team-report-range'
              labelText='Date Range'
              errorMessage={errors?.dateRange?.message}
            >
              <Controller
                name='dateRange'
                control={control}
                rules={{
                  required: { value: true, message: 'Date Range is mandatory' }
                }}
                render={({ field: { value: fieldValue, onChange } }) => (
                  <FilterByDate
                    id='team-report-range'
                    dateRangeSelected={fieldValue}
                    onSelectDateRange={(value, start, end) => onChange({ value, start, end })}
                    classes='w-full max-w-34rem'
                    customSelectClasses='h-10 w-full max-w-34rem'
                  />
                )}
              />
            </BulkReportsInputSection>
            <BulkReportsInputSection
              id='team-report-type'
              labelText='Review Type'
              errorMessage={errors?.reportType?.message}
            >
              <Controller
                name='reportType'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    id='team-report-type'
                    classes='w-full max-w-34rem'
                    variant='shadow'
                    title={value.title}
                    options={reportTypeOptions}
                    onChange={(option) => onChange(option)}
                  />
                )}
              />
            </BulkReportsInputSection>
            <BulkReportsInputSection
              id='team-report-groups'
              labelText='To be Reviewed'
              errorMessage={errors?.reviewee?.message}
            >
              <Controller
                name='groups'
                control={control}
                render={() => (
                  <div className='w-full max-w-34rem inline-block align-top'>
                    <Multiselect
                      disabled={isFetchingPrepareReports}
                      multiSelectSelectedItemClasses='w-8.5rem'
                      options={formValues.groups}
                      placeholder='Select Teams'
                      variant='minimal'
                      multiselect
                      onChange={(option, type) => {
                        if (type === 'select') {
                          return toggleGroup(option);
                        }
                        if (type === 'remove') {
                          return removeGroup(option);
                        }
                      }}
                    />
                  </div>
                )}
              />
            </BulkReportsInputSection>
            {/* <TeamReportsInputSection
              id='team-report-deadline'
              labelText='Review Deadline'
              errorMessage={errors?.deadline?.message}
            >
              <Controller
                name='deadline'
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Review Deadline is mandatory'
                  }
                }}
                render={({ field: { value: fieldValue, onChange } }) => (
                  <TeamReportsDeadlinePicker
                    id='team-report-deadline'
                    date={fieldValue}
                    minDate={now}
                    onSelect={(value) => onChange(value)}
                    classes='w-full max-w-34rem'
                    customSelectClasses='h-10'
                  />
                )}
              />
            </TeamReportsInputSection> */}
            <BulkReportsInputSection
              id='team-report-scores'
              labelText='Include Scores?'
              errorMessage={errors?.includeScores?.message}
            >
              <Controller
                name='includeScores'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    id='team-report-scores'
                    classes='w-full max-w-34rem'
                    variant='shadow'
                    title={value.title}
                    options={includeScoresOptions}
                    onChange={(option) => onChange(option)}
                  />
                )}
              />
            </BulkReportsInputSection>
            <BulkReportsInputSection
              id='new-user-report-generation'
              type={REPORT_GENERATION_TYPE.AI}
              labelText='Report Generation'
              errorMessage={errors?.generation?.message}
            >
              <button
                type='button'
                onClick={() => setValue('generationType', REPORT_GENERATION_TYPE.AI)}
                className={`rounded-md mb-8 focus:outline-none bg-black ${
                  formValues.generationType === REPORT_GENERATION_TYPE.AI
                    ? 'border-4 border-purple'
                    : 'border-2 border-black'
                }`}
              >
                <div className='text-left px-3 py-4'>
                  <div className='flex justify-between'>
                    <p className='font-roboto-mono text-white text-xl mb-1 mt-1'>
                      WorkStoryAI
                    </p>
                    <AiTextIcon className='text-green' />
                  </div>
                  <p className='font-roboto-mono mb-1 font-bold text-white'>
                    AI-Generated Performance Review
                  </p>
                  <p className='font-roboto-mono mb-0 text-white'>
                    Automatically summarizes performance and recommends
                    opportunities for future development. Can edit later.
                  </p>
                </div>
              </button>
              <button
                type='button'
                onClick={() => setValue('generationType', REPORT_GENERATION_TYPE.TRADITIONAL)}
                className={`rounded-md focus:outline-none w-full ${
                  formValues.generationType
                  === REPORT_GENERATION_TYPE.TRADITIONAL
                    ? 'border-4 border-purple'
                    : 'border-2 border-black'
                }`}
              >
                <div className='text-left px-3 py-4'>
                  <div className='flex justify-between'>
                    <p className='text-xl mb-1 mt-1'>Traditional</p>
                    <TraditionalReportIcon className='text-purple' />
                  </div>
                  <p className='mb-[3.4rem] font-bold'>
                    Self-Authored Performance Review
                  </p>
                </div>
              </button>
            </BulkReportsInputSection>
          </div>
          <div className='flex justify-end mb-3'>
            {isNumber(reportsToBeCreatedCount)
            && groups.some((g) => g.checked) ? (
              <div className='w-1/2 flex flex-col'>
                <p className='text-base font-semibold mb-0'>
                  {reportsToBeCreatedMessage.firstPart}
                </p>
                {revieweesWithoutReviews ? (
                  <p className='text-sm text-gray-400 font-semibold mb-0'>
                    {reportsToBeCreatedMessage.secondPart}
                  </p>
                ) : null}
              </div>
              ) : null}
            <div className='w-1/2 flex justify-end'>
              <Button
                classes='mr-3 bg-white text-black text-lg font-bold px-8 py-2.5 border rounded border-black'
                type='button'
                variant='custom'
                onClick={() => route('/dashboard/organization/reports')}
              >
                Cancel
              </Button>
              <Button
                classes='mr-3 rounded'
                disabled={isConfirmDisabled()}
                type='submit'
                variant='purple'
                onClick={submit}
              >
                Create Reviews
              </Button>
            </div>
          </div>
        </div>
      </Base>
    </Fragment>
  );
};

export default BulkReports;
