import { h, Fragment } from 'preact';
import { get } from 'lodash';
import { useState, useContext, useEffect } from 'preact/hooks';
import appUtils from 'src/components/appUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import STYLE from 'src/constants/style';
import TopFilters from 'src/containers/CompanyDash/TopFilters/TopFilters';
import TopScores from 'src/containers/CompanyDash/TopScores/TopScores';
import GroupsStats from 'src/containers/CompanyDash/GroupsStats/GroupsStats';
import PerformersStatsV2 from 'src/containers/CompanyDash/Stats/PerformersStatsV2';
import ParticipationStats from 'src/containers/CompanyDash/Stats/ParticipationStats';
import { useCompanyReviews } from 'src/pagesDashboard/UserProfile/queries';
import { useCompany } from 'src/queries/company';
import { CompanyDashContext } from 'src/pagesDashboard/CompanyDash/context/Provider';
import { HistoricReviews, Base } from 'src/components';
import HeaderV2 from 'src/containers/Header/HeaderV2';

const { ACCESS } = COMMON_CONSTANTS;

const Loading = () => (
  <Fragment>
    <HeaderV2 overtitle='Dashboards' title='Organization' />
    <Base classes={STYLE.BASE} />
  </Fragment>
);

const OrganizationOverview = () => {
  const loggedUser = appUtils.getLoggedUser();

  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isReadyCompany = company && company.id && !isFetchingCompany && !isErrorCompany;

  if (!isReadyCompany) {
    return <Loading />;
  }

  const companyid = get(company, 'id');
  const companyName = get(company, 'name');

  const {
    reviews,
    pagination,
    isFetching: isFetchingCompanyReviews,
    isError: isErrorCompanyReviews
  } = useCompanyReviews(companyid, currentPage);

  const isFetching = isFetchingCompany || isFetchingCompanyReviews;
  const isError = isErrorCompanyReviews || isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return <Loading />;
  }

  const { context } = useContext(CompanyDashContext);

  if (loggedUser.access !== ACCESS.ADMIN) {
    console.error(
      'User does not have access to company dash, redirecting to /dashboard/me/feedback'
    );
    window.location.replace(appUtils.getDashRoute());
  }

  useEffect(() => {
    setCurrentPage(1);
  }, [context.range]);

  const selectPage = (page) => {
    setCurrentPage(page);
  };

  return (
    <Fragment>
      <TopFilters
        show={['actions', 'date']}
      />
      <TopScores />
      <GroupsStats />
      <PerformersStatsV2 />
      <ParticipationStats range={context.range} />
      <HistoricReviews
        reviews={reviews}
        isLoading={!isReady}
        showRequestReview={false}
        emptyPlaceholder={`${companyName} does not have any reviews.`}
        pagination={{
          totalPages: pagination ? pagination.pages : null,
          currentPage,
          selectPage
        }}
      />
    </Fragment>
  );
};

export default OrganizationOverview;
