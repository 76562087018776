import { h } from 'preact';
import { Select } from 'src/components/';
import COMMON_CONSTANTS from 'common/commonConstants';

const { GOALS, getGoalStatusValueByKey } = COMMON_CONSTANTS;

const FilterByStatus = ({
  companyid,
  status,
  updateFn,
  customSelectClasses
}) => {
  const statusOptions = [
    ...Object.keys(GOALS.STATUS).map((key) => ({
      id: GOALS.STATUS[key],
      label: getGoalStatusValueByKey(key, companyid),
      checked: status && status.includes(GOALS.STATUS[key])
    }))
  ];

  const filterByStatusTitle = status && status.length
    ? `${status.length} status selected`
    : 'Filter by status';

  const updateSelectedStatus = (key) => {
    let newStatus = [...status];
    const exists = newStatus && newStatus.includes(key);
    if (exists) {
      newStatus = newStatus.filter((value) => value !== key);
    } else {
      newStatus.push(key);
    }

    return updateFn({ status: newStatus });
  };

  const clearStatusFilter = () => updateFn({ status: [] });

  return (
    <Select
      variant='shadow'
      multiselect
      title={filterByStatusTitle}
      options={statusOptions}
      onChange={(option) => updateSelectedStatus(option.id)}
      classes={customSelectClasses ?? 'w-60'}
      showXButton={status && status.length}
      onClickXButton={clearStatusFilter}
    />
  );
};

export default FilterByStatus;
