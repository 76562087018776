import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { useQueryClient } from 'react-query';
import uuidv4 from 'uuid/v4';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import commonQuestions from 'common/commonQuestions';
import COMMON_CONSTANTS from 'common/commonConstants';
import InfoCircleAlterSVG from 'src/assets/svg/infoCircle-alter.svg';
import {
  useQuestions,
  QUERY_KEYS as COMPANY_QUERY_KEYS
} from '../../../queries/company';
import { organizationThunks } from '../../../thunks';
import X_SVG from '../../../assets/cross.svg';
import {
  SectionBox, Button, Input, Modal, toast
} from '../../../components';
import appUtils from '../../../components/appUtils';
import sharedDateUtils from '../../../common/sharedDateUtils';
import sharedUtils from '../../../common/sharedUtils';
import SHARED_CONSTANTS from '../../../common/sharedConstants';

const { DEFAULT_CATEGORY, DEFAULT_QUESTION } = COMMON_CONSTANTS;
const { EDIT_MODES } = SHARED_CONSTANTS;

const filterQuestions = (companyQuestions, roleQuestions = []) => {
  const selected = [];
  const available = [];
  companyQuestions.forEach((questionObj) => {
    if (questionObj.id === DEFAULT_QUESTION.id) return;

    if (questionObj.status === COMMON_QUESTION_CONSTANTS.STATUS.ARCHIVED) {
      return;
    }
    if (roleQuestions.indexOf(questionObj.id) > -1) {
      selected.push(questionObj);
    } else {
      available.push(questionObj);
    }
  });

  return {
    selected,
    available
  };
};

// type = edit|add
const getInitValues = (type, category) => {
  if (type === EDIT_MODES.EDIT) {
    return {
      initName: category.label,
      initDesc: category.desc,
      initQuestions: category.questions
    };
  }

  if (type === EDIT_MODES.ADD) {
    return {
      initName: '',
      initDesc: '',
      initCategories: []
    };
  }
};

const AvailableCategories = ({ available, addItem }) => {
  const [keyword, setKeyword] = useState('');
  const [filtered, setFiltered] = useState(available);

  useEffect(() => {
    setFiltered(sharedUtils.filterList(available, 'question', keyword, 20));
  }, [available]);

  const filter = (e) => {
    const list = sharedUtils.filterList(
      available,
      'question',
      e.target.value,
      20
    );
    setKeyword(e.target.value);
    setFiltered(list);
  };

  return (
    <Fragment>
      <Input value={keyword} onChange={filter} placeholder='Search' />
      {filtered.map((category) => (
        <div>
          <p className='inline-block w-2/4 mb-4'>{category.question}</p>
          <a
            onClick={(e) => addItem(e, category)}
            className='text-red inline-block w-2/4 mb-4'
          >
            Add
          </a>
        </div>
      ))}
    </Fragment>
  );
};

// type = add|edit
const NewCategory = ({
  close,
  type,
  roleKey,
  category,
  categoryKey,
  dispatch
}) => {
  const queryClient = useQueryClient();
  const {
    data: companyQuestions,
    refetch,
    isFetching
  } = useQuestions(dispatch);
  const { initName, initDesc, initQuestions } = getInitValues(type, category);
  const { selected, available } = filterQuestions(
    companyQuestions.QUESTIONS,
    initQuestions
  );

  console.log(available.length);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(initName);
  const [desc, setDesc] = useState(initDesc);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [questions, setQuestions] = useState(selected);
  // const [categories, setCategories] = useState(selected)
  const [categoriesAvailable, setCategoriesAvailable] = useState(available);

  const isSaveDisabled = (selectedCategories, newName, newDesc) => {
    if (Object.keys(selectedCategories).length === 0) {
      return true;
    }
    if (type === EDIT_MODES.ADD) {
      if (!newName || newName === '') {
        return true;
      }
      return false;
    }

    // check name and description changes
    if (newName !== category.label) {
      return false;
    }
    if (newDesc !== category.desc) {
      return false;
    }

    // check category changes
    const currentCategories = category.questions;
    if (Object.keys(selectedCategories).length !== currentCategories.length) {
      return false;
    }

    let nomatch = true;
    currentCategories.forEach((id) => {
      const found = selectedCategories.find((cat) => cat.id === id);
      if (!found) {
        nomatch = false;
      }
    });
    return nomatch;
  };

  const addCategory = (e, category) => {
    e.preventDefault();
    let newAvailableCategories = [...categoriesAvailable];
    const newCategories = [...questions];
    newCategories.push(category);
    newAvailableCategories = categoriesAvailable.filter(
      (cat) => cat.id !== category.id
    );
    setQuestions(newCategories);
    setCategoriesAvailable(newAvailableCategories);
    setSaveDisabled(isSaveDisabled(newCategories, name, desc));
  };

  const removeCategory = (e, category) => {
    e.preventDefault();
    const newAvailableCategories = Object.assign([], categoriesAvailable);
    let newCategories = Object.assign([], questions);
    newAvailableCategories.push(category);
    newCategories = newCategories.filter((cat) => cat.id !== category.id);
    setQuestions(newCategories);
    setCategoriesAvailable(newAvailableCategories);
    setSaveDisabled(isSaveDisabled(newCategories, name, desc));
  };

  const save = () => {
    const isThereARecurringQuestion = questions.some(
      (question) => question.status === COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE
        && question.recurring
    );

    if (!isThereARecurringQuestion) {
      return toast.error(
        'All the questions in this category are set non-recurring. Please select at least one recurring question or update the ones you selected.'
      );
    }

    const unix = sharedDateUtils.getUnixDateNow();
    if (type === EDIT_MODES.EDIT) {
      const newCompanyQuestions = { ...companyQuestions };
      const newQuestions = questions.map((cat) => cat.id);
      const categoryObj = commonQuestions.getCategory(
        category.id,
        newCompanyQuestions.CATEGORIES,
        {
          byReference: true
        }
      );
      categoryObj.label = name;
      categoryObj.desc = desc;
      categoryObj.questions = newQuestions;
      categoryObj.lastUpdated = unix;
      categoryObj.status = COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE;
      setLoading(true);
      toast.show('Updating category..');
      organizationThunks
        .saveQuestions(newCompanyQuestions)(dispatch)
        .then((resp) => {
          toast.show('Category updated!');
          appUtils.scrollToTop();
          setLoading(false);
          setSaveDisabled(true);
          queryClient.invalidateQueries(COMPANY_QUERY_KEYS.COMPANY);
          refetch();
          close();
        });
    }

    if (type === EDIT_MODES.ADD) {
      const newCompanyQuestions = { ...companyQuestions };
      if (newCompanyQuestions.CATEGORIES[name]) {
        toast.error(`Category with name ${name} already exists`);
        return;
      }
      const questionsList = questions.map((cat) => cat.id);
      const newCategoryId = uuidv4();
      const newCategory = {
        id: newCategoryId,
        label: name,
        desc,
        questions: questionsList,
        timestamp: unix,
        lastUpdated: unix,
        status: COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE
      };
      newCompanyQuestions.CATEGORIES[name] = newCategory;
      newCompanyQuestions.ROLES[roleKey].categories = [
        ...newCompanyQuestions.ROLES[roleKey].categories,
        newCategoryId
      ];
      setLoading(true);
      toast.show('Adding new category..');
      organizationThunks
        .saveQuestions(newCompanyQuestions)(dispatch)
        .then((resp) => {
          toast.show('Category added!');
          appUtils.scrollToTop();
          setLoading(false);
          setSaveDisabled(true);
          queryClient.invalidateQueries(COMPANY_QUERY_KEYS.COMPANY);
          refetch();
          close();
        });
    }
  };

  const changeName = (e) => {
    setName(e.target.value);
    setSaveDisabled(isSaveDisabled(questions, e.target.value, desc));
  };

  const changeDesc = (e) => {
    setDesc(e.target.value);
    setSaveDisabled(isSaveDisabled(questions, name, e.target.value));
  };

  const archiveCategory = () => {
    const newCompanyQuestions = JSON.parse(JSON.stringify(companyQuestions));

    const currentRole = newCompanyQuestions.ROLES[roleKey];
    if (currentRole.categories.length < 2) {
      return toast.show(
        `"${category.label}" is the only category in role "${currentRole.label}". Please delete or add other categories to role "${currentRole.label}" before deleting this category.`,
        20000
      );
    }

    const rolesUsingThisCategory = [];

    Object.keys(newCompanyQuestions.ROLES).forEach((key) => {
      const roleObj = newCompanyQuestions.ROLES[key];
      if (roleObj.categories.includes(category.id)) {
        rolesUsingThisCategory.push(roleObj.label);
      }
    });

    if (rolesUsingThisCategory.length > 1) {
      return toast.show(
        `This category is used in roles: "${rolesUsingThisCategory.join(
          '", "'
        )}".`
          + ' Please remove the category from being used before deleting it.'
      );
    }

    const answer = confirm(
      `Are you sure you want to archive category ${category.label}?`
    );
    if (!answer) {
      return;
    }

    Object.keys(newCompanyQuestions.ROLES).forEach((key) => {
      const roleObj = newCompanyQuestions.ROLES[key];
      if (roleObj.categories.includes(category.id)) {
        roleObj.categories = roleObj.categories.filter(
          (categoryId) => categoryId !== category.id
        );
      }
    });

    // delete newCompanyQuestions.CATEGORIES[category.label];
    const categoryKey = Object.keys(newCompanyQuestions.CATEGORIES).find(
      (key) => newCompanyQuestions.CATEGORIES[key].id === category.id
    );

    const categoryObj = newCompanyQuestions.CATEGORIES[categoryKey];

    if (categoryObj.id === DEFAULT_CATEGORY.id) {
      return toast.show('Cannot delete default category');
    }
    categoryObj.status = COMMON_QUESTION_CONSTANTS.STATUS.ARCHIVED;

    organizationThunks
      .saveQuestions(newCompanyQuestions)()
      .then((resp) => {
        toast.show('Category deleted!');
        setLoading(false);
        setSaveDisabled(true);
        refetch();
        close();
      });
  };

  const duplicate = () => {
    const newCompanyQuestions = JSON.parse(JSON.stringify(companyQuestions));
    let i = 1;
    let newKey = `${category.label}_${i}`;
    while (newCompanyQuestions.CATEGORIES[newKey]) {
      i++;
      newKey = `${category.label}_${i}`;
    }
    const unix = sharedDateUtils.getUnixDateNow();
    const newCategoryObject = commonQuestions.createCategory({
      label: newKey,
      desc: category.desc || '',
      questions: [...category.questions],
      timestamp: unix,
      lastUpdated: unix,
      status: 'Active'
    });
    newCompanyQuestions.CATEGORIES[newKey] = newCategoryObject;
    toast.show('Duplicating category');
    setLoading(true);
    organizationThunks
      .saveQuestions(newCompanyQuestions)(dispatch)
      .then((resp) => {
        toast.show('Category duplicated');
        setLoading(false);
        setSaveDisabled(true);
        refetch();
        close();
      });
  };

  return (
    <SectionBox
      classes=''
      innerClasses='paddingBottom0'
      loading={loading}
      sectionType='dash'
    >
      <a onClick={close} className='clear-both float-right'>
        <X_SVG class='width25px height25px' />
      </a>
      <h5 className='font-bold'>
        {type === EDIT_MODES.EDIT ? 'Edit Category' : 'Add Category'}
      </h5>
      <a
        href='javascript:void(0)'
        onClick={() => {
          duplicate();
        }}
        className='blue'
      >
        Duplicate category
      </a>

      <div className='mt-6'>
        <p className='inline-block w-1/5'>Category Name</p>
        <Input
          value={name}
          onChange={changeName}
          classes='inline-block w-1/5'
          placeholder='Category Name'
        />
      </div>
      <div className='mt-6'>
        <p className='inline-block w-1/5'>Description</p>
        <div className='inline-block w-1/5'>
          <textarea
            value={desc}
            onChange={changeDesc}
            className='resize-none w-full h-32'
            placeholder='Category Description'
          />
        </div>
      </div>
      <div className='mt-6'>
        <p className='inline-block w-1/5'>Questions</p>
        <div className='inline-block w-2/5'>
          <p>Selected Questions:</p>
          {questions.length === 0 ? (
            <p className='italic'>No questions selected</p>
          ) : null}
          {questions.map((category) => (
            <div>
              <div className='inline-block w-2/4 mb-4'>
                <div className='flex gap-2 w-full h-full items-center justify-start'>
                  {category.recurring ? null : (
                    <div className='tooltip'>
                      <span className='tooltip-text w-fit bg-black text-white text-xs font-medium whitespace-nowrap -mt-[35px]'>
                        Non-recurring question
                      </span>
                      <InfoCircleAlterSVG class='w-4 h-4 my-auto' />
                    </div>
                  )}
                  <p className='m-0'>{category.question}</p>
                </div>
              </div>
              <a
                onClick={(e) => removeCategory(e, category)}
                className='text-red inline-block w-2/4 mb-4'
              >
                Remove
              </a>
            </div>
          ))}
        </div>
        <div className='inline-block w-2/5 align-top'>
          <p>Available Questions:</p>
          <AvailableCategories
            available={categoriesAvailable}
            addItem={addCategory}
          />
        </div>
      </div>

      <div className='text-center mt-16'>
        <Button disabled={saveDisabled} onClick={save}>
          Save
        </Button>
      </div>
      {type === 'edit' ? (
        <div className='text-left'>
          <a onClick={archiveCategory}>Archive Category</a>
        </div>
      ) : null}
    </SectionBox>
  );
};

export default NewCategory;
