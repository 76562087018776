import { h, cloneElement, Fragment } from 'preact';
import STYLE from 'src/constants/style';
import Notifications from 'src/containers/Dash/Notifications/Notifications';
import { Base, appUtils } from 'src/components/index';
import { useCompany } from 'src/queries/company';
import TabNavigator, { TABS } from 'src/components/TabNavigator/TabNavigator';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import { get } from 'lodash';
import { route } from 'preact-router';

const Dash = ({ children }) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = get(company, 'id') && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const loggedUserId = appUtils.getLoggedUserId();
  const tabNavigatorActions = [
    {
      tabs: [TABS.FEEDBACK, TABS.ANALYTICS],
      onClick: () => route(`/dashboard/request-feedback?revieweeId=${loggedUserId}`),
      buttonText: 'Request Feedback',
      buttonVariant: 'black'
    },
    {
      tabs: [TABS.GOALS],
      onClick: () => route(`/dashboard/goal/new/${loggedUserId}`),
      buttonText: 'Set a Goal',
      buttonVariant: 'black'
    },
    {
      tabs: [TABS.REPORTS],
      onClick: () => route(`/dashboard/profile/${loggedUserId}/report/new`),
      buttonText: 'Create a Review',
      buttonVariant: 'black'
    }
  ];

  return (
    <Fragment>
      <HeaderV2 overtitle='Dashboards' title='Individual' />
      <Notifications />
      <TabNavigator userId={loggedUserId} actions={tabNavigatorActions} />
      <Base classes={STYLE.BASE}>
        {cloneElement(children, { userId: loggedUserId })}
      </Base>
    </Fragment>
  );
};

export default Dash;
