import qs from 'qs';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { get, isNil, isEmpty } from 'lodash';
import { appUtils, toast } from 'src/components/index';
import { route } from 'preact-router';
import api from '../services/api';

export const QUERY_KEYS = {
  ACCOUNT: 'account',
  ACCOUNTS: 'accounts',
  PARTICIPATION: 'participation',
  SCORE: 'score',
  OVERVIEW: 'overview',
  TASKS: 'tasks',
  TASK_DATA: 'task-data',
  TASK_COUNT: 'task-count'
};

export const useAccountLogin = (options = {}) => {
  const queryClient = useQueryClient();
  const { mutateAsync: login, ...rest } = useMutation((data) => api.post('/user/login', data).then((resp) => {
    const {
      success, error, message, warning
    } = resp;
    if (!success) {
      if (warning) return toast.show(message);
      console.error('POST /user/login', error);
      return toast.error(
        'Uh oh, we ran into an issue. Please try again later!'
      );
    }

    const redir = options.redir || resp.redir;
    const { userData } = resp;
    queryClient.removeQueries();
    appUtils.saveLoggedUser(userData);
    if (redir) {
      if (window.location.pathname === redir) {
        window.location.reload();
      } else {
        route(redir);
      }
    } else if (
      appUtils.showOrganizationIntroSection(userData)
        && process.env.NEW_ONBOARDING === 'true'
    ) {
      route('/dashboard/get-started');
    } else route(appUtils.getHomeRoute());

    return resp;
  }));

  return {
    login,
    ...rest
  };
};

export const useAccountSignUp = (options = {}) => {
  const queryClient = useQueryClient();
  const { mutateAsync: signup, ...rest } = useMutation((data) => api.post('/user/create', data).then((resp) => {
    const {
      success, error, message, warning
    } = resp;
    if (!success) {
      if (warning) return toast.show(message);
      console.error('POST /user/create', error);
      return toast.error(
        'Uh oh, we ran into an issue. Please try again later!'
      );
    }

    const redir = options.redir || resp.redir;
    const { userData } = resp;
    queryClient.removeQueries();
    appUtils.saveLoggedUser(userData);
    if (redir) {
      if (window.location.pathname === redir) {
        window.location.reload();
      } else {
        route(redir);
      }
    } else if (process.env.NEW_ONBOARDING === 'true') {
      route('/dashboard/get-started');
    } else {
      route('/dashboard/organization/chart');
    }

    return resp;
  }));

  return {
    signup,
    ...rest
  };
};

export const useAccount = (id, { include } = {}, queryOptions = {}) => {
  const options = { include };
  const stringified = qs.stringify({ options }, { skipNulls: true });

  let accountId = id;
  if (id === 'me') {
    accountId = appUtils.getLoggedUserId();
  }

  const { data, ...rest } = useQuery(
    [QUERY_KEYS.ACCOUNT, accountId, options, queryOptions],
    () => api.get(`/account/${accountId}?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /account/${accountId}?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    {
      enabled: Boolean(accountId),
      ...queryOptions
    }
  );

  return {
    data: get(data, 'data', {}),
    meta: get(data, 'meta', {}),
    success: get(data, 'success'),
    ...rest
  };
};

export const useAccounts = (
  {
    ids, emails, groups, reviews, status, notIds, verified, notStatus
  } = {},
  {
    page, sort, include, search, projection
  } = {},
  queryOptions = {}
) => {
  const filters = {
    ids,
    emails,
    groups,
    reviews,
    status,
    notIds,
    verified,
    notStatus
  };
  const options = {
    page,
    sort,
    include,
    search,
    projection
  };
  const stringified = qs.stringify(
    { filters, options },
    { skipNulls: true, arrayFormat: 'brackets' }
  );

  const { data, ...rest } = useQuery(
    [QUERY_KEYS.ACCOUNTS, ...(ids || []), filters, options],
    () => api.get(`/account?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /account?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    queryOptions
  );

  return {
    data: get(data, 'data', []),
    meta: get(data, 'meta', {
      page: {
        number: null,
        size: null,
        totalItems: null,
        totalPages: null
      }
    }),
    success: get(data, 'success'),
    ...rest
  };
};

export const useAccountCreate = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: mutate, ...rest } = useMutation(
    (data) => api.post('/account', data).then((resp) => {
      if (!resp.success) {
        console.error('POST /account error');
        return {
          success: false
        };
      }
      return resp;
    }),
    {
      mutationKey: [QUERY_KEYS.ACCOUNT],
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.ACCOUNT);
        queryClient.invalidateQueries(QUERY_KEYS.ACCOUNTS);
      }
    }
  );

  return {
    create: mutate,
    ...rest
  };
};

export const useAccountUpdate = (id) => {
  const queryClient = useQueryClient();
  const { mutateAsync: mutate, ...rest } = useMutation(
    (data) => api
      .patch(`/account/${id}`, data)
      .then((resp) => {
        if (!resp.success) {
          console.error('PATCH /account error');
          return {
            success: false
          };
        }
        return resp;
      })
      .catch((error) => {
        console.error('useAccountUpdate', error, { id });
        return Promise.reject(error);
      }),
    {
      mutationKey: [QUERY_KEYS.ACCOUNT, id],
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.ACCOUNT);
        queryClient.invalidateQueries(QUERY_KEYS.ACCOUNTS);
      }
    }
  );

  return {
    update: mutate,
    ...rest
  };
};

export const useAccountsParticipation = (
  {
    ids, categoryId, managerId, start, end, notStatus
  },
  { page, sort, include } = {}
) => {
  const filters = {
    ids,
    categoryId,
    managerId,
    start,
    end,
    notStatus
  };
  const options = { page, sort, include };
  const stringified = qs.stringify({ filters, options }, { skipNulls: true });

  const { data, ...rest } = useQuery(
    [
      QUERY_KEYS.ACCOUNTS,
      QUERY_KEYS.PARTICIPATION,
      filters,
      options,
      ...(ids || [])
    ],
    () => api.get(`/account/participation?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /account/participation?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    })
  );

  return {
    data: get(data, 'data', []),
    meta: get(data, 'meta', {
      page: {
        number: null,
        size: null,
        totalItems: null,
        totalPages: null
      }
    }),
    success: get(data, 'success'),
    ...rest
  };
};

export const useAccountParticipation = (
  { id, start, end },
  { include } = {}
) => {
  const filters = { start, end };
  const options = { include };
  const stringified = qs.stringify({ filters, options }, { skipNulls: true });

  const { data, ...rest } = useQuery(
    [QUERY_KEYS.ACCOUNT, QUERY_KEYS.PARTICIPATION, filters, options, id],
    () => api.get(`/account/participation/${id}?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(
          `GET /account/participation/${id}?${stringified} error`
        );
        return {
          success: false
        };
      }
      return resp;
    }),
    {
      enabled: Boolean(id)
    }
  );

  return {
    data: get(data, 'data', {}),
    meta: get(data, 'meta', {}),
    success: get(data, 'success'),
    ...rest
  };
};

export const useAccountsScore = (
  {
    ids, managerId, start, end, notStatus
  },
  {
    page, sort, include, role, teamScores
  } = {},
  queryOptions = {}
) => {
  const filters = {
    ids,
    managerId,
    start,
    end,
    notStatus
  };
  const options = {
    page,
    sort,
    include,
    role,
    teamScores
  };
  const stringified = qs.stringify(
    { filters, options },
    { skipNulls: true, arrayFormat: 'brackets' }
  );

  const { data, ...rest } = useQuery(
    [QUERY_KEYS.ACCOUNTS, QUERY_KEYS.SCORE, filters, options, ...(ids || [])],
    () => api.get(`/account/score?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /account/score?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    queryOptions
  );

  return {
    data: get(data, 'data', []),
    meta: get(data, 'meta', {
      page: {
        number: null,
        size: null,
        totalItems: null,
        totalPages: null
      }
    }),
    success: get(data, 'success'),
    ...rest
  };
};

export const useAccountScore = (
  { id, start, end },
  {
    include,
    role,
    bundleCategories,
    bundleReviewers,
    reportId,
    enabled = false
  } = {}
) => {
  const filters = { start, end };
  const options = {
    include,
    role,
    reportId,
    bundleCategories,
    bundleReviewers
  };
  const stringified = qs.stringify({ filters, options }, { skipNulls: true });

  const { data, ...rest } = useQuery(
    [QUERY_KEYS.ACCOUNT, QUERY_KEYS.SCORE, filters, options, id],
    () => api.get(`/account/score/${id}?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /account/score/${id}?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    {
      enabled: Boolean(id) || enabled
    }
  );

  return {
    data: get(data, 'data', {}),
    meta: get(data, 'meta', {}),
    success: get(data, 'success'),
    ...rest
  };
};

export const useAccountOverview = ({ id, start, end }) => {
  const filters = { start, end };
  const stringified = qs.stringify({ filters }, { skipNulls: true });

  const { data, ...rest } = useQuery(
    [QUERY_KEYS.ACCOUNT, QUERY_KEYS.OVERVIEW, filters, id],
    () => api.get(`/account/overview/${id}?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /account/overview/${id}?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    {
      enabled: Boolean(id)
    }
  );

  return {
    data: get(data, 'data', {}),
    meta: get(data, 'meta', {}),
    success: get(data, 'success'),
    ...rest
  };
};

export const useAccountTasks = (id, options = {}) => {
  const stringified = qs.stringify({ options }, { skipNulls: true });

  let accountId = id;
  if (id === 'me') {
    accountId = appUtils.getLoggedUserId();
  }

  if (isNil(accountId) || isEmpty(accountId)) {
    return console.error('account.useAccountTasks account id is null or empty');
  }

  const queryKeys = [
    QUERY_KEYS.TASKS,
    QUERY_KEYS.TASK_DATA,
    QUERY_KEYS.ACCOUNT,
    accountId,
    options
  ];
  if (!isEmpty(options.specs) && options.specs.count) queryKeys.splice(1, 1, QUERY_KEYS.TASK_COUNT);

  const { data, ...rest } = useQuery(
    queryKeys,
    () => api.get(`/account/tasks/${accountId}?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /account/overview/${accountId} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    {
      enabled: Boolean(accountId)
    }
  );

  return {
    data: get(data, 'data', {}),
    meta: get(data, 'meta', {}),
    success: get(data, 'success'),
    ...rest
  };
};
