import { h } from 'preact';
import { useEffect, useState, useRef } from 'preact/hooks';
import { route } from 'preact-router';
import { useQueryClient } from 'react-query';
import {
  Button, Base, Select, toast
} from 'src/components';
import { useTree } from 'src/queries/tree';
import {
  useCompany,
  QUERY_KEYS as COMPANY_QUERY_KEYS
} from 'src/queries/company';
import commonTreeUtils from 'common/commonTreeUtils';
import commonQuestions from 'common/commonQuestions';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import CategoryOption from 'src/containers/UserProfile/TopScores/CategoryOption';
import commonReviewUtils from 'common/commonReviewUtils';
import STYLE from 'src/constants/style';
import { useAccount, useAccounts } from 'src/queries/account';
import COMMON_CONSTANTS from 'common/commonConstants';
import { newQuestionQuery } from 'src/queries/questions';
import { uniq } from 'lodash';

const { USER_STATE, ACCESS, DIRECT_REVIEW_PERMISSIONS } = COMMON_CONSTANTS;

const PAGE_ROUTES = {
  FEEDBACK: 'feedback',
  REVIEW: 'review'
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const SelectGiveFeedback = ({ parentProps }) => {
  const { rest: currentPage, revieweeId } = parentProps;

  const isFeedback = currentPage === PAGE_ROUTES.FEEDBACK;
  const isReview = currentPage === PAGE_ROUTES.REVIEW;

  const queryClient = useQueryClient();

  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');

  const {
    data: defaultReviewee,
    isFetching: isFetchingDefaultReviewee,
    isError: isErrorDefaultReviewee
  } = useAccount(
    revieweeId,
    {
      projection: ['name']
    },
    { enabled: Boolean(revieweeId) }
  );

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    refetch: refetchCompany,
    isRefetching: isRefetchingCompany
  } = useCompany({
    keepPreviousData: true,
    isSelectFeedback: true
  });

  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isInitalFetchingCompany = isFetchingCompany && !isRefetchingCompany;
  const isFetching = isFetchingLoggedAccount
    || isInitalFetchingCompany
    || isFetchingTree
    || isFetchingDefaultReviewee;
  const isError = isErrorLoggedAccount
    || isErrorCompany
    || isErrorTree
    || isErrorDefaultReviewee;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const [searchText, setSearchText] = useState('');
  const [isCheckedQuestionsEmpty, setIsCheckedQuestionsEmpty] = useState(true);
  const [checkedQuestions, setCheckedQuestions] = useState([]);

  const isAdmin = loggedAccount.access === ACCESS.ADMIN;
  const isManager = loggedAccount.access === ACCESS.MANAGER;
  const isBasic = loggedAccount.access === ACCESS.BASIC;
  const canReviewAnyone = isAdmin
    || isManager
    || company.settings.directReviewPermissions === DIRECT_REVIEW_PERMISSIONS.OPEN;

  const revieweeIds = loggedAccount.reviews.map(
    (relationship) => relationship.userId
  );

  const COMPANY_QUESTIONS = company.questions;
  const canReviewDefaultReviewee = canReviewAnyone || revieweeIds.includes(revieweeId);

  const [selectedRevieweeData, setSelectedRevieweeData] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const selectedReviewee = selectedRevieweeData?.data;

  const onChangeReviewee = (reviewee) => setSelectedRevieweeData(reviewee);

  const redirectBackToFeedbacks = async () => {
    const searchQ = `?revieweeId=${revieweeId}&type=${PAGE_ROUTES.FEEDBACK}`;
    toast.error(`You do not have permission to review ${defaultReviewee.name}`);
    await sleep(500);
    return route(`/dashboard/submit-feedback${searchQ}`);
  };

  useEffect(() => {
    // if user choose to give review but is not allowed to, redirect to feedback
    if (
      revieweeId
      && defaultReviewee._id
      && !canReviewDefaultReviewee
      && currentPage
    ) {
      return redirectBackToFeedbacks();
    }

    if (defaultReviewee._id && canReviewDefaultReviewee && currentPage) {
      setSelectedRevieweeData({
        value: defaultReviewee._id,
        label: defaultReviewee.name,
        data: defaultReviewee
      });
    }
  }, [revieweeId, defaultReviewee._id, canReviewDefaultReviewee, currentPage]);

  const { data: revieweeAccounts, isFetching: isFetchingRevieweeAccounts } = useAccounts(
    {
      ...(!canReviewAnyone ? { ids: revieweeIds } : {}),
      status: [USER_STATE.ACTIVE, USER_STATE.PASSIVE]
    },
    {
      search: {
        enabled: true,
        field: 'name',
        value: searchText
      },
      page: { size: 100 },
      projection: ['name']
    },
    {
      enabled: Boolean(canReviewAnyone || revieweeIds.length)
    }
  );

  const revieweeOptions = [];
  revieweeAccounts.forEach((reviewee) => revieweeOptions.push({
    value: reviewee._id,
    label: reviewee.name,
    data: reviewee
  }));

  const roleOptions = [];
  if (selectedReviewee && selectedReviewee._id) {
    let roleIds;
    if (canReviewAnyone) {
      const revieweeNode = commonTreeUtils.findNodeById(
        tree,
        selectedReviewee._id
      );
      roleIds = revieweeNode.roles;
    } else {
      const reviewRelationship = loggedAccount.reviews.find(
        (relationship) => relationship.userId === selectedReviewee._id
      );
      roleIds = reviewRelationship.roles;
    }
    roleIds.forEach((roleId) => {
      const role = commonQuestions.getRoleById(roleId, COMPANY_QUESTIONS);
      roleOptions.push({
        id: roleId,
        label: role.label
      });
    });
  }

  const categoryOptions = [];
  if (selectedRole) {
    const roleCategories = commonQuestions.getRoleCategories(
      [selectedRole.id],
      COMPANY_QUESTIONS
    );
    roleCategories.forEach((category) => {
      categoryOptions.push({
        ...category,
        questionObjects: category.questions
          .filter(
            (qid) => commonQuestions.getQuestion(qid, COMPANY_QUESTIONS.QUESTIONS)
              .status === COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE
          )
          .map((qid) => commonReviewUtils.getDirectReviewQuestion({
            name: selectedReviewee.name,
            frequency: company.emailFrequency,
            isSelfReview: selectedReviewee._id === loggedAccount._id,
            roleId: selectedRole.id,
            questionData: {
              questionId: qid,
              categoryId: category.id
            },
            COMPANY_QUESTIONS
          }))
      });
    });
  }

  const { mutateAsync: addQuestion, isLoading: isLoadingAddQuestion } = newQuestionQuery();

  const goToReview = async () => {
    if (!company.active) {
      return toast.error('Company is not active.');
    }

    const urlQuestions = JSON.stringify(checkedQuestions);
    const url = isReview
      ? `/dashboard/submit-review/${selectedReviewee._id}/${selectedRole.id}/${urlQuestions}`
      : `/dashboard/submit-feedback/${selectedReviewee._id}/feedback`;

    return route(url);
  };

  const reviewCount = checkedQuestions.length;
  const areDataFieldsEmpty = !selectedReviewee || (isReview && !selectedRole);

  const isSubmitDisabled = areDataFieldsEmpty
    || (isReview && isCheckedQuestionsEmpty)
    || isLoadingAddQuestion;

  const submitButtonTitle = ` ${isReview ? 'Start' : 'Give'} ${reviewCount > 1 ? reviewCount : ''} ${isReview ? 'Review' : 'Feedback'}${reviewCount > 1 ? `s` : ''}`;

  const cleanupRef = useRef(() => {});
  useEffect(() => () => cleanupRef.current(), []);

  return (
    <Base classes={STYLE.CONTAINER}>
      <div className='min-h-80 bg-white'>
        <div className='mb-4'>
          <span className='font-semibold text-black'>Reviewee</span>
          <Select
            placeholder='selectedReviewee'
            classes='w-full'
            options={revieweeOptions}
            title={
              selectedRevieweeData
                ? selectedRevieweeData.label
                : 'Select a Team Member'
            }
            onChange={(revieweeOption) => {
              setSelectedRole(null);
              setCheckedQuestions([]);
              setIsCheckedQuestionsEmpty(true);
              onChangeReviewee(revieweeOption);
            }}
            loading={isFetchingRevieweeAccounts}
            showSearch
            onSearch={(value) => setSearchText(value)}
            onDropdownClose={() => setSearchText('')}
            disabled={revieweeId}
          />
        </div>
        {selectedReviewee && isReview ? (
          <div>
            {roleOptions.length ? (
              <div className='mb-4'>
                <span className='font-semibold text-black'>Role</span>

                <Select
                  placeholder='selectedRole'
                  classes='w-full'
                  options={roleOptions}
                  title={selectedRole ? selectedRole.label : 'Select a Role'}
                  onChange={(roleOption) => {
                    setCheckedQuestions([]);
                    setIsCheckedQuestionsEmpty(true);
                    setSelectedRole(roleOption);
                  }}
                />
              </div>
            ) : (
              <div className='h-80 flex items-center'>
                <span className='font-base'>
                  You do not review
                  {' '}
                  {selectedReviewee.name}
                  {' '}
                  by any roles. Please
                  adjust review relationships if necessary
                </span>
              </div>
            )}
            {selectedRole ? (
              <div className='mb-4'>
                <span className='font-semibold text-black'>
                  Select questions to base your review(s) on
                </span>
                {categoryOptions.map((category) => (
                  <CategoryOption
                    category={category}
                    checkedQuestions={checkedQuestions}
                    setCheckedQuestions={setCheckedQuestions}
                    setIsButtonDisabled={setIsCheckedQuestionsEmpty}
                    showCustomQuestion
                    isLoadingSaveCustomQuestion={
                      isLoadingAddQuestion || isRefetchingCompany
                    }
                    onSaveCustomQuestion={async (questionObject) => {
                      const { success, questionId } = await addQuestion({
                        questionObject,
                        categoryId: category.id
                      });

                      if (!success) return toast.error('Failed to add question');

                      cleanupRef.current = () => {
                        queryClient.invalidateQueries(
                          COMPANY_QUERY_KEYS.COMPANY
                        );
                        queryClient.invalidateQueries(
                          COMPANY_QUERY_KEYS.COMPANY_QUESTIONS
                        );
                      };

                      refetchCompany();
                      setCheckedQuestions((prev) => [
                        ...prev,
                        {
                          categoryId: category.id,
                          questionId
                        }
                      ]);
                      setIsCheckedQuestionsEmpty(false);
                    }}
                  />
                ))}
              </div>
            ) : null}
          </div>
        ) : null}
        <div className='flex justify-start text-center mt-12 mb-4'>
          <Button
            onClick={goToReview}
            disabled={isSubmitDisabled}
            variant='black'
            classes='!border-0 !rounded-lg'
          >
            {submitButtonTitle}
          </Button>
        </div>
      </div>
    </Base>
  );
};

export default SelectGiveFeedback;
