import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Button, Base, Radio, appUtils
} from 'src/components';
import STYLE from 'src/constants/style';

const SubmitFeedbackContainer = (props) => {
  const {
    children,
    parentProps: { revieweeId, type, rest: currentRoute }
  } = props;

  const { textAlternativeOnGiveFeedbackPage } = useFlags();

  const loggedUser = appUtils.getLoggedUser();

  const [selectionValue, setSelectionValue] = useState();

  const opts = [
    {
      title: textAlternativeOnGiveFeedbackPage
        ? 'Share a general feedback comment'
        : 'Share a feedback comment',
      value: 'feedback',
      description: textAlternativeOnGiveFeedbackPage
        ? 'Add "on-demand" feedback for the team here! Kudos from a client? Want to thank them for helping out on a project? Add it here!'
        : 'Provide general feedback without assigning it to a performance category'
    },
    {
      title: textAlternativeOnGiveFeedbackPage
        ? 'Submit a post-engagement review'
        : 'Submit a review',
      value: 'review',
      description: textAlternativeOnGiveFeedbackPage
        ? 'Add post-engagement reviews here for colleagues on your engagement team linked to our core competencies.'
        : 'Rate and give feedback for a specific performance category'
    }
  ];

  useEffect(() => {
    const searchQ = revieweeId ? `?revieweeId=${revieweeId}` : '';

    if (selectionValue) {
      route(`/dashboard/submit-feedback/${selectionValue}${searchQ}`);
    }
  }, [selectionValue, revieweeId]);

  useEffect(() => {
    if (type || currentRoute) {
      setSelectionValue(type ?? currentRoute);
    }
  }, [type, currentRoute]);

  const navigateBack = () => {
    const isMyProfile = revieweeId === loggedUser.id;

    if (isMyProfile) {
      return route(appUtils.getDashRoute());
    }

    if (!revieweeId) return route('/dashboard/home');

    return route(appUtils.getDashRoute(revieweeId));
  };

  return (
    <Base classes={STYLE.STANDARD}>
      <div className='min-h-80 p-5 bg-white'>
        <div className='flex justify-between items-center mb-6'>
          <h1 className='font-bold text-xxl m-0 p-0 text-center'>
            Give Feedback
          </h1>
          <Button
            type='button'
            variant='empty-with-border'
            onClick={navigateBack}
          >
            Close
          </Button>
        </div>

        {opts.map((option) => (
          <div className='font-semibold'>
            <Radio
              name='option'
              value={selectionValue}
              title={option.title}
              checked={selectionValue === option.value}
              onChange={() => setSelectionValue(option.value)}
            />
            <div>
              <p className='text-dark-grey ml-9'>{option.description}</p>
            </div>
          </div>
        ))}

        <div className='mt-16'>{children}</div>
      </div>
    </Base>
  );
};

export default SubmitFeedbackContainer;
