import { h, Fragment } from 'preact';
import { useState, useEffect, useContext } from 'preact/hooks';
import { useQueryClient } from 'react-query';
import uuidv4 from 'uuid/v4';
import { isNil } from 'lodash';
import {
  useQuestions,
  QUERY_KEYS as COMPANY_QUERY_KEYS
} from 'src/queries/company';
import {
  SectionBox,
  Button,
  Input,
  NewInput,
  toast,
  Base,
  Checkbox
} from 'src/components';
import appUtils from 'src/components/appUtils';
import CustomQuestionAnswers from 'src/pagesDashboard/QuestionBuilder/components/CustomQuestionAnswers';
import QuestionPreview from 'src/pagesDashboard/QuestionBuilder/components/QuestionPreview';
import { QuestionBuilderContext } from 'src/pagesDashboard/QuestionBuilder/context/QuestionBuilderProvider';
import { updateData } from 'src/pagesDashboard/QuestionBuilder/context/actions';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import commonQuestionsUtils from 'common/commonQuestionsUtils';
import {
  newQuestionQuery,
  editQuestionQuery,
  archiveQuestionQuery
} from 'src/queries/questions';
import commonQuestions from 'common/commonQuestions';
import COMMON_CONSTANTS from 'common/commonConstants';
import SHARED_CONSTANTS from '../../../common/sharedConstants';
import sharedUtils from '../../../common/sharedUtils';
import X_SVG from '../../../assets/cross.svg';

const { DEFAULT_QUESTION } = COMMON_CONSTANTS;

const {
  DEFAULT_QUESTION_ANSWERS,
  QUESTION_ANSWER_TYPES,
  ONE_TO_FIVE_CUSTOM,
  ONE_TO_TEN_CUSTOM,
  STATUS
} = COMMON_QUESTION_CONSTANTS;

const { EDIT_MODES } = SHARED_CONSTANTS;

// type = edit|add
const getInitValues = (type, question) => {
  if (type === EDIT_MODES.EDIT) {
    return {
      initQuestion: question.question,
      initQuestionSelf: question.questionYourself,
      leftLabel: question.questionHeaders.left,
      rightLabel: question.questionHeaders.right,
      initRecurring: question.recurring
    };
  }

  if (type === EDIT_MODES.ADD) {
    return {
      initQuestion: '',
      initQuestionSelf: '',
      leftLabel: '',
      rightLabel: '',
      initRecurring: true
    };
  }
};

const AvailableCategories = ({ available, addItem }) => {
  const [keyword, setKeyword] = useState('');
  const [filtered, setFiltered] = useState(available);

  useEffect(() => {
    setFiltered(sharedUtils.filterList(available, 'question', keyword, 20));
  }, [available]);

  const filter = (e) => {
    const list = sharedUtils.filterList(
      available,
      'question',
      e.target.value,
      20
    );
    setKeyword(e.target.value);
    setFiltered(list);
  };

  return (
    <Fragment>
      <Input value={keyword} onChange={filter} placeholder='Search' />
      {filtered.map((category) => (
        <div>
          <p className='inline-block w-2/4 mb-4'>{category.question}</p>
          <a
            onClick={(e) => addItem(e, category)}
            className='text-red inline-block w-2/4 mb-4'
          >
            Add
          </a>
        </div>
      ))}
    </Fragment>
  );
};

const validateForm = ({
  questionVal,
  selfVal,
  leftVal,
  rightVal,
  answersTypeVal,
  recurring
}) => !questionVal
  || questionVal === ''
  || !selfVal
  || selfVal === ''
  || ([
    QUESTION_ANSWER_TYPES.ONE_TO_TEN,
    QUESTION_ANSWER_TYPES.ONE_TO_FIVE
  ].includes(answersTypeVal)
    && (!leftVal || leftVal === '' || !rightVal || rightVal === ''))
  || isNil(recurring);

// const getAnswerOptions = (answersObj) => {
//   if (answersObj.type === COMMON_QUESTION_CONSTANTS.QUESTION_ANSWER_TYPES.CUSTOM) {
//     return answerObj.custom.map((customObj) => ({
//       ...customObj,
//       score: utils.getScoreAtIndex(questionObject.answers.custom, index)
//     }))
//   }
// };

// type = add|edit
const NewQuestion = ({
  close, type, categoryKey, questionId, dispatch
}) => {
  const { context, dispatch: dispatchContext } = useContext(
    QuestionBuilderContext
  );
  const queryClient = useQueryClient();
  const { data: companyQuestions, isFetching: isFetchingCompanyQuestions } = useQuestions(dispatch);
  const { mutateAsync: archiveQuestion, isLoading: archiveLoading } = archiveQuestionQuery();
  const { mutateAsync: editQuestion, isLoading: editQuestionLoading } = editQuestionQuery();
  const { mutateAsync: addQuestion, isLoading: addQuestionLoading } = newQuestionQuery();

  const {
    question,
    questionSelf,
    left,
    right,
    answers,
    recurring,
    answers: { type: answersType }
  } = context;

  const [isFormInvalid, setIsFormInvalid] = useState(true);

  useEffect(() => {
    if (context.questionObj) {
      if (context.questionObj.answers.type !== answersType) {
        setIsFormInvalid(false);
      }
    }

    setIsFormInvalid(
      validateForm({
        questionVal: question,
        selfVal: questionSelf,
        leftVal: left,
        rightVal: right,
        answersTypeVal: answersType,
        recurring
      })
    );
  }, [context]);

  useEffect(() => {
    if (companyQuestions && companyQuestions.QUESTIONS) {
      const initObj = questionId
        ? companyQuestions.QUESTIONS.find((obj) => obj.id === questionId)
        : null;
      const {
        initQuestion,
        initQuestionSelf,
        leftLabel,
        rightLabel,
        initRecurring
      } = getInitValues(type, initObj);

      setIsFormInvalid(
        validateForm({
          questionVal: question,
          selfVal: questionSelf,
          leftVal: left,
          rightVal: right,
          answersTypeVal: answersType,
          recurring: initRecurring
        })
      );

      dispatchContext(
        updateData({
          type,
          questionObj: initObj,
          question: initQuestion,
          questionSelf: initQuestionSelf,
          left: leftLabel,
          right: rightLabel,
          answers: initObj ? initObj.answers : DEFAULT_QUESTION_ANSWERS,
          recurring: initRecurring
        })
      );
    }
  }, [questionId, companyQuestions]);

  const updateContext = (key, value) => {
    dispatchContext(updateData({ [key]: value }));
  };

  const save = async () => {
    if (answersType === QUESTION_ANSWER_TYPES.ONE_TO_FIVE) {
      answers.custom = ONE_TO_FIVE_CUSTOM;
    }

    if (answersType === QUESTION_ANSWER_TYPES.ONE_TO_TEN) {
      answers.custom = ONE_TO_TEN_CUSTOM;
    }

    if (answersType === QUESTION_ANSWER_TYPES.CUSTOM) {
      answers.custom = context.answers.custom.map((answerObj, index) => ({
        ...answerObj,
        score: parseFloat(
          commonQuestions.getScoreAtIndex(context.answers.custom, index)
        )
      }));
    }

    if (type === EDIT_MODES.ADD) {
      try {
        const newQuestionObject = {
          status: COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE,
          question,
          questionYourself: questionSelf,
          questionHeaders: {
            left,
            right
          },
          answers,
          recurring
        };
        const categoryObject = companyQuestions.CATEGORIES[categoryKey];

        if (!recurring) {
          const { success, message } = commonQuestionsUtils.canMakeQuestionNonRecurring(
            questionId,
            companyQuestions
          );
          if (!success) return toast.error(message);
        }

        await addQuestion({
          questionObject: newQuestionObject,
          categoryId: categoryObject.id
        });
        toast.show('Question saved!');
        queryClient.invalidateQueries(COMPANY_QUERY_KEYS.COMPANY);
        queryClient.invalidateQueries(COMPANY_QUERY_KEYS.COMPANY_QUESTIONS);
        close();
      } catch (error) {
        return toast.error(error);
      }
    }

    if (type === EDIT_MODES.EDIT) {
      try {
        if (!recurring) {
          const categoriesContainingQuestion = commonQuestionsUtils.getCategoriesContainingQuestion(
            companyQuestions,
            questionId
          );
          let message = '';
          for (const categoryObject of categoriesContainingQuestion) {
            const activeCategoryQuestions = companyQuestions.QUESTIONS.filter(
              (questionObj) => questionObj.status === STATUS.ACTIVE
                && categoryObject.questions.includes(questionObj.id)
            );
            const isLastRecurringQuestion = activeCategoryQuestions.every(
              (questionObj) => !questionObj.recurring || questionObj.id === questionId
            );
            if (isLastRecurringQuestion) {
              if (message) {
                message += `, ${categoryObject.label}`;
              } else {
                message = `Categories ${categoryObject.label}`;
              }
            }
          }
          if (message) {
            return toast.error(
              `${message} need at least one recurring question.`
            );
          }
        }

        const newQuestionObject = {
          status: COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE,
          question,
          questionYourself: questionSelf,
          questionHeaders: {
            left,
            right
          },
          answers,
          recurring
        };

        await editQuestion({
          questionObject: newQuestionObject,
          questionId
        });

        toast.show('Question saved!');
        queryClient.invalidateQueries(COMPANY_QUERY_KEYS.COMPANY);
        queryClient.invalidateQueries(COMPANY_QUERY_KEYS.COMPANY_QUESTIONS);
        close();
      } catch (error) {
        return toast.error(error);
      }
    }
  };

  const archive = async () => {
    try {
      // if this question is archived, check if any categories are left with no active, recurring questions
      const canArchive = commonQuestionsUtils.canArchiveQuestion(
        companyQuestions,
        questionId
      );
      if (!canArchive.success) {
        return toast.show(canArchive.message, 20000);
      }
      const { inCategories } = canArchive;
      const answer = confirm(
        `This question belongs to ${inCategories.length} ${
          inCategories.length > 1 ? 'categories' : 'category'
        }`
          + ` (${inCategories.join(',')}). Are you sure you want to archive it?`
      );
      if (!answer) {
        return;
      }

      toast.show('Archiving question..');
      await archiveQuestion({ ids: [questionId] });

      queryClient.invalidateQueries(COMPANY_QUERY_KEYS.COMPANY);
      queryClient.invalidateQueries(COMPANY_QUERY_KEYS.COMPANY_QUESTIONS);
      close();
      toast.show('Question archived!');
    } catch (error) {
      return toast.error(error);
    }
  };

  const superfill = (stype, subtype) => {
    if (context.answers?.type !== 'Custom Labels') {
      toast.error('Please select custom answers');
      return;
    }
    const likertFive = (index, st) => {
      if (st === 'expectations') {
        if (index === 0) {
          return 'Needs development';
        }
        if (index === 1) {
          return 'Meets expectations';
        }
        if (index === 2) {
          return 'Exceeds expectations';
        }
        if (index === 3) {
          return 'Always exceeds expectations';
        }
        if (index === 4) {
          return 'Sets a new standard';
        }
      }

      if (st === 'agree') {
        if (index === 0) {
          return 'Strongly disagree';
        }
        if (index === 1) {
          return 'Disagree';
        }
        if (index === 2) {
          return 'Neutral';
        }
        if (index === 3) {
          return 'Agree';
        }
        if (index === 4) {
          return 'Strongly agree';
        }
      }
      if (st === 'frequency') {
        if (index === 0) {
          return 'Never';
        }
        if (index === 1) {
          return 'Rarely';
        }
        if (index === 2) {
          return 'Sometimes';
        }
        if (index === 3) {
          return 'Often';
        }
        if (index === 4) {
          return 'Always';
        }
      }
      if (st === 'satisfaction') {
        if (index === 0) {
          return 'Very poor';
        }
        if (index === 1) {
          return 'Poor';
        }
        if (index === 2) {
          return 'Fair';
        }
        if (index === 3) {
          return 'Good';
        }
        if (index === 4) {
          return 'Excellent';
        }
      }
    };

    const livingCitiesFive = (index, st) => {
      if (index === 0) {
        return 'Not meeting expectations: unsatisfactory';
      }
      if (index === 1) {
        return 'Needs development';
      }
      if (index === 2) {
        return 'Meets expectations: satisfactory';
      }
      if (index === 3) {
        return 'Often exceeds expectations';
      }
      if (index === 4) {
        return 'Sets a new standard';
      }
    };

    const likertSeven = (index, st) => {
      if (st === 'agree') {
        if (index === 0) {
          return 'Strongly disagree';
        }
        if (index === 1) {
          return 'Disagree';
        }
        if (index === 2) {
          return 'Somewhat disagree';
        }
        if (index === 3) {
          return 'Neutral';
        }
        if (index === 4) {
          return 'Somewhat agree';
        }
        if (index === 5) {
          return 'Agree';
        }
        if (index === 6) {
          return 'Strongly agree';
        }
      }
      if (st === 'frequency') {
        if (index === 0) {
          return 'Never';
        }
        if (index === 1) {
          return 'Rarely';
        }
        if (index === 2) {
          return 'Occasionally';
        }
        if (index === 3) {
          return 'Sometimes';
        }
        if (index === 4) {
          return 'Frequently';
        }
        if (index === 5) {
          return 'Usually';
        }
        if (index === 6) {
          return 'Always';
        }
      }
      if (st === 'satisfaction') {
        if (index === 0) {
          return 'Terrible';
        }
        if (index === 1) {
          return 'Very poor';
        }
        if (index === 2) {
          return 'Poor';
        }
        if (index === 3) {
          return 'Fair';
        }
        if (index === 4) {
          return 'Good';
        }
        if (index === 5) {
          return 'Very Good';
        }
        if (index === 6) {
          return 'Excellent';
        }
      }
    };

    let newAnswers = null;
    if (stype === 'likert-5') {
      newAnswers = [0, 1, 2, 3, 4].map((c, index) => ({
        id: uuidv4(),
        value: likertFive(index, subtype)
      }));
    }

    if (stype === 'living-cities-5') {
      newAnswers = [0, 1, 2, 3, 4].map((c, index) => ({
        id: uuidv4(),
        value: livingCitiesFive(index, subtype)
      }));

      updateContext('right', 'Sets a new standard');
      updateContext('left', 'Not meeting expectations: unsatisfactory');
    }

    if (stype === 'likert-7') {
      newAnswers = [0, 1, 2, 3, 4, 5, 6].map((c, index) => ({
        id: uuidv4(),
        value: likertSeven(index, subtype)
      }));
    }

    if (stype === 'expectations-5') {
      newAnswers = [0, 1, 2, 3, 4].map((c, index) => ({
        id: uuidv4(),
        value: likertFive(index, subtype)
      }));
    }

    if (stype === 'standard-5') {
      newAnswers = [
        { id: uuidv4(), value: 'Unacceptable' },
        { id: uuidv4(), value: 'Needs improvement' },
        { id: uuidv4(), value: 'Meets expectations' },
        { id: uuidv4(), value: 'Exceeds expectations' },
        { id: uuidv4(), value: 'Sets a new standard' }
      ];

      updateContext('right', 'Sets a new standard');
      updateContext('left', 'Unacceptable');
    }

    if (stype === 'italian-5') {
      newAnswers = [
        { id: uuidv4(), value: 'Molto in disaccordo' },
        { id: uuidv4(), value: 'Abbastanza in disaccordo' },
        { id: uuidv4(), value: 'Né d’accordo né in disaccordo' },
        { id: uuidv4(), value: 'Abbastanza d’accordo' },
        { id: uuidv4(), value: 'Molto d’accordo' }
      ];

      updateContext('right', 'Molto d’accordo');
      updateContext('left', 'Molto in disaccordo');
    }

    if (stype === 'italian-exp') {
      newAnswers = [
        { id: uuidv4(), value: 'Inaccettabile' },
        { id: uuidv4(), value: 'Necessita di sviluppo' },
        { id: uuidv4(), value: 'Soddisfa le aspettative' },
        { id: uuidv4(), value: 'Supera le aspettative' },
        { id: uuidv4(), value: 'Supera sempre le aspettative' }
      ];

      updateContext('right', 'Supera sempre le aspettative');
      updateContext('left', 'Inaccettabile');
    }

    setIsFormInvalid(false);
    updateContext('answers', { ...context.answers, custom: newAnswers });
  };

  return (
    <div>
      <SectionBox
        classes=''
        innerClasses='paddingBottom0'
        loading={
          isFetchingCompanyQuestions || addQuestionLoading || archiveLoading
        }
        sectionType='dash'
      >
        <button onClick={close} className='clear-both float-right'>
          <X_SVG class='width25px height25px' />
        </button>
        <h5 className='font-bold'>
          {type === EDIT_MODES.EDIT ? 'Edit Question' : 'Add Question'}
        </h5>
        <div className='clear-both' />
        <div className='mt-6 mb-12'>
          <div className='inline-block w-6/12 align-top'>
            <div className='inline-block w-6/12 align-top'>
              <p className='mb-4 font-bold'>Review Question Text</p>
              <p className='mb-0'>
                Use
                <span className='bg-gray text-light-blue bold rounded-md pl-1 pr-1'>
                  @name
                </span>
                {' '}
                if including an individual's name in the question.
              </p>
              <p className='mb-0'>
                Use
                <span className='bg-gray text-red bold rounded-md pl-1 pr-1'>
                  @frequency
                </span>
                {' '}
                to specify your company's review period.
              </p>
            </div>
            <textarea
              value={question}
              onChange={(e) => updateContext('question', e.target.value)}
              className='inline-block w-6/12 h-32 align-top'
              placeholder='Question'
            />
            <section className='mt-12'>
              <div className='inline-block w-6/12'>
                <p className='font-bold'>Self-Review Question Text</p>
              </div>
              <textarea
                value={questionSelf}
                onChange={(e) => updateContext('questionSelf', e.target.value)}
                className='inline-block w-6/12 h-32 align-top'
                placeholder='Self Question'
              />
            </section>
            <section className='flex'>
              <div className='w-1/2'>
                <p className='font-bold m-0'>Recurring Question</p>
                <p className='m-0'>
                  If disabled, question will not be used in the ongoing review
                  process - however, it can be manually requested
                </p>
              </div>
              <div className='w-1/2 flex items-center'>
                <Checkbox
                  value={recurring}
                  label='Enabled'
                  classes='font-bold'
                  disabled
                />
              </div>
            </section>
          </div>

          <div className='inline-block w-6/12 align-top'>
            <div className='ml-10'>
              <CustomQuestionAnswers />
              <QuestionPreview />
            </div>
          </div>
        </div>
        {[
          QUESTION_ANSWER_TYPES.ONE_TO_TEN,
          QUESTION_ANSWER_TYPES.ONE_TO_FIVE
        ].includes(answersType) ? (
          <Fragment>
            <p className='font-bold'>Answer Labels</p>
            <div className='mt-6 mb-12'>
              <div className='inline-block w-3/12'>
                <p>High End (10)</p>
              </div>
              <div className='inline-block w-3/12 align-top'>
                <NewInput
                  value={right}
                  onChange={(e) => updateContext('right', e.target.value)}
                  classes='w-full'
                  placeholder='Strongly Agree'
                />
              </div>
            </div>
            <div className='clear-both mt-6 mb-12'>
              <div className='inline-block w-3/12'>
                <p>Low End (1)</p>
              </div>
              <div className='inline-block w-3/12 align-top'>
                <NewInput
                  value={left}
                  onChange={(e) => updateContext('left', e.target.value)}
                  classes='w-full'
                  placeholder='Strongly Disagree'
                />
              </div>
            </div>
          </Fragment>
          ) : null}

        <div className='text-center mt-16'>
          <Button
            disabled={
              isFormInvalid || addQuestionLoading || editQuestionLoading
            }
            onClick={save}
          >
            Save
          </Button>
        </div>
        {type === EDIT_MODES.EDIT ? (
          <div className='text-left'>
            <button
              className='text-base leading-7 text-black'
              onClick={archive}
            >
              Archive Question
            </button>
          </div>
        ) : null}
      </SectionBox>
      {appUtils.isSuperUser() ? (
        <Base>
          <div className='m-3'>
            <div className='border'>
              <p>Superuser quick custom answer fill</p>

              <div className='inline-block mr-2'>
                <Button
                  onClick={() => {
                    superfill('likert-5', 'agree');
                  }}
                >
                  Likert 5 - Agree
                </Button>
              </div>
              <div className='inline-block mr-2'>
                <Button
                  class='inline-block mr-2'
                  onClick={() => {
                    superfill('likert-5', 'frequency');
                  }}
                >
                  Likert 5 - Frequency
                </Button>
              </div>
              <div className='inline-block mr-2'>
                <Button
                  onClick={() => {
                    superfill('likert-5', 'satisfaction');
                  }}
                >
                  Likert 5 - Satisfaction
                </Button>
              </div>
              <div className='inline-block mr-2'>
                <Button
                  onClick={() => {
                    superfill('living-cities-5', 'satisfaction');
                  }}
                >
                  Living Cities 5
                </Button>
              </div>

              <div className='my-3' />
              <div className='inline-block mr-2'>
                <Button
                  onClick={() => {
                    superfill('likert-7', 'agree');
                  }}
                >
                  Likert 7 - Agree
                </Button>
              </div>
              <div className='inline-block mr-2'>
                <Button
                  class='inline-block mr-2'
                  onClick={() => {
                    superfill('likert-7', 'frequency');
                  }}
                >
                  Likert 7 - Frequency
                </Button>
              </div>
              <div className='inline-block mr-2'>
                <Button
                  onClick={() => {
                    superfill('likert-7', 'satisfaction');
                  }}
                >
                  Likert 7 - Satisfaction
                </Button>
              </div>

              <div className='my-3' />
              <div className='inline-block mr-2'>
                <Button
                  onClick={() => {
                    superfill('expectations-5', 'expectations');
                  }}
                >
                  Expectations 5
                </Button>
              </div>

              <div className='my-3' />
              <div className='inline-block mr-2'>
                <Button
                  onClick={() => {
                    superfill('standard-5', '');
                  }}
                >
                  Standard 5
                </Button>
              </div>

              <div className='my-3' />
              <div className='inline-block mr-2'>
                <Button
                  onClick={() => {
                    superfill('italian-5', '');
                  }}
                >
                  Italian 5
                </Button>
              </div>

              <div className='my-3' />
              <div className='inline-block mr-2'>
                <Button
                  onClick={() => {
                    superfill('italian-exp', '');
                  }}
                >
                  Italian Exp
                </Button>
              </div>
            </div>
          </div>
        </Base>
      ) : null}
    </div>
  );
};

export default NewQuestion;
