import { h } from 'preact';
import { get } from 'lodash';
import { Router, route } from 'preact-router';
import { useContext } from 'preact/hooks';
import { useQueryClient } from 'react-query';
import {
  SignUp,
  Dashboard,
  Success,
  Home,
  Page404,
  DocumentsRedirect,
  IntroRedirect,
  RedirectPage,
  Slack,
  SlackSignUp,
  PerformanceBuilder
} from 'src/pages';
import { useFlags } from 'launchdarkly-react-client-sdk';
import appUtils from 'src/components/appUtils';
import AppRoute from 'src/components/AppRoute';
import {
  Onboarding,
  UserProfile as UserProfileV2,
  Team,
  Organization,
  Dash,
  DashTeam,
  DashCompany,
  Settings,
  DirectReview,
  Sandbox,
  EmailReview,
  EmailGoal,
  Pulse,
  PulseQuestionResults,
  UserReport,
  Goals,
  Kudos,
  GoalSettings,
  NotificationSettings,
  NotifyRevieweeParticipation,
  ReportTemplates,
  NewUserReport,
  NewUserReportFeedback,
  NewUserReportPerformanceCategories,
  NewUserReportGoalsAndObjectives,
  NewUserReportSummary,
  NewUserReportPreview,
  NewUserReportShare,
  PulseQuestionSettings
} from 'src/pagesDashboard';
import OnboardingSetup from 'src/pagesDashboard/Onboarding/OnboardingSetup';
import OnboardingVideo from 'src/pagesDashboard/Onboarding/OnboardingVideo';
import OnboardingComms from 'src/pagesDashboard/Onboarding/OnboardingComms';
import OnboardingMSTeams from 'src/pagesDashboard/Onboarding/OnboardingMSTeams';
import OnboardingSlack from 'src/pagesDashboard/Onboarding/OnboardingSlack';
import OnboardingWebex from 'src/pagesDashboard/Onboarding/OnboardingWebex';
import OnboardingComplete from 'src/pagesDashboard/Onboarding/OnboardingComplete';
import EmailPulseReview from 'src/pagesDashboard/EmailPulseReview/EmailPulseReview';

import UserProfileProvider from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import TeamProvider from 'src/pagesDashboard/Team/context/TeamProvider';
import GoalsProvider from 'src/context/Goals/GoalsProvider';
import OrganizationProvider from 'src/pagesDashboard/Organization/context/OrganizationProvider';
import DashProvider from 'src/pagesDashboard/Dash/context/DashProvider';
import CompanyDashProvider from 'src/pagesDashboard/CompanyDash/context/Provider';
import DashTeamProvider from 'src/pagesDashboard/DashTeam/context/DashTeamProvider';
import SettingsProvider from 'src/pagesDashboard/Settings/context/SettingsProvider';
import DirectReviewProvider from 'src/pagesDashboard/DirectReview/context/DirectReviewProvider';
import SandboxProvider from 'src/pagesDashboard/Sandbox/context/SandboxProvider';
import PulseProvider from 'src/pagesDashboard/Pulse/context/PulseProvider';
import PulseQuestionResultsProvider from 'src/pagesDashboard/PulseQuestionResults/context/PulseQuestionResultsProvider';
import NewUserReportProvider from 'src/pagesDashboard/NewUserReport/context/NewUserReportProvider';

import AppProvider, { AppContext } from 'src/app/context/AppProvider';
import { updateData } from 'src/app/context/actions';
import ReviewsDue from 'src/pagesDashboard/ReviewsDue/ReviewsDue';
import SubmitFeedback from 'src/containers/SubmitOrRequestFeedback/SubmitFeedback';
import RequestFeedback from 'src/containers/SubmitOrRequestFeedback/RequestFeedback';
import SelectGiveFeedback from 'src/pagesDashboard/SelectFeedback/SelectGiveFeedback';
import SelectRequestFeedback from 'src/pagesDashboard/SelectFeedback/SelectRequestFeedback';

import Admin from 'src/containers/Admin/Admin';
import AdminStats from 'src/pagesDashboard/AdminStats/AdminStats';
import AdminEvents from 'src/pagesDashboard/AdminEvents/AdminEvents';
import Billing from 'src/pagesDashboard/Billing/Billing';
import QuestionBuilder from 'src/pagesDashboard/QuestionBuilder/QuestionBuilder';
import OrganizationSettings from 'src/pagesDashboard/OrganizationSettings/OrganizationSettings';
import FeedbackDashboard from 'src/containers/UserProfile/FeedbackDashboard/FeedbackDashboard';
import UserDashOverviewTab from 'src/containers/UserProfile/Overview/Overview';
import UserDashActivityTab from 'src/containers/UserProfile/Activity/Activity';
import MyDashAnalyticsTab from 'src/containers/Dash/UserFeedback/UserFeedback';
import UserDashAnalyticsTab from 'src/containers/UserProfile/UserFeedback/UserFeedback';
import MyDashReportsTab from 'src/containers/Dash/MyReports/MyReports';
import UserDashReportsTab from 'src/containers/UserProfile/Reports/Reports';
import UserDashGoalsTab from 'src/containers/UserProfile/Goals/Goals';
import UserDashAiAssistTab from 'src/containers/UserProfile/AiAssist/AiAssist';
import UserDashProfileTab from 'src/containers/UserProfile/Profile/Profile';
import CompanyDashOverviewTab from 'src/pagesDashboard/CompanyDash/components/OrganizationOverview';
import CompanyDashReportsTab from 'src/pagesDashboard/CompanyDash/components/OrganizationReports';
import BulkReports from 'src/pagesDashboard/CompanyDash/components/BulkReports';
import ReviewDetails from 'src/containers/Reviews/ReviewDetails';
import FeedbackDetails from 'src/containers/Feedback/FeedbackDetails/FeedbackDetails';
import EditReview from 'src/containers/Reviews/EditReview';
import RequestReview from 'src/containers/Reviews/RequestReview';
import NewUserReportLayout from 'src/pagesDashboard/NewUserReport/layouts/NewUserReportLayout';
import PAGE_TYPES from 'src/constants/pageTypes';
import OrganizationEvents from 'src/pagesDashboard/OrganizationSettings/OrganizationEvents';
import COMMON_CONSTANTS from 'common/commonConstants';
import TaskList from 'src/pages/TaskList/TaskList';
import NotificationList from 'src/pages/NotificationList/NotificationList';
import PulseReviewsDue from 'src/pagesDashboard/PulseReviewDue/PulseReviewDue';
import HomeComponent from 'src/pagesDashboard/HomePage/HomePage';
import DirectFeedback from 'src/pagesDashboard/DirectFeedback/DirectFeedback';
import EditFeedback from 'src/containers/Feedback/FeedbackDetails/EditFeedback';
import { PerformanceBuilderProvider } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import BuilderErrorBoundary from 'src/pages/PerformanceBuilder/BuilderErrorBoundary';
import { ErrorUI } from 'src/components/index';
import TransitionReview from '../pagesDashboard/TransitionReview/TransitionReview';

const {
  SIDEBAR_PAGES: {
    HOME,
    INBOX,
    DASHBOARDS,
    TRACKING,
    PEOPLE,
    PROFILE,
    ORGANIZATION
  }
} = COMMON_CONSTANTS;

const DashboardRedirector = () => route(appUtils.getDashRoute());

const isAdPage = () => /^\/$/.test(window.location.pathname);

const DashPage = (props) => (
  <DashProvider>
    <Dash {...props} />
  </DashProvider>
);

const TaskListPage = (props) => <TaskList {...props} />;

const NotificationListPage = (props) => <NotificationList {...props} />;

const HomePage = (props) => <HomeComponent {...props} />;

const FeedbackDashboardPage = (props) => (
  <DashProvider>
    <Dash {...props}>
      <FeedbackDashboard />
    </Dash>
  </DashProvider>
);

const DashOverviewPage = (props) => (
  <DashProvider>
    <Dash {...props}>
      <UserDashOverviewTab />
    </Dash>
  </DashProvider>
);

const DashParticipationPage = (props) => (
  <DashProvider>
    <Dash {...props}>
      <UserDashActivityTab />
    </Dash>
  </DashProvider>
);

const DashAnalytics = (props) => (
  <DashProvider>
    <Dash {...props}>
      <MyDashAnalyticsTab />
    </Dash>
  </DashProvider>
);

const DashReportsPage = (props) => (
  <DashProvider>
    <Dash {...props}>
      <MyDashReportsTab />
    </Dash>
  </DashProvider>
);

const DashGoalsPage = (props) => (
  <DashProvider>
    <Dash {...props}>
      <UserDashGoalsTab />
    </Dash>
  </DashProvider>
);

const DashAiAssistPage = (props) => (
  <DashProvider>
    <Dash {...props}>
      <UserDashAiAssistTab />
    </Dash>
  </DashProvider>
);

const DashProfilePage = (props) => (
  <DashProvider>
    <Dash {...props}>
      <UserDashProfileTab />
    </Dash>
  </DashProvider>
);

const CompanyDashOverviewPage = (props) => (
  <CompanyDashProvider>
    <DashCompany {...props}>
      <CompanyDashOverviewTab />
    </DashCompany>
  </CompanyDashProvider>
);

const CompanyDashReportsPage = (props) => (
  <CompanyDashProvider>
    <DashCompany {...props}>
      <CompanyDashReportsTab />
    </DashCompany>
  </CompanyDashProvider>
);

const CompanyDashCreateReportsPage = (props) => (
  <CompanyDashProvider>
    <DashCompany {...props}>
      <BulkReports />
    </DashCompany>
  </CompanyDashProvider>
);

const GoalsPage = (props) => (
  <GoalsProvider>
    <Goals {...props} />
  </GoalsProvider>
);

const DashTeamPage = (props) => (
  <DashTeamProvider>
    <DashTeam {...props} />
  </DashTeamProvider>
);

const OrganizationPage = (props) => (
  <OrganizationProvider>
    <Organization {...props} />
  </OrganizationProvider>
);

const UserProfileFeedbackPage = (props) => (
  <UserProfileProvider>
    <UserProfileV2 props={props}>
      <FeedbackDashboard />
    </UserProfileV2>
  </UserProfileProvider>
);

const UserProfileOverviewPage = (props) => (
  <UserProfileProvider>
    <UserProfileV2 props={props}>
      <UserDashOverviewTab />
    </UserProfileV2>
  </UserProfileProvider>
);

const UserProfileParticipationPage = (props) => (
  <UserProfileProvider>
    <UserProfileV2 props={props}>
      <UserDashActivityTab />
    </UserProfileV2>
  </UserProfileProvider>
);

const UserProfileAnalyticsPage = (props) => (
  <UserProfileProvider>
    <UserProfileV2 props={props}>
      <UserDashAnalyticsTab />
    </UserProfileV2>
  </UserProfileProvider>
);

const UserProfileReportsPage = (props) => (
  <UserProfileProvider>
    <UserProfileV2 props={props}>
      <UserDashReportsTab />
    </UserProfileV2>
  </UserProfileProvider>
);

const UserProfileSettingsPage = (props) => (
  <UserProfileProvider>
    <UserProfileV2 props={props}>
      <UserDashProfileTab />
    </UserProfileV2>
  </UserProfileProvider>
);

const UserProfileGoalsPage = (props) => (
  <UserProfileProvider>
    <UserProfileV2 props={props}>
      <UserDashGoalsTab />
    </UserProfileV2>
  </UserProfileProvider>
);

const UserProfileAiAssistPage = (props) => (
  <UserProfileProvider>
    <UserProfileV2 props={props}>
      <UserDashAiAssistTab />
    </UserProfileV2>
  </UserProfileProvider>
);

const UserReportPage = (props) => (
  <UserProfileProvider>
    <UserReport {...props} />
  </UserProfileProvider>
);

const TeamPage = (props) => (
  <TeamProvider>
    <Team props={props} />
  </TeamProvider>
);

const ReviewViewPage = (props) => <ReviewDetails {...props} />;

const FeedbackViewPage = (props) => <FeedbackDetails {...props} />;

const ReviewEditPage = (props) => <EditReview {...props} />;

const FeedbackEditPage = (props) => <EditFeedback {...props} />;

const RequestReviewPage = (props) => <RequestReview {...props} />;

const SettingsPage = (props) => (
  <SettingsProvider>
    <Settings {...props} />
  </SettingsProvider>
);

const NotificationSettingsPage = (props) => <NotificationSettings {...props} />;

const DirectReviewPage = (props) => (
  <DirectReviewProvider>
    <DirectReview {...props} />
  </DirectReviewProvider>
);

const SandboxPage = (props) => (
  <SandboxProvider>
    <Sandbox {...props} />
  </SandboxProvider>
);

const PulsePage = (props) => (
  <PulseProvider>
    <Pulse {...props} />
  </PulseProvider>
);

const PulseQuestionResultsPage = (props) => (
  <PulseQuestionResultsProvider>
    <PulseQuestionResults {...props} />
  </PulseQuestionResultsProvider>
);

const EmailReviewPage = (props) => <EmailReview {...props} />;
const EmailGoalPage = (props) => <EmailGoal {...props} />;

const EmailReviewTransitionPage = (props) => <TransitionReview {...props} />;

const EmailPulsePage = (props) => <EmailPulseReview {...props} />;

const ViewGoalPage = (props) => <GoalSettings {...props} mode='view' />;
const EditGoalPage = (props) => <GoalSettings {...props} mode='edit' />;
const NewGoalPage = (props) => <GoalSettings {...props} mode='new' />;

const EditReportTemplate = (props) => <ReportTemplates {...props} />;

const PerformanceBuilderPage = (props) => (
  <PerformanceBuilderProvider>
    <BuilderErrorBoundary {...props}>
      <Router>
        <PerformanceBuilder page='builder' {...props} path='/builder' />
        <PerformanceBuilder
          page='edit'
          {...props}
          path='/builder/:editToken/edit'
        />
        <PerformanceBuilder
          page='view'
          {...props}
          path='/builder/:reportId/view'
        />
        <PerformanceBuilder
          page='dashboard'
          {...props}
          path='/builder/dashboard'
        />
      </Router>
    </BuilderErrorBoundary>
  </PerformanceBuilderProvider>
);

const NewReportsFlow = (props) => (
  <NewUserReportProvider>
    <UserProfileProvider>
      <DashProvider>
        <NewUserReportLayout {...props}>
          <Router>
            <NewUserReport
              path='/dashboard/profile/:userId/report/new'
              {...props}
            />
            <NewUserReport
              path='/dashboard/profile/:userId/report/:reportId'
              {...props}
            />
            <NewUserReportFeedback
              path='/dashboard/profile/:userId/report/:reportId/open-feedback'
              {...props}
            />
            <NewUserReportPerformanceCategories
              path='/dashboard/profile/:userId/report/:reportId/performance-categories'
              {...props}
            />
            <NewUserReportGoalsAndObjectives
              path='/dashboard/profile/:userId/report/:reportId/goals-and-objectives'
              {...props}
            />
            <NewUserReportSummary
              path='/dashboard/profile/:userId/report/:reportId/performance-summary'
              {...props}
            />
            <NewUserReportPreview
              path='/dashboard/profile/:userId/report/:reportId/preview'
              {...props}
            />
            <NewUserReportShare
              path='/dashboard/profile/:userId/report/:reportId/share'
              {...props}
            />
          </Router>
        </NewUserReportLayout>
      </DashProvider>
    </UserProfileProvider>
  </NewUserReportProvider>
);

const EditPulsePage = (props) => (
  <PulseQuestionSettings {...props} mode='edit' />
);
const NewPulsePage = (props) => <PulseQuestionSettings {...props} mode='new' />;

const SubmitFeedbackFlow = (props) => (
  <SubmitFeedback {...props}>
    <Router>
      <SelectGiveFeedback
        path='/dashboard/submit-feedback/feedback'
        key='feedback'
        {...props}
      />
      <SelectGiveFeedback
        path='/dashboard/submit-feedback/review'
        key='review'
        {...props}
      />
    </Router>
  </SubmitFeedback>
);

const RequestFeedbackFlow = (props) => (
  <RequestFeedback {...props}>
    <Router>
      <SelectRequestFeedback
        path='/dashboard/request-feedback/feedback'
        key='feedback'
        {...props}
      />
      <SelectRequestFeedback
        path='/dashboard/request-feedback/review'
        key='review'
        {...props}
      />
    </Router>
  </RequestFeedback>
);

const Blank = () => null;

const redirectGetSpeedBackToWorkStory = () => {
  try {
    const { pathname } = window.location;
    const { search } = window.location;

    if (pathname === '/dashboard/user' || pathname === '/dashboard/user/') {
      console.log('pathname is dashboard/user so do NOT redirect');
      return;
    }

    if (appUtils.isOnGetSpeedback()) {
      console.log('REDIRECTING to workstory');
      const newUrl = `https://app.workstory.team${pathname}${search}`;
      window.location.replace(newUrl);
    }
  } catch (error) {
    return null;
  }
};

const Routes = () => {
  const { globalContext, dispatch } = useContext(AppContext);

  const handleRoute = (e) => {
    const pageRoute = get(e, 'current.props.path');
    if (e && e.current && e.current.props) {
      // getSubdomain();
      if (e.current.props.url === '/dashboard/login') {
        return window.location.replace('/login');
      }
      redirectGetSpeedBackToWorkStory();

      if (!pageRoute) {
        return;
      }

      if (
        pageRoute === '/'
        || pageRoute === '/360-reviews'
        || pageRoute === '/feedback'
        || pageRoute === '/performance-reviews'
        || pageRoute === '/best-practices'
        || pageRoute === '/transparency'
        || pageRoute === '/slack'
        || pageRoute === '/blogs'
        || pageRoute === '/knowledge'
        || pageRoute === '/terms'
        || pageRoute === '/privacy'
        || pageRoute.match(/\/faq/)
      ) {
        if (process.env.NODE_ENV === 'production') {
          return window.location.replace('https://home.workstory.team/');
        }
        return window.location.replace('/login');
      }
    }
    window.scrollTo(0, 0);
    if (e && e.current && e.current.props && e.current.props.matches) {
      dispatch(
        updateData({
          matches: e.current.props.matches,
          props: e.current.props
        })
      );
    }
  };

  const isSuperUser = appUtils.isSuperUser();
  const queryClient = useQueryClient();

  const { showDashboardItems } = useFlags();

  return (
    <Router onChange={handleRoute}>
      {/* <Home path="/" /> */}
      <Blank path='/' />
      <Home path='/ref/:reference' />

      <Home path='/home' />
      <Home path='/home/ref/:reference' />

      <AppRoute path='/app/:*?' />

      <Onboarding path='/dashboard/get-started/' />

      <OnboardingSetup path='/dashboard/onboarding/:inviteToken' />
      <OnboardingVideo path='/dashboard/onboarding/video' />
      <OnboardingComms path='/dashboard/onboarding/comms' />
      <OnboardingMSTeams path='/dashboard/onboarding/msteams/:step' />
      <OnboardingSlack path='/dashboard/onboarding/slack' />
      <OnboardingWebex path='/dashboard/onboarding/webex' />
      <OnboardingComplete path='/dashboard/onboarding/complete' />

      <SignUp
        path='/sign-up/'
        props={{ queryCache: queryClient }}
        pageType={PAGE_TYPES.SIGNUP}
      />
      <SignUp
        path='/login/'
        props={{ queryCache: queryClient }}
        pageType={PAGE_TYPES.LOGIN}
      />
      <SignUp
        path='/forgot-password/'
        props={{ queryCache: queryClient }}
        pageType={PAGE_TYPES.FORGOT_PASSWORD}
      />
      <SignUp
        path='/recover-password/:token'
        props={{ queryCache: queryClient }}
        pageType={PAGE_TYPES.RECOVER_PASSWORD}
      />
      <SignUp
        path='/invite/:inviteToken'
        props={{ queryCache: queryClient }}
        pageType={PAGE_TYPES.INVITED_USER}
      />

      <Dashboard path='/dashboard/home' component={HomePage} page={HOME} />

      <Dashboard
        path='/dashboard/inbox/tasks'
        page={INBOX.TASKS}
        component={TaskListPage}
      />

      {appUtils.isNotificationsEnabled() ? (
        <Dashboard
          path='/dashboard/inbox/notifications'
          page={INBOX.NOTIFICATIONS}
          component={NotificationListPage}
        />
      ) : null}

      <Dashboard
        path='/dashboard/inbox/reviews'
        page={INBOX.REVIEWS_DUE}
        component={ReviewsDue}
      />

      <Dashboard
        path='/dashboard/inbox/pulse'
        page={INBOX.PULSE_DUE}
        component={PulseReviewsDue}
      />

      {/* <Dashboard
        path='/dashboard/inbox/pulse'
        page={INBOX.PULSE_DUE}
        component={PulseDue}
      /> */}

      <Dashboard
        path='/dashboard/kudos'
        component={Kudos}
        page='dashboard-kudos'
      />
      <Dashboard path='/dashboard/reviews/due' component={ReviewsDue} />

      <Dashboard
        path='/dashboard/submit-feedback/:rest*'
        component={SubmitFeedbackFlow}
      />

      <Dashboard
        path='/dashboard/request-feedback/:rest*'
        component={RequestFeedbackFlow}
      />

      <Dashboard
        path='/dashboard/organization/chart/:*?'
        component={OrganizationPage}
        page={ORGANIZATION.ORGANIZATION_CHART}
      />

      <Dashboard
        path='/dashboard/profile/:id'
        component={UserProfileAnalyticsPage}
      />
      {appUtils.getIsFeedbackTabEnabled() ? (
        <Dashboard
          path='/dashboard/profile/:id/feedback'
          component={UserProfileFeedbackPage}
        />
      ) : null}
      <Dashboard
        path='/dashboard/profile/:id/overview'
        component={UserProfileOverviewPage}
      />
      {showDashboardItems ? (
        <Dashboard
          path='/dashboard/profile/:id/participation'
          component={UserProfileParticipationPage}
        />
      ) : null}
      <Dashboard
        path='/dashboard/profile/:id/analytics'
        component={UserProfileAnalyticsPage}
      />
      <Dashboard
        path='/dashboard/profile/:id/reports'
        component={UserProfileReportsPage}
      />
      <Dashboard
        path='/dashboard/profile/:id/information/:rest*'
        component={UserProfileSettingsPage}
      />
      {showDashboardItems ? (
        <Dashboard
          path='/dashboard/profile/:id/goals'
          component={UserProfileGoalsPage}
        />
      ) : null}
      <Dashboard
        path='/dashboard/profile/:id/ai'
        component={UserProfileAiAssistPage}
      />
      {!appUtils.isNewReportFlowEnabled() ? (
        <Dashboard
          path='/dashboard/profile/:id/report/:reportId'
          component={UserReportPage}
        />
      ) : null}
      <Dashboard
        path='/dashboard/people'
        component={TeamPage}
        page={PEOPLE.ALL_PEOPLE}
      />
      <Dashboard
        path='/dashboard/goal/view/:userId/:goalId'
        component={ViewGoalPage}
      />
      <Dashboard
        path='/dashboard/goal/edit/:userId/:goalId'
        component={EditGoalPage}
      />
      <Dashboard path='/dashboard/goal/new/:userId' component={NewGoalPage} />
      <Dashboard
        path='/dashboard'
        component={DashboardRedirector}
        page={DASHBOARDS.MY_DASHBOARD}
      />
      {appUtils.getIsFeedbackTabEnabled() ? (
        <Dashboard
          path='/dashboard/me/feedback'
          component={FeedbackDashboardPage}
          page={DASHBOARDS.MY_DASHBOARD}
        />
      ) : null}
      <Dashboard
        path='/dashboard/me/overview'
        component={DashOverviewPage}
        page={DASHBOARDS.MY_DASHBOARD}
      />
      <Dashboard
        path='/dashboard/me/participation'
        page={DASHBOARDS.MY_DASHBOARD}
        component={DashParticipationPage}
      />
      <Dashboard
        path='/dashboard/me'
        component={DashAnalytics}
        page={DASHBOARDS.MY_DASHBOARD}
      />
      <Dashboard
        path='/dashboard/me/analytics'
        component={DashAnalytics}
        page={DASHBOARDS.MY_DASHBOARD}
      />
      <Dashboard
        path='/dashboard/me/reports'
        component={DashReportsPage}
        page={DASHBOARDS.MY_DASHBOARD}
      />
      <Dashboard
        path='/dashboard/me/goals'
        component={DashGoalsPage}
        page={DASHBOARDS.MY_DASHBOARD}
      />
      <Dashboard
        path='/dashboard/me/ai'
        component={DashAiAssistPage}
        page={DASHBOARDS.MY_DASHBOARD}
      />
      <Dashboard
        path='/dashboard/me/information/:rest*'
        component={DashProfilePage}
        page={DASHBOARDS.MY_DASHBOARD}
      />
      <Dashboard
        path='/dashboard/me/:rest*'
        component={DashboardRedirector}
        page={DASHBOARDS.MY_DASHBOARD}
      />

      <Dashboard
        path='/dashboard/team'
        component={DashTeamPage}
        page={DASHBOARDS.TEAM_DASHBOARD}
      />

      <Dashboard
        path='/dashboard/organization'
        component={CompanyDashOverviewPage}
        page={DASHBOARDS.ORGANIZATION_DASHBOARD}
      />
      <Dashboard
        path='/dashboard/organization/overview'
        component={CompanyDashOverviewPage}
        page={DASHBOARDS.ORGANIZATION_DASHBOARD}
      />
      <Dashboard
        path='/dashboard/organization/reports'
        component={CompanyDashReportsPage}
        page={DASHBOARDS.ORGANIZATION_DASHBOARD}
      />
      <Dashboard
        path='/dashboard/organization/reports/create'
        component={CompanyDashCreateReportsPage}
        page={DASHBOARDS.ORGANIZATION_DASHBOARD}
      />

      <Dashboard
        path='/dashboard/goals'
        component={GoalsPage}
        page={TRACKING.GOALS}
      />

      <Dashboard
        path='/dashboard/settings'
        component={SettingsPage}
        page={PROFILE.MY_SETTINGS}
      />
      <Dashboard
        path='/dashboard/settings/notifications'
        component={NotificationSettingsPage}
        page={PROFILE.MY_NOTIFICATIONS}
      />

      <Dashboard
        path='/dashboard/pulse/:*?'
        component={PulsePage}
        page={TRACKING.QUESTIONS}
      />

      <Dashboard path='/dashboard/pulse/new' component={NewPulsePage} />
      <Dashboard
        path='/dashboard/pulse/edit/:pulseQuestionId'
        component={EditPulsePage}
      />
      <Dashboard
        path='/dashboard/pulse/:questionId'
        component={PulseQuestionResultsPage}
        page={TRACKING.QUESTIONS}
      />
      <Dashboard
        path='/dashboard/reviews/notify-participation/:revieweeId'
        component={NotifyRevieweeParticipation}
      />
      <Dashboard
        path='/dashboard/reportTemplate/add'
        component={EditReportTemplate}
      />
      <Dashboard
        path='/dashboard/reportTemplate/edit/:templateId'
        component={EditReportTemplate}
      />

      <Dashboard
        path='/dashboard/reviews/view/:reviewId'
        component={ReviewViewPage}
      />

      <Dashboard
        path='/dashboard/feedback/view/:feedbackId'
        component={FeedbackViewPage}
      />

      <Dashboard
        path='/dashboard/reviews/edit/:reviewId'
        component={ReviewEditPage}
      />

      <Dashboard
        path='/dashboard/feedback/edit/:feedbackId'
        component={FeedbackEditPage}
      />

      <Dashboard
        path='/dashboard/review/request/:revieweeId'
        component={RequestReviewPage}
      />

      <Dashboard
        path='/dashboard/submit-review/:revieweeId/:roleId/:questions'
        component={DirectReviewPage}
      />

      <Dashboard
        path='/dashboard/submit-feedback/:revieweeId/feedback'
        component={DirectFeedback}
      />

      <EmailReviewPage path='/dashboard/email-review/:id/:answerId/:score/:companyid' />
      <EmailReviewTransitionPage path='/dashboard/email-review-transition' />
      <EmailGoalPage path='/dashboard/email-goal/:goalId/:assignee/:companyid/:answerId' />
      <EmailPulsePage path='/dashboard/pulse-review/:pulseId/:score/:label/:companyid/' />
      <SandboxPage path='/sandbox/new/:email/:name?/:company?' page='new' />
      <SandboxPage path='/sandbox/start/:email/:name?/:company?' page='start' />
      <SandboxPage path='/sandbox/review' page='review' />
      <SandboxPage path='/sandbox/results' page='results' />
      <SandboxPage path='/sandbox/organization' page='organization' />
      <Dashboard
        path='/dashboard/billing/'
        component={Billing}
        page='billing'
      />

      {appUtils.isNewReportFlowEnabled() ? (
        <Dashboard
          path='/dashboard/profile/:userId/report/:rest*'
          component={NewReportsFlow}
          parentPage='team'
          sidebar={false}
        />
      ) : null}

      {isSuperUser ? (
        <Dashboard path='/dashboard/admin' component={Admin} />
      ) : null}
      {isSuperUser ? (
        <Dashboard path='/dashboard/admin/stats' component={AdminStats} />
      ) : null}
      {isSuperUser ? (
        <Dashboard path='/dashboard/admin/events' component={AdminEvents} />
      ) : null}
      <Dashboard
        path='/dashboard/organization/questions'
        component={QuestionBuilder}
        page={ORGANIZATION.ORGANIZATION_QUESTIONS}
      />
      <Dashboard
        path='/dashboard/organization/settings'
        component={OrganizationSettings}
        page={ORGANIZATION.ORGANIZATION_SETTINGS}
      />
      <Dashboard
        path='/dashboard/organization/events'
        component={OrganizationEvents}
        page={ORGANIZATION.ORGANIZATION_EVENTS}
      />
      <Success path='/success/' pageType={PAGE_TYPES.CREATED_ACCOUNT} />
      <Success path='/success/' pageType={PAGE_TYPES.LOGGED_INTO_ACCOUNT} />
      <Success path='/verify/:token' pageType={PAGE_TYPES.VERIFY_EMAIL} />
      <Success path='/saved-password/' pageType={PAGE_TYPES.UPDATED_PASSWORD} />
      <Success
        path='/recovery-email/'
        pageType={PAGE_TYPES.SENT_RECOVERY_PASS_EMAIL}
      />
      <Slack path='/slack/' />
      <SlackSignUp path='/slack-intro/' />
      <DocumentsRedirect path='/beta/:name' />
      <IntroRedirect path='/intro/' />
      <RedirectPage path='/b/:name' />
      <ErrorUI default />
      <PerformanceBuilderPage path='/builder/:rest*' />
    </Router>
  );
};

const RoutesPage = (props) => (
  <AppProvider>
    <Routes {...props} />
  </AppProvider>
);

export default RoutesPage;
