import { h, Fragment, Component } from 'preact';
import { connect } from 'react-redux';
import { route } from 'preact-router';
import COMMON_CONSTANTS from 'common/commonConstants';
import { Header } from '../../containers';
import { SectionBox, Button } from '../../components';
import { Companies, ThreeColumns } from '../../componentsFront';
import { DATA } from '../../constants';
import SHARED_CONSTANTS from '../../common/sharedConstants';
import { appThunks } from '../../thunks';
import appUtils from '../../components/appUtils';

const { HELLO_WORKSTORY_EMAIL } = COMMON_CONSTANTS;

const {
  IMAGES,
  TEXT,
  TEXT: { GEN }
} = DATA;

class Slack extends Component {
  constructor(props) {
    super(props);
    if (this.props.matches && this.props.matches.reference) {
      const refId = this.props.matches.reference;
      route('/');
      appThunks.sendMessageToAdmins({
        source: 'Reference visited!',
        name: refId,
        email: refId,
        description: refId,
        random: refId,
        phone: refId
      })();
    }
  }

  clickLandingActionButton = () => {
    const {
      app: { userLogged }
    } = this.props;
    if (userLogged) {
      if (appUtils.showOrganizationIntroSection(userLogged)) {
        return route('/dashboard/get-started/');
      }
      return route(appUtils.getDashRoute());
    }
    route('/sign-up/');
  };

  render() {
    const {
      app: { userLogged }
    } = this.props;

    return (
      <Fragment>
        <SectionBox
          width='full'
          classes='paddingTop0 paddingBottom50 paddingBottom20sm marginBottom0 border0'
          innerClasses='width97per'
        >
          <Header />
          <div class='row width90lg margin0Auto'>
            <div class='col-md-8 col-sm-16 marginBottom50md'>
              <div class='paddingLeft7per paddingLeft0md marginLeft0md '>
                <h1 class='marginTop12per marginTop0md text-center-md'>
                  {TEXT.HOME.USE_SLACK}
                </h1>
                <p class='text-center-md width90lg'>
                  {TEXT.HOME.MEASURE_PRODUCTIVITY}
                  <br />
                  <br />
                  <b>
                    Get started and you will be able to add WorkStory to your
                    Slack workspace.
                  </b>
                </p>
                <div class='text-center-md'>
                  <a
                    onClick={this.clickLandingActionButton}
                    href='javascript: void(0)'
                    class='marginTop15sm button-red'
                  >
                    {userLogged ? TEXT.APP.DASHBOARD : GEN.LETS_START}
                  </a>
                  <a href='/demo' class='marginTop15sm button-transparent'>
                    {GEN.SEE_HOW_IT_WORKS}
                  </a>
                </div>
              </div>
            </div>
            <div class='col-md-8 col-sm-16 text-center'>
              <img class='maxWidth100' src={TEXT.HOME.HEADLINE_IMAGE} />
            </div>
          </div>
        </SectionBox>

        <SectionBox
          classes='text-center marginBottom0 border0'
          innerClasses=''
          width='full'
        >
          <div class='width90lg margin0Auto'>
            <h2 class='dark-grey'>{TEXT.HOME.HERO_HEADLINE}</h2>
            <h4 class='dark-grey fontWeightNormal marginBottom30'>
              {TEXT.HOME.HERO_SUB}
            </h4>
            <div class='row text-center'>
              <Companies />
            </div>
            <div class='width80per margin0Auto'>
              <h2 class='paddingBottom0 marginTop50 marginBottom0'>
                {TEXT.HOME.HERO_UNDER}
              </h2>
            </div>
          </div>
        </SectionBox>

        <SectionBox
          classes='text-center marginBottom0 paddingBottom0 paddingTop0md paddingBottom0sm border0 paddingTop0md'
          innerClasses='row'
          width='full'
        >
          <div class='width90lg margin0Auto'>
            <div class='visible-xs visible-sm paddingBottom20sm col-md-8 col-sm-16 text-left text-center-md'>
              <h2 class='marginTop13per marginTop0sm'>
                {TEXT.HOME.GIVE_EMPLOYEES_FEEDBACK}
              </h2>
              <h5>{TEXT.HOME.WE_PROMPT_YOU}</h5>
            </div>
            <div class='col-md-8 col-sm-16'>
              <img class='maxWidth100' src={IMAGES.QUESTIONS} />
            </div>
            <div class='hidden-xs hidden-sm col-md-8 col-sm-16 text-left text-center-md'>
              <h2 class='marginTop13per'>
                {TEXT.HOME.GIVE_EMPLOYEES_FEEDBACK}
              </h2>
              <h5>{TEXT.HOME.WE_PROMPT_YOU}</h5>
            </div>
          </div>
        </SectionBox>

        <SectionBox
          classes='text-center marginBottom0 paddingBottom0 paddingBottom0sm border0 paddingTop0md'
          innerClasses='row'
          width='full'
        >
          <div class='width90lg margin0Auto'>
            <div class='col-md-8 col-xs-16 text-left text-center-md'>
              <div class='paddingLeft7per paddingLeft0md'>
                <h2 class='marginTop16per marginTop10perlg marginTop8permd'>
                  {TEXT.HOME.BETTER_REVIEWS}
                </h2>
                <h5>{TEXT.HOME.BETTER_REVIEWS_DESC}</h5>
              </div>
            </div>
            <div class='col-md-8 col-xs-16'>
              <img class='maxWidth100' src={IMAGES.EVALUATION_QUESTIONS} />
            </div>
          </div>
        </SectionBox>

        <SectionBox
          classes='text-center marginBottom0 border0 paddingTop0md'
          innerClasses='row'
          width='full'
        >
          <div class='width90lg margin0Auto'>
            <div class='visible-xs visible-sm col-md-8 col-xs-16 text-left text-center-md paddingBottom70sm'>
              <h2 class='marginTop70'>{TEXT.HOME.TRANSPARENCY}</h2>
              <h5>{TEXT.HOME.TRANSPARENCY_DESC}</h5>
            </div>
            <div class='col-md-8 col-xs-16'>
              <img class='maxWidth100' src={IMAGES.SLACK} />
            </div>
            <div class='hidden-xs hidden-sm col-md-8 col-xs-16 text-left'>
              <h2 class='marginTop15per marginTop13perlg marginTop8permd'>
                {TEXT.HOME.TRANSPARENCY}
              </h2>
              <h5>{TEXT.HOME.TRANSPARENCY_DESC}</h5>
            </div>
          </div>
        </SectionBox>

        <SectionBox
          classes='text-center marginBottom0 paddingTop0 paddingTop0md paddingBottom0sm border0'
          innerClasses='row'
          width='full'
        >
          <div class='width90lg margin0Auto'>
            <div class='col-md-8 col-xs-16 text-left paddingBottom70sm'>
              <div class='paddingLeft7per marginLeft0md'>
                <h2 class='marginTop16per marginTop8permd'>
                  {TEXT.HOME.NO_LONGER_A_HASSLE}
                </h2>
                <h5>{TEXT.HOME.NO_LONGER_A_HASSLE_SUB}</h5>
              </div>
            </div>
            <div class='col-md-8 col-xs-16'>
              <img class='maxWidth100' src={IMAGES.EVALUATION} />
            </div>
          </div>
        </SectionBox>

        <SectionBox
          classes='text-center paddingBottom5 marginBottom0 border0'
          innerClasses=''
          width='full'
        >
          <h2>{TEXT.HOME.CUSTOMER_REVIEWS}</h2>
          <h4 class='fontWeightNormal'>{TEXT.HOME.CUSTOMER_REVIEWS_SUB}</h4>
          <img class='maxWidth100' src={TEXT.HOME.CUSTOMER_REVIEWS_IMAGE} />
        </SectionBox>

        <SectionBox
          classes='text-center paddingBottom5 marginBottom0 border0'
          innerClasses=''
          width='full'
        >
          <div class='width75per margin0Auto'>
            <h2 class='fontWeightNormal'>{TEXT.HOME.QUOTE}</h2>
            <h4 class='dark-grey fontWeightNormal'>{TEXT.HOME.QUOTE_SOURCE}</h4>
          </div>
        </SectionBox>

        <SectionBox
          classes='text-center paddingBottom5 marginBottom0 border0'
          innerClasses=''
          width='full'
        >
          <h2>{TEXT.HOME.NOT_JUST}</h2>
          <h4 class='fontWeightNormal'>{TEXT.HOME.NOT_ANOTHER_TOOL}</h4>
        </SectionBox>

        <SectionBox
          classes='text-center marginBottom0 border0'
          innerClasses=''
          width='full'
        >
          <div class='row width90lg margin0Auto'>
            <ThreeColumns
              first={{
                title: GEN.REVIEWS_360,
                desc: TEXT.HOME.REVIEWS_360_DESC,
                classes: 'text-left',
                linkLabel: 'See More',
                link: '/360-reviews'
              }}
              second={{
                title: GEN.TRIGGERED_FEEDBACK,
                desc: TEXT.HOME.TRIGGERED_FEEDBACK_DESC,
                classes: 'text-left',
                linkLabel: 'See More',
                link: '/feedback'
              }}
              third={{
                title: GEN.ANNUAL_REVIEWS,
                desc: TEXT.HOME.ANNUAL_REVIEWS_DESC,
                classes: 'text-left',
                linkLabel: 'See More',
                link: '/performance-reviews'
              }}
              style='small'
            />
          </div>
        </SectionBox>

        <SectionBox
          classes='text-center marginBottom0 border0'
          innerClasses=''
          width='full'
        >
          <h2 className='bold'>Terms and Conditions + Privacy and Policy</h2>
          <p>Let's keep it simple!</p>
          <p>
            When you connect your WorkStory account with your Slack account, we
            retain your slack user id in order to send you periodic messages.
          </p>
          <p>
            When you disconnect your Slack account from your WorkStory account,
            we remove all of your Slack data from our database.
          </p>
          <p>
            We do not store any personal information from your Slack
            integration.
          </p>
          <p>
            {`Please contact us at ${HELLO_WORKSTORY_EMAIL} for any specific
            questions around data collection.`}
          </p>
        </SectionBox>

        <SectionBox
          classes='text-center marginBottom0 paddingTop0 paddingBottom100 border0 noBoxShadow'
          innerClasses=''
          width='full'
        >
          <h2>{GEN.WANT_TO_TRY}</h2>
          <h4 class='fontWeightNormal marginBottom20'>
            {GEN.WANT_TO_SEE_SUB}
            <br />
            {GEN.NO_CREDIT_REQUIRED}
          </h4>
          <a href='/sign-up/'>
            <Button>{GEN.LETS_START}</Button>
          </a>
          <a href='/demo'>
            <Button variant='transparent'>{GEN.LETS_CHAT}</Button>
          </a>
        </SectionBox>
      </Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    app: state.appReducer
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Slack);
