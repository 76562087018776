import { h } from 'preact';
import ThreeDotsBubbleSVG from 'src/assets/svg/three-dots-bubble.svg';

export const CIRCLED_ICON_COLORS = {
  PURPLE: 'purple'
};

const CircledIcon = ({
  svg = <ThreeDotsBubbleSVG className='w-5 h-5 text-purple' />,
  color = CIRCLED_ICON_COLORS.PURPLE
}) => {
  let colorClasses = '';

  if (color === CIRCLED_ICON_COLORS.PURPLE) {
    colorClasses = 'bg-light-purple border-alt-light-purple';
  }

  return (
    <div
      className={`absolute right-0 top-0 m-2 p-1 rounded-full w-fit h-fit border-[6px] ${colorClasses}`}
    >
      {svg}
    </div>
  );
};

export default CircledIcon;
