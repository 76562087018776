import { useContext } from 'preact/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { get } from 'lodash';
import { toast } from 'src/components';
import {
  useUpdateRecord,
  useSetRecord,
  useCreateRecord,
  useDeleteRecord
} from 'src/queries/api';
import commonDateUtils from 'common/commonDateUtils';
import { useReportById, QUERY_KEYS as REPORT_KEYS } from 'src/queries/reports';
import { QUERY_KEYS as ACCOUNT_KEYS } from 'src/queries/account';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import COMMON_CONSTANTS from 'common/commonConstants';
import api from 'src/services/api';

const { REPORT_SHARE_PERMISSIONS } = COMMON_CONSTANTS;

export const useUpdateReport = (reportId) => {
  const { context } = useContext(UserProfileContext);
  const queryClient = useQueryClient();
  const { mutateAsync: updateReport, isLoading } = useUpdateRecord();
  const { mutateAsync: deleteReport, isLoading: isLoadingDelete } = useDeleteRecord();
  const { mutateAsync: shareReport, isLoading: isLoadingShare } = useSetRecord();
  const { mutateAsync: generatePdf } = useMutation((data) => api.post('/reports/report/pdf', data));
  const { data: reportResponse, refetch: refetchReport } = useReportById(reportId);
  const report = get(reportResponse, 'report', null);

  const update = async (customContext) => {
    const {
      range: { start, end },
      filters: {
        category, roles, reviewerGroup, reviewerIds
      }
    } = customContext || context;

    const updateData = {
      start,
      end: end || commonDateUtils.getLastUnixOfDate(new Date()),
      categories: category ? [category] : [],
      roles: roles || [],
      reviewerIds: reviewerIds || [],
      reviewerGroup
    };

    const response = await updateReport({
      endpoint: `/reports/report/${reportId}`,
      data: updateData
    });
    refetchReport();
    queryClient.invalidateQueries('/reports/reports');
    if (!response || !response.success) {
      return toast.error('Failed to save report');
    }
    toast.show('Report saved');
  };

  const unshare = async (id) => {
    if (!id) {
      return;
    }

    const response = await deleteReport({
      endpoint: `/reports/share/${reportId}`,
      data: {
        users: [id]
      }
    });

    queryClient.invalidateQueries(REPORT_KEYS.SHARED_REPORTS);
    queryClient.invalidateQueries([ACCOUNT_KEYS.TASKS]);

    refetchReport();
    if (!response || !response.success) {
      return toast.error('Failed to unshare report');
    }
    toast.show('Report unshared');
  };

  const share = async (id) => {
    if (!id) {
      return;
    }
    // const c = confirm(`Do you want to send this report to ${userTree.name} for review? This will allow them to add comments and review their feedback.`);
    // if (!c) { return; }

    const response = await shareReport({
      endpoint: `/reports/share/${reportId}`,
      data: {
        users: [id].map((uid) => ({
          id: uid,
          permissions: REPORT_SHARE_PERMISSIONS
        }))
      }
    });

    queryClient.invalidateQueries(REPORT_KEYS.SHARED_REPORTS);
    refetchReport();
    if (!response || !response.success) {
      return toast.error('Failed to share report');
    }
    toast.show('Report shared');
  };

  const pdf = async ({
    reportId,
    options,
    showBundled,
    isScoresVisible,
    hideStats,
    compareToReport
  }) => {
    if (!reportId) {
      return;
    }
    // const userTree = commonTreeUtils.findNodeById(tree, id);
    // const c = confirm('Do you want to export the record as PDF?');
    // if (!c) { return; }
    toast.show('Creating PDF report');

    let response;
    try {
      response = await generatePdf({
        reportId,
        options,
        showBundled,
        isScoresVisible,
        hideStats,
        compareToReport
      });
    } catch (e) {
      return toast.error('Failed to export report as pdf');
    }
    toast.show('Report exported as pdf');

    const { documentUrl, name } = response;
    const a = document.createElement('a');
    a.setAttribute('href', documentUrl);
    a.setAttribute('download', name);
    const el = document.getElementById('app');
    el.appendChild(a);
    a.click();
  };

  const remove = async () => {
    const response = await deleteReport({
      endpoint: `/reports/${reportId}`
    });
    if (!response || !response.success) {
      return toast.error('Failed to delete report');
    }
    queryClient.invalidateQueries(REPORT_KEYS.GET_REPORTS);
    queryClient.invalidateQueries(REPORT_KEYS.REPORTS);
    queryClient.invalidateQueries([ACCOUNT_KEYS.TASKS]);

    return toast.show('Report deleted');
  };

  return {
    report,
    refetchReport,
    update,
    share,
    unshare,
    remove,
    pdf,
    isLoading: isLoading || isLoadingShare || isLoadingDelete
  };
};

export default useUpdateReport;
