import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { get } from 'lodash';
import commonTreeUtils from 'common/commonTreeUtils';
import {
  Base,
  Select,
  XButton,
  Pagination,
  Placeholder
} from 'src/components/';
import { useEvents } from 'src/queries/events';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import COMMON_CONSTANTS from 'common/commonConstants';
import RefreshSVG from 'src/assets/svg/refresh-icon.svg';
import STYLE from 'src/constants/style';
import EventsTableHeader from 'src/containers/Organization/Events/EventsTableHeader';
import EventsTableContent from 'src/containers/Organization/Events/EventsTableContent';

const { ACCESS, EVENT_TYPES, EVENT_LABELS } = COMMON_CONSTANTS;

const SELECT_EVENT_OPTIONS = [
  {
    id: EVENT_TYPES.SUMMARY_EVENT,
    label: EVENT_LABELS.SUMMARY_EVENT
  },
  {
    id: EVENT_TYPES.REVIEW,
    label: EVENT_LABELS.REVIEW
  },
  {
    id: EVENT_TYPES.RANDOM_EVENT,
    label: EVENT_LABELS.RANDOM_EVENT
  },
  {
    id: EVENT_TYPES.PULSE.PULSE_REVIEW,
    label: EVENT_LABELS.PULSE.PULSE_REVIEW
  },
  {
    id: EVENT_TYPES.PULSE.PULSE_QUESTION,
    label: EVENT_LABELS.PULSE.PULSE_QUESTION
  },
  {
    id: EVENT_TYPES.GOAL_UPDATE,
    label: EVENT_LABELS.GOAL_UPDATE
  },
  {
    id: EVENT_TYPES.TRIAL_EXPIRES,
    label: EVENT_LABELS.TRIAL_EXPIRES
  },
  {
    id: EVENT_TYPES.ENABLE_COMPANY_REVIEWS,
    label: EVENT_LABELS.ENABLE_COMPANY_REVIEWS
  }
];

const Events = ({ isSuperUser }) => {
  const [filterEvent, setFilterEvent] = useState({
    id: EVENT_TYPES.REVIEW,
    label: EVENT_LABELS.REVIEW
  });
  const [filterEventOptions, setFilterEventOptions] = useState(SELECT_EVENT_OPTIONS);
  const [filterName, setFilterName] = useState(null);
  const [nameSearchText, setNameSearchText] = useState('');
  const [filterUserId, setFilterUserId] = useState(null);
  const [filterAssignee, setFilterAssignee] = useState(null);
  const [sortQuery, setSortQuery] = useState({
    field: 'timestamp',
    order: 'asc'
  });
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: { tree, myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: eventsData,
    refetch: refetchEvents,
    isFetching: isFetchingEvents,
    isError: isErrorEvents
  } = useEvents({
    isAdmin: myTreeRow.access === ACCESS.ADMIN,
    filters: {
      type: filterEvent?.id,
      userId: filterUserId,
      assignee: filterAssignee
    },
    options: {
      page: currentPage,
      size: 10,
      sort: sortQuery
    }
  });

  useEffect(() => {
    refetchEvents();
  }, [filterEvent, filterName, filterUserId, filterAssignee]);

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  useEffect(() => {
    if (!isSuperUser) {
      setFilterEventOptions(
        SELECT_EVENT_OPTIONS.filter(
          (event) => event.id !== EVENT_TYPES.PULSE.PULSE_QUESTION
            && event.id !== EVENT_TYPES.TRIAL_EXPIRES
            && event.id !== EVENT_TYPES.ENABLE_COMPANY_REVIEWS
            && event.id !== EVENT_TYPES.RANDOM_EVENT
        )
      );
    }
  }, [isSuperUser]);

  useEffect(() => {
    setCurrentPage(1);
    const selectedUserNode = filterName
      ? commonTreeUtils.findNodeByEmail(tree, filterName.email)
      : {};
    if (selectedUserNode.id) setFilterUserId(selectedUserNode.id);
    if (!selectedUserNode.id) {
      setFilterUserId(null);
      setFilterAssignee(null);
    }
    if (
      selectedUserNode.id
      && filterEvent
      && filterEvent.id === EVENT_TYPES.GOAL_UPDATE
    ) {
      setFilterAssignee(selectedUserNode.id);
    }
  }, [filterEvent, filterName, company?.events]);

  useEffect(() => {
    if (
      eventsData
      && eventsData.sort
      && eventsData.sort.field
      && eventsData.sort.order
    ) {
      setSortQuery(eventsData.sort);
    }
  }, [eventsData]);

  useEffect(() => {
    if (get(myTreeRow, 'email')) {
      setFilterName({
        id: myTreeRow.name,
        label: myTreeRow.name,
        email: myTreeRow.email
      });
    }
  }, [myTreeRow]);

  const names = commonTreeUtils.getNamesUnderNode(tree, tree.email) ?? [];
  const SELECT_NAME_OPTIONS = names
    .filter(
      (userObj) => userObj.name
        && userObj.name !== ''
        && userObj.name.includes(nameSearchText)
    )
    .sort()
    .map((userObj) => ({
      id: userObj.name,
      label: userObj.name,
      email: userObj.email
    }));

  const isLoading = isFetchingEvents || isFetchingCompany || isFetchingTree;
  const eventsScheduled = eventsData?.pagination?.total ?? 0;

  return (
    <Base
      classes={`${STYLE.CONTAINER_WHITE} ${isLoading ? 'min-h-[24rem]' : ''}`}
      loading={isLoading}
    >
      <h5 className='font-bold'>Events</h5>
      <div>
        <div className='w-1/2 inline-block text-left align-top'>
          <p className='mb-0'>Record of scheduled events.</p>
          {eventsData && eventsData.pagination ? (
            <p className='font-bold'>
              {`Number of events: ${eventsData.pagination.total}`}
            </p>
          ) : null}
        </div>
        {
          isSuperUser ? (
            <div className='w-1/2 inline-block text-right align-top'>
              <button data-cy='refresh-btn' onClick={refetchEvents}>
                <RefreshSVG />
              </button>
              {isSuperUser ? (
                <p className='text-purple font-bold'>Superuser View Only</p>
              ) : null}
            </div>
          ) : null
        }
      </div>

      <div className='mb-2'>
        <div className=' align-top inline-block align-top w-56 mr-3'>
          <Select
            title={filterEvent ? filterEvent.label : 'Filter by event'}
            options={filterEventOptions}
            onChange={setFilterEvent}
            drop='up'
          />
        </div>

        <div className='align-top inline-block'>
          <div className='inline-block align-top w-56'>
            <Select
              options={SELECT_NAME_OPTIONS}
              placeholder='Filter by name'
              title={filterName ? filterName.label : 'Filter by name'}
              onChange={setFilterName}
              showSearch
              onSearch={(value) => {
                setNameSearchText(value);
              }}
              onDropdownClose={() => {
                setNameSearchText('');
              }}
              drop='up'
            />
          </div>
          {filterName ? (
            <div className='inline-block'>
              <XButton onClick={() => setFilterName(null)} />
            </div>
          ) : null}
        </div>
      </div>

      <EventsTableHeader filterEvent={filterEvent} />
      {eventsData && eventsData.events ? (
        <EventsTableContent
          events={eventsData.events}
          tree={tree}
          isSuperUser={isSuperUser}
          refetchEvents={refetchEvents}
          company={company}
          isFetchingEvents={isFetchingEvents}
        />
      ) : null}
      {!isFetchingEvents && eventsData ? (
        <Pagination
          name='Events'
          totalPages={eventsData.pagination.pages}
          currentPage={eventsData.pagination.current}
          selectPage={setCurrentPage}
          isLoading={isFetchingEvents}
        />
      ) : null}
    </Base>
  );
};

export default Events;
